<template>
     <b-modal
			ref="modal"
			centered
			id="modal"
			hide-footer
			title="Paramétrez votre document"
      v-model="isOpen"
      size="lg"
		>
       <div v-if='file' class="content">
           <p><b>Fichier séléctionné :</b> <small>{{file.name}}</small></p>
          <form @submit.prevent="send">
               <div class="form-group" role="group">
               <label for="input-name">Nom du fichier :</label>
            <b-form-input required id="input-name" v-model="document.title" placeholder="Nom du document"></b-form-input>
           </div>
           <div class="form-group" role="group">
            <Toggle :checked="document.link_to_chapter" :onInput="handleLink"/>
               <label class='ml-4'>Relier ce document à un chapitre :</label>
            <div class="form-group" v-if='document.link_to_chapter'>
                <v-select
					placeholder="Séléctionner un chapitre"
					label="title"
					:options="chapters"
					v-model="document.chapter"
					:reduce="(c) => c.id"
                    item-text="title"
                    item-value="id"
                    required
				>
				</v-select>
            
            </div>
            <Toggle :checked="document.isInfo" :onInput="handleIsInfos"/>
               <label class='ml-4'>Document informatif</label>
           </div>
            <div class="d-flex align-center justify-center">
          <button type="submit" class="mb-4 twn-button">Enregister et ajouter à la bibliothèque</button>
          <button v-if='isEdit' type="button" class="mb-4 twn-button ml-4" @click="showConfDelete = true" style='background:indianred;color:white;border-color:indianred'>Supprimer ce document</button>
           </div>
          </form>
          <b-modal id="conf" v-model="showConfDelete">Voulez-vous vraiment supprimer ce document de la bilbiothèque des utilisateurs?
              <template #modal-header>
                  <h1>Confimation</h1>
              </template>
              <template #modal-footer>
      <b-button size="sm" variant="danger" @click="deleteDoc()">
        Oui, supprimer 
      </b-button>
      <b-button size="sm" variant='null' @click="showConfDelete=hidden">
        Annuler
      </b-button>
        </template>

          </b-modal>
       </div>
		</b-modal>
</template>
<script>
import Toggle from '../components/Toggle.vue';
import { mapGetters,mapActions } from 'vuex'
export default {
    props:{
        open: {
            type:Boolean,
            default:false,
        },
        file:{
            type:Object,
            default:null
        },
        isEdit:{
            type:Boolean,
            default:false
        },
    },
    components:{
        Toggle
    },
  
    data() {
        return {
            chapitres:[],
            showConfDelete:false,
            document: {
                id:null,
                media:null,
                link_to_chapter:false,
                chapter:null,
                title:null,
                order:null
            },
                isOpen:false,

        }
    },
    async created() {
        this.chapitres = await this.getChapterList();
    },
   async mounted() {
        this.isOpen = true;
        console.log(this.file)
        this.document.media = this.file.id
            this.document.title = this.file.title
            if(this.isEdit) {
            this.document.media = this.file.media
            this.document.id = this.file.id
            this.document.order= this.file.order
            this.document.isInfo = this.file.isInfo
                let file = await this.getById(this.file.media);
                this.file.name = file.name
                if(this.file.chapter !== null) {
                    this.document.link_to_chapter=true;
                    this.document.chapter = this.chapitres.find(item => item.id === this.file.chapter).id
                }
            }
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit("hide",modalId)
    })
    },
    computed:{
        ...mapGetters('Chapter', ['chapters'])
    },
    methods:{
    ...mapActions('Chapter', ['saveChapter', 'getChapterList']),
    ...mapActions('Media', ['getById']),
    deleteDoc() {
        console.log("DELETE")
        this.$emit('delete', this.document.id)
        this.$refs['modal'].hide()
        

    },
    send() {
            if(this.isEdit) {
        delete this.document.media

                this.document.id = this.file.id
            }
            else {
                delete this.document.id
            }
        this.$refs['modal'].hide()
        this.$emit('send', this.document)
    },
         handleLink() {
        this.document.link_to_chapter ? this.document.link_to_chapter = false : this.document.link_to_chapter = true 
    },
       handleIsInfos() {
                   this.document.isInfo ? this.document.isInfo = false : this.document.isInfo = true

    },
    }
}
</script>