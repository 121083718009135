<template>
  <div class="search-users">
    <button @click="$emit('clear')" class="twn-button mt-10">
      Retour aux listes
    </button>
    <b-table
      id="table"
      class="table_list min-w-0 bootstrap"
      responsive="lg"
      :current-page="current"
      :per-page="perPage"
      borderless
      :busy="searchLoading"
      :items="results"
      :fields="fields"
      :tbody-tr-class="{ 'cursor-pointer': true }"
    >
      <!-- https://bootstrap-vue.js.org/docs/components/table/#tables -->
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mr-5"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>

      <hr />
    </b-table>
    <b-pagination
      class="m-auto style-paginate"
      v-model="current"
      v-if="pages"
      :total-rows="results.length"
      :per-page="perPage"
      aria-controls="table"
    >
    </b-pagination>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      current: 1,
      perPage: 20,
      loading: true,
      fields: [
        {
          key: "lastname",
          label: "NOM",
          sortable: true,
          formatter: (name) => {
            return name ? name.toUpperCase() : "—";
          },
        },
        {
          key: "firstname",
          label: "Prénom",
          sortable: true,
          formatter: (name) => {
            return name ? name.charAt(0).toUpperCase() + name.slice(1) : "—";
          },
        },
        {
          key: "mail",
          label: "Mail",
          sortable: true,
          formatter: (mail) => {
            return mail ? mail : "—";
          },
        },

        {
          key: "type",
          label: "Type d'import / d'inscription",
          sortable: true,
          formatter: (status) => {
            if (status === 0) return "Import sans mail";
            if (status === 1) return "Import avec mail";
            if (status === 2) return "Inscription spontanée";
          },
        },
        {
          key: "created_at",
          label: "Date d'inscription / d'ajout",
          sortable: true,
          formatter: (date) => {
            return moment(date)
              .format("dddd DD MMMM YYYY HH:mm")
              .split(":")
              .join("h");
          },
        },
        {
          key: "status",
          label: "Est inscrit ?",
          formatter: (status) => {
            return status ? "Oui" : "Non inscrit";
          },
        },
      ],
      detail: false,
      detailData: [],
      moment: moment,
    };
  },
  mounted() {
    this.moment.locale("fr");
  },
  computed: {
    ...mapGetters("Auth", ["results", "searchLoading"]),
    pages() {
      if (this.results.length && this.perPage) {
        return Math.ceil(this.results.length / this.perPage);
      } else return 1;
    },
  },
};
</script>
