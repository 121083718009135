import Rete from "rete"

import CustomNode from './CustomNode.vue'
import * as Socket from "../sockets"

import { SelectControl } from "../controls/SelectControl.js"
import { TextControl } from "../controls/TextControl.js"

export class ExplorationComponent extends Rete.Component {
  constructor(node_type) {
    // Use the node type slug as component name
    super(node_type.slug)

    this.data = {
      render: 'vue',
      component: CustomNode,
      props: {
        title: node_type.name,
        description: node_type.description,
      }
    }
  }

  builder(node) {
    const next = new Rete.Output("next", "Noeud suivant", Socket.trigger, false)//todo: comon ?
    const previous = new Rete.Input("previous", "", Socket.triggered, true)
    
    const action = new SelectControl("action", "Action(s)", { storeFn: (store) => {
      const entityList = store.state.Entity.list

      if (!entityList)
        return []

      return entityList.filter((entity) => {
        return entity.type.slug == 'action'
      })
    }, }, true)
    const text = new TextControl("text", "Texte", false, 4)
    
    return node
      .addInput(previous)
      .addControl(action)
      .addControl(text)
      .addOutput(next)
  }
}
