<template>
    <div class="dashboard-admin">
        <div class="">
            <button type="button" class="twn-button float-right" @click="ExportCSV" v-if="initialized">Exporter en CSV</button>
            <div class="mb-10 bg-white flex w-full items-center justify-between">
                <div class="filters w-full flex justify-center">
                    <div class="flex items-center">
                        <label for="orga-period-from">Début: </label>
                        <b-form-datepicker v-model="fromDate" @input="GetDashboardInfo" type="text" id="orga-period-from" class="m-4"></b-form-datepicker>
                        <label for="orga-period-to">Fin: </label>
                        <b-form-datepicker v-model="toDate" @input="GetDashboardInfo" type="text" id="orga-period-to" class="m-4"></b-form-datepicker>
                    </div>
                </div>
            </div>
            <div v-if="initialized">
                <div class="dashboard-content flex">
                    <div class="">
                        <p>Dates & Durées de connexions</p>
                        <bar-chart class="dashboard-chart" :chart-data="dailyActivity" :options="barChartOptions" />
                    </div>
                </div>
                <div class="dashboard-content">
                    <div>
                        <p>Séquences parcourues</p>
                        <line-chart class="dashboard-chart" :chart-data="scenarioCompletion" :options="completionLineChartOptions" />
                    </div>
                </div>
                <div class="dashboard-content game-result">
                    <div>
                        <p>Taux de réussite aux exercices</p>
                        <div class="flex flex-col">
                            <line-chart class="dashboard-chart" :chart-data="gameResultAverage" :options="lineChartOptions" />
                            <div class="exercice-info p-4" v-if="selectedScenarioName && gameResultList.length > 0">
                                <div class="title text-sm">{{selectedScenarioName}}</div>
                                <div class="exercice-list">
                                    <div v-for="result in gameResultList" :key="result.name">
                                        <div class="text-sm flex items-center justify-between">
                                            <div class="pr-4">{{result.name}}</div>
                                            <div>{{result.percent}}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dashboard-content">
                    <div>
                        <p>Taux de consultation des médias par épisode</p>
                        <horizontal-bar-chart class="dashboard-chart" :chart-data="averagePdfPerEpisode" :options="mediaPercentOptions" />
                    </div>
                </div>
                <div class="dashboard-content">
                    <div>
                        <p>Taux de consultation des Vidéos par épisode</p>
                        <horizontal-bar-chart class="dashboard-chart" :chart-data="videoWatchPerEpisode" :options="mediaPercentOptions" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {
    GC_GET_GAME_RESULTS_BY_USERID,
    GC_GET_SCENARIO_COMPLETION_BY_USERID,
    GC_INITALIZE_DASHBOARD_DATA,
    GC_GET_USER_ACTIVITY_BY_ID
} from '@/graphql/logs'
import BarChart from '@/components/chart/Bar'
import LineChart from '@/components/chart/Line'
import HorizontalBarChart from '@/components/chart/HorizontalBar'
import * as XLSX from 'xlsx'

export default {
    name:'UserDashboard',
    components: {
        BarChart,
        LineChart,
        HorizontalBarChart
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    async created(){
        await this.GetDashboardInfo()
    },
    data() {
        return {
            scenarioData: {},
            gameData: {},
            connexionTimes: {},
            scenarios: null,
            hoursPerDay: null,
            gameNameByID: null,
            fromDate: null,
            toDate: null,
            initialized: false,
            barChartOptions: {
                tooltips:{
                    callbacks: {
                        label: (tooltipItem, data) => {
                            const minutesPerDay = tooltipItem.yLabel;
                            let hours = minutesPerDay / 60
                            let rHours = Math.floor(hours)
                            let min = (hours - rHours) * 60
                            let rMin = Math.round(min)

                            return data.datasets[tooltipItem.datasetIndex].label + ': ' + rHours + "h" + (rMin < 10 ? '0' + rMin : rMin) 
                        }
                    }
                },
                    legend: {
                        display: false,
                    },
                    layout: {
                        padding: {
                            top: 30,
                            right: 18,
                            left: 18,
                            bottom: 10,
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }],
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                                color: '#E1E1E1',
                                lineWidth: 0.3,
                                zeroLineColor: '#E1E1E1',
                                zeroLineWidth: 0.3,
                            },
                            ticks:{
                                callback: (v) => {
                                    const minutesPerDay = v;
                                    let hours = minutesPerDay / 60
                                    let rHours = Math.floor(hours)
                                    let min = (hours - rHours) * 60
                                    let rMin = Math.round(min)

                                    return rHours + "h" + rMin
                                }
                            }
                        }]
                    }
                },
            lineChartOptions: {
                legend: {
                    display: false,
                },
                layout: {
                    padding: {
                        top: 30,
                        right: 18,
                        left: 18,
                        bottom: 10,
                    }
                },
                responsive: true,
                onHover: this.selectedScenarioData,
                // events: ['mouseenter'],
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            callback: (v) => {
                                if (v.length > 20){
                                    return v.substring(0, 20) + '...'
                                }
                                return v;
                            }
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            drawBorder: false,
                            color: '#E1E1E1',
                            lineWidth: 0.3,
                            zeroLineColor: '#E1E1E1',
                            zeroLineWidth: 0.3,
                        },
                        ticks: {
                            min: 0,
                            max: 100,
                            callback: function (value) {
                                return value + '%'
                            },
                        },
                    }]
                },
            },
            completionLineChartOptions:{
                    legend: {
                        display: false,
                    },
                    layout: {
                        padding: {
                            top: 30,
                            right: 18,
                            left: 18,
                            bottom: 10,
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                callback: (v) => {
                                    if (v.length > 20){
                                        return v.substring(0, 20) + '...'
                                    }
                                    return v;
                                }
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                                color: '#E1E1E1',
                                lineWidth: 0.3,
                                zeroLineColor: '#E1E1E1',
                                zeroLineWidth: 0.3,
                            },
                            ticks: {
                                min: 0,
                                max: 100,
                                callback: function (value) {
                                    return value + '%'
                                },
                            },
                        }]
                    },
                },
            mediaPercentOptions: {
                legend: {
                    display: true
                },
                layout: {
                    padding: {
                        top: 30,
                        right: 18,
                        left: 18,
                        bottom: 10,
                    }
                },
                tooltips: {
                    callbacks: {
                        label: function(item, data) {
                            return data.datasets[item.datasetIndex].label + ": " + data.datasets[item.datasetIndex].data[item.index] + '%' 
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            min: 0,
                            max: 100,
                            callback: function (value) {
                                return value + '%'
                            },
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            drawBorder: false,
                            color: '#E1E1E1',
                            lineWidth: 0.3,
                            zeroLineColor: '#E1E1E1',
                            zeroLineWidth: 0.3,
                        },
                        ticks: {
                            callback: (v) => {
                                if (v.length > 20){
                                    return v.substring(0, 20) + '...'
                                }
                                return v;
                            }
                        }
                    }]
                },
            },
            gameResultList: [],
            gameResultAverageRaw: null,
            selectedScenarioName: null,
            averagePdf: {},
            videoPerScenario: {},
            gameResults: {},
            scenarioCompletionRaw: null,
            months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
                'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        }
    },
    computed:{
        scenarioLabels(){
            if(!this.scenarios) return []

            return Object.values(this.scenarios).reduce((arr, e) => {
                arr.push(e.name)
                return arr
            }, [])
        },
        scenarioCompletion(){
            if (this.scenarioCompletionRaw){
                let data = []
                this.scenarioLabels.forEach(label => {
                    if (this.scenarioCompletionRaw[label]){
                        data.push(this.scenarioCompletionRaw[label] * 100)
                    } else {
                        data.push(0)
                    }
                })
                return {
                    labels: this.scenarioLabels,
                    datasets: [
                        {
                            label: '',
                            backgroundColor: '#DC9799',
                            pointRadius: 4,
                            pointHoverRadius: 6,
                            data
                        }
                    ]
                }
            }
            return {}
        },
        dailyActivity(){
            if (this.hoursPerDay){
                return {
                    labels: Object.keys(this.hoursPerDay),
                    datasets: [
                        {
                            label: '',
                            backgroundColor: '#DC9799',
                            hoverBackgroundColor: '#BE7173',
                            data: Object.values(this.hoursPerDay)
                        }
                    ]
                }
            }
            return {}
        },
        gameResultAverage(){
            if (this.gameResultAverageRaw){
                let data = []
                this.scenarioLabels.forEach(label => {
                    if (this.gameResultAverageRaw[label]){
                        data.push(this.gameResultAverageRaw[label])
                    } else {
                        data.push(0)
                    }
                })
                return {
                    labels: this.scenarioLabels,
                    datasets: [
                        {
                            label: '',
                            backgroundColor: '#DC9799',
                            pointRadius: 8,
                            pointHoverRadius: 10,
                            data
                        }
                    ]
                }
            }
            return {}
        },
        averagePdfPerEpisode(){
            if (this.averagePdf){
                let data = []
                this.scenarioLabels.forEach(label => {
                    if (this.averagePdf[label]){
                        data.push(this.averagePdf[label] * 100)
                    } else {
                        data.push(0)
                    }
                })
                return {
                    labels:this.scenarioLabels,
                    datasets: [
                        {
                            label: 'Taux de téléchargement des fiches',
                            backgroundColor: '#DC9799',
                            hoverBackgroundColor: '#DC9799',
                            data
                        },
                    ]
                    
                }
            }
            return {}
        },
        videoWatchPerEpisode(){
            if (this.videoPerScenario){
                let data = []
                this.scenarioLabels.forEach(label => {
                    if (this.videoPerScenario[label]){
                        data.push(this.videoPerScenario[label] * 100)
                    } else {
                        data.push(0)
                    }
                })
                return {
                    labels:this.scenarioLabels,
                    datasets: [
                        {
                            label: 'Taux de visionnage des vidéos',
                            backgroundColor: '#DC9799',
                            hoverBackgroundColor: '#DC9799',
                            data
                        },
                    ]
                }
            }
            return {}
        }
    },
    methods: {
        ExportCSV(){
            let wb = XLSX.utils.book_new();

            this.Export_FormatGame(wb);
            this.Export_ConnexionTimes(wb)
            let today = new Date();
            let name = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}-${today.getHours()}h${today.getMinutes()}-${today.getSeconds()}_data.xlsb`
            XLSX.writeFile(wb, name);

        },
        Export_FormatGame(wb){
            let sheet = XLSX.utils.aoa_to_sheet([
                ["Données de scénarios (%)"]
            ])
            let index = 1

            for (const scenarioName in this.scenarioData) {
                if (Object.prototype.hasOwnProperty.call(this.scenarioData, scenarioName)) {

                    let scenarioData = this.scenarioData[scenarioName]

                    XLSX.utils.sheet_add_aoa(sheet, [
                        [scenarioName],
                    ], {origin:{r:index, c:0}});
                    index++

                    XLSX.utils.sheet_add_aoa(sheet, [
                        Object.keys(scenarioData),
                        Object.values(scenarioData)
                    ], {origin:{r:index, c:0}});
                    index+= 2

                    if (this.gameData[scenarioName]){
                        let scenarioGames = this.gameData[scenarioName];
                        XLSX.utils.sheet_add_aoa(sheet, [
                            Object.keys(scenarioGames),
                            Object.values(scenarioGames)
                        ], {origin:{r:index, c:0}});
                        index+= 2
                    }

                    index++

                }
            }

            XLSX.utils.book_append_sheet(wb, sheet, 'Scenarios')
        },
        Export_ConnexionTimes(wb){
            let sheet = XLSX.utils.aoa_to_sheet([
                ["Date", "Temps (h)"]
            ])
            let index = 2
            for (const key in this.hoursPerDay) {
                if (Object.prototype.hasOwnProperty.call(this.hoursPerDay, key)) {
                    const minutes = this.hoursPerDay[key];
                    let hours = this.MinutesToHours(minutes)
                    XLSX.utils.sheet_add_aoa(sheet, [
                        [key, hours],
                    ], {origin:{r:index, c:0}});
                    index++;
                }
            }

            XLSX.utils.book_append_sheet(wb, sheet, 'Dates de connexions')
        },
        MinutesToHours(minutes){
            let hours = minutes / 60
            let rHours = Math.floor(hours)
            let min = (hours - rHours) * 60
            let rMin = Math.round(min)

            return rHours + "h" + (rMin < 10 ? '0' + rMin : rMin)
        },
        async GetDashboardInfo(){
            this.initialized = false
            let waitForAll = []
            await this.InitDashboardData()
            await this.GetScenarioCompletion(this.id)
            waitForAll.push(this.GetConnexionDuration(this.id))
            waitForAll.push(this.GetGameResultAverage(this.id))
            Promise.all(waitForAll).then(() => {
                this.initialized = true
                window.scrollTo(0,0)
            })
        },
        async InitDashboardData(){
            const dashboardData = await this.$apollo.query({
                query: GC_INITALIZE_DASHBOARD_DATA
            })

            this.scenarios = {}
            this.gameNamesByID = {}
            if (dashboardData.data && dashboardData.data.scenario && dashboardData.data.game){
                dashboardData.data.scenario.forEach(scenario => {
                    if (scenario.scenarioLocations && scenario.scenarioLocations.length > 0){
                        if (!this.scenarios[scenario.id]) {
                            this.scenarios[scenario.id] = {}
                        }
                        this.scenarios[scenario.id]['location'] = scenario.scenarioLocations
                        this.scenarios[scenario.id]['name'] = scenario.name
                        this.scenarios[scenario.id]['count'] = scenario.nodes_aggregate.aggregate.count
                    }
                })

                dashboardData.data.game.forEach(game => {
                    this.gameNamesByID[game.id] = game.title
                })

            }
        },
        async GetConnexionDuration(id){

            let date = new Date()

            let from = this.fromDate
            if (!from){
                from = new Date(date.getFullYear(), date.getMonth() - 1, 1)
            }

            let to = this.toDate
            if (!to){
                to = date;
            }

            const response = await this.$apollo.query({
                query: GC_GET_USER_ACTIVITY_BY_ID,
                variables: {
                    from,
                    to,
                    id
                }
            })
            let hoursPerDay = {}

            if (response.data && response.data.users[0]){
                let logs = response.data.users[0].logs
                let previousDay;
                logs.forEach(log => {
                    let dateID = new Date(log.created_at)
                    let updateDate = new Date(log.updated_at)
                    let dayID = dateID.getDate() + ' ' + this.months[dateID.getMonth()] + ' ' + dateID.getFullYear()
                    if (!hoursPerDay[dayID]) { hoursPerDay[dayID] = 0 }

                    if (previousDay == dayID || !previousDay){
                        if (log.logType.slug == 'scenario_nodes'){ //difference between created and updated times
                            let timeElapsed = updateDate.getTime() - dateID.getTime()
                                if (timeElapsed >= 200 && timeElapsed <= 3600000){
                                    if (!hoursPerDay[dayID]) { hoursPerDay[dayID] = 0 }
                                    hoursPerDay[dayID] += timeElapsed
                                }
                        }
                        else { //visited media library
                            if (!hoursPerDay[dayID]) { hoursPerDay[dayID] = 0 }
                            hoursPerDay[dayID] += (1000 * 60)
                        }
                    }
                    previousDay = dayID
                })

                for (const dayID in hoursPerDay) {
                    if (Object.prototype.hasOwnProperty.call(hoursPerDay, dayID)) {
                        const milliseconds = hoursPerDay[dayID]

                        hoursPerDay[dayID] = milliseconds / 60000
                    }
                }
            }
            this.hoursPerDay = hoursPerDay
        },
        Hour10ToDate(hour10){
            let hour = Math.floor(hour10)
            let decimalPart = hour10 - hour;

            let min = 1/60;

            decimalPart = min * Math.round(decimalPart / min)
            let minute = Math.floor(decimalPart * 60) + ''

            if (minute.length < 2){
                minute = '0' + minute
            }

            return hour + 'h' + minute
        },
        async GetScenarioCompletion(id){
            const response = await this.$apollo.query({
                query: GC_GET_SCENARIO_COMPLETION_BY_USERID,
                variables: {
                    id,
                    from: this.fromDate,
                    to: this.toDate
                }
            })

            this.scenarioCompletionRaw = {}
            this.averagePdf = {}
            this.videoPerScenario = {}
            if (response.data && response.data.users[0]){
                const logs = response.data.users[0].logs

                logs.forEach(log => {
                    if (this.scenarios[log.data_key] && log.data.stats){
                        let label = this.scenarios[log.data_key].name

                        this.scenarios[log.data_key].stats = log.data.stats

                        if (!this.scenarioData[label]) this.scenarioData[label] = {}
                        if (!this.scenarioCompletionRaw[label]) { this.scenarioCompletionRaw[label] = 0 }
                        if (!this.averagePdf[label]) { this.averagePdf[label] = 0 }
                        if (!this.videoPerScenario[label]) { this.videoPerScenario[label] = 0 }
    
                        if (this.scenarioCompletionRaw[label] < log.data.progress) {
                            this.scenarioCompletionRaw[label] = Math.round(Math.min(log.data.progress, 1) * 100) / 100
                            this.scenarioData[label].completion = this.scenarioCompletionRaw[label]
                        }
                        if (this.averagePdf[label] < log.data.progressPDF) {
                            this.averagePdf[label] = Math.round(Math.min(log.data.progressPDF, 1) * 100) / 100
                            this.scenarioData[label]['PDF Visionnés'] = this.averagePdf[label]
                        }
                        if (this.videoPerScenario[label] < log.data.progressMedia) {
                            this.videoPerScenario[label] = Math.round(Math.min(log.data.progressMedia, 1) * 100) / 100
                            this.scenarioData[label]['Vidéos vues en entier'] = this.videoPerScenario[label]
                        }
                    }
                })
            }
        },
        async GetGameResultAverage(id){
            const response = await this.$apollo.query({
                query: GC_GET_GAME_RESULTS_BY_USERID,
                variables: {
                    id,
                    from: this.fromDate,
                    to: this.toDate
                }
            })

            this.gameResultAverageRaw = {}
            this.gameResults = {}
            if (response.data && response.data.users[0]){
                let exercicesPerScenario = {}
                let averageTryPerGame = {}
                let gameDonePerScenario = {}

                response.data.users[0].logs.forEach(log => {
                    // console.log('log :>> ', log);
                    //Sum the score by scenario[user][game]
                    let gameName = this.gameNamesByID[log.data_key];
                    if (this.scenarios[log.data.scenario_id] && gameName && this.scenarios[log.data.scenario_id].stats && this.scenarios[log.data.scenario_id].stats.game){
                        let scenarioID = log.data.scenario_id
                        if (!exercicesPerScenario[scenarioID]) { exercicesPerScenario[scenarioID] = {} }
                        if (!averageTryPerGame[scenarioID]) { averageTryPerGame[scenarioID] = {} }
                        if (!averageTryPerGame[scenarioID][gameName]) { averageTryPerGame[scenarioID][gameName] = 0 }
                        if (!exercicesPerScenario[scenarioID][gameName]) { exercicesPerScenario[scenarioID][gameName] = 0 }

                        exercicesPerScenario[scenarioID][gameName] += log.data.value
                        averageTryPerGame[scenarioID][gameName] += 1
                    }
                })

                for (const scenarioID in exercicesPerScenario) {
                    if (Object.prototype.hasOwnProperty.call(exercicesPerScenario, scenarioID)) {
                        //Format for csv
                        const games = exercicesPerScenario[scenarioID]
                        let label = this.scenarios[scenarioID].name
                        if (!this.gameData[label]) this.gameData[label] = {}
                        if (!this.scenarioData[label]) this.scenarioData[label] = {}
                        if (!this.gameResults[label]) { this.gameResults[label] = {} }
                        gameDonePerScenario[label] = Object.keys(games).length

                        let sum = 0
                        //avg per game
                        for (const gameName in games) {
                            if (Object.prototype.hasOwnProperty.call(games, gameName)) {
                                const gameResult = games[gameName];
                                let avgGameResult = gameResult / averageTryPerGame[scenarioID][gameName]
                                sum += avgGameResult
                                this.gameResults[label][gameName] = Math.round((Math.min(avgGameResult, 1) * 100) * 10) / 10
                            }
                        }
                        this.gameData[label] = this.gameResults[label]
                        this.gameResultAverageRaw[label] = Math.round(((sum / gameDonePerScenario[label]) * 100) * 10) / 10
                        this.scenarioData[label]['Minijeu Average'] = this.gameResultAverageRaw[label]
                    }
                }

                // console.log('object :>> ', this.gameResultAverageRaw);
            }
        },
        selectedScenarioData(e, data){
            if (data[0]){
                let element = data[0]
                let chart = element._chart
                let index = element._index

                this.selectedScenarioName = chart.data.labels[index]

                this.gameResultList = []

                for (const exerciceName in this.gameResults[this.selectedScenarioName]) {
                    if (Object.prototype.hasOwnProperty.call(this.gameResults[this.selectedScenarioName], exerciceName)) {
                        const result = this.gameResults[this.selectedScenarioName][exerciceName]
                        this.gameResultList.push({
                            name: exerciceName,
                            percent: result
                        })
                    }
                }
            }
        }
    },
    watch:{
        id: function(newID){
            if (newID){
                this.GetDashboardInfo()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.dashboard-header, .dashboard-content {
    @apply flex justify-between mb-10;

    &.game-result > div > div {
        // @apply items-center;
        background-color: #f9f9f9
    }

    & > div {
        @apply flex flex-col justify-between rounded-lg shadow-twn p-8 flex-1;

        margin-right: 2%;

        &:last-child {
            margin-right: 0;
        }

        & > p {
            @apply text-xs font-principal-bold uppercase mb-4;
        }

        .dashboard-header-content {
            @apply flex justify-between;

            & > img {
                @apply h-12;
            }

            & > span {
                @apply text-3xl text-gray-star;
            }
        }

        &.dashboard-content-wide {
            flex-grow: 3;
        }
        .exercice-info{
            background-color: #f9f9f9;
            .exercice-list {
                max-height: 400px;
                overflow-y: scroll;
                overflow-x: hidden;
                > div {
                    @apply my-1 py-2 px-1;
                    background-color: white;
                }
            }
        }
    }
}

.dashboard-chart {
    @apply rounded-lg bg-gray-background;

    max-height: 40vh;
}

</style>