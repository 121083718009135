<template>
  <section class="chapitres">
    <section v-if="!isChapterCreation && !isChapterUpdate" class="chapitres-list flex w-100 flex-wrap">
      <div class="chapitre p-2 mr-2 mb-2 flex flex-col" @click="updateChapitre(chapitre)" :style="`background: ${chapitre.color}59;background-opacity: 0.1 `" v-for="chapitre in chapitres" :key="chapitre.id">
        <div class="chapter-data flex flex-col h-25">
          <span>Chapitre {{chapitre.number}}</span>
          <span>{{chapitre.title}}</span>
        </div>
        <div class="chapitre-preview h-75 flex justify-around">
          <img v-if="chapitre.planche_1" :src="chapitre.planche_1.url" alt="">
          <img v-if="chapitre.planche_2" :src="chapitre.planche_2.url" alt="">
        </div>
      </div>
      <div class="chapitre p-2 mr-2 flex">
        <div
					class="ml-2 plus cursor-pointer w-100 flex-center text-5xl"
					@click="createChapitre"
				>
					+
				</div>
      </div>
      
    </section>
    <section v-else class="chapitre-creation">
      <div class="flex justify-between">
        <b-form-input class="w-25 mb-4" id="chapitre_number" placeholder="Numéro du chapitre" type="number" step="1" min="0" v-model="newChapter.number"></b-form-input>
        <div
          @click="resetNewChapter"
          class="cursor-pointer w-6 h-6 mt-1 mr-1 flex justify-center items-center text-5xl font-principal"
        >
          <b-icon-x></b-icon-x>
        </div>
      </div>
      <b-form-input class="w-50 mb-4" id="chapitre_title" placeholder="Titre du chapitre" type="text" v-model="newChapter.title"></b-form-input>
      <div class="w-50 flex mb-4">
        <b-form-input class="w-50 mr-4" id="chapitre_title" placeholder="Couleur du chapitre (ex: #6d071a)" type="text" v-model="newChapter.color"></b-form-input>
        <div class="color-preview" :style="'background:'+newChapter.color"></div>
      </div>
      <section class="planches-selection justify-around flex w-100 mb-8">
        <section class="planche flex-center">
          <section class="planche-overlay"></section>
          <img v-if="newChapter.planche_1" class="planche-img" :src="newChapter.planche_1.url" alt="">
          <button
            type="button"
            class="twn-button bg-gray-background text-sm"
            @click="openMediaSelectModal(1)"
          >
            Partie 1
          </button>
        </section>
        <section class="planche flex-center">
          <img v-if="newChapter.planche_2" class="planche-img" :src="newChapter.planche_2.url" alt="">
          <section class="planche-overlay"></section>
          <button
            type="button"
            class="twn-button bg-gray-background text-sm"
            @click="openMediaSelectModal(2)"
          >
            Partie 2
          </button>
        </section>

      </section>
      <div>
        <button
          type="button"
          class="twn-button bg-gray-background text-sm mx-auto block"
          @click="save"
        >
          Valider le chapitre
        </button>

      </div>
    </section>
    <!-- <section v-else class="chapitre-update"></section> -->
    <b-modal
			ref="select-modal"
			centered
			id="select-modal"
			hide-footer
			title="Choissez une planche"
      v-model="isSelectMediaModalOpen"
      size="lg"
		>
			<MediaSearchList selectOnly @select-media="selectMedia"></MediaSearchList>
		</b-modal>
  </section>
</template>

<script>
import MediaSearchList from '@/components/MediaSearchList'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: "Chapitres",
  components: {
    MediaSearchList,
  },
  data: () => ({
    chapitres: [],
    isChapterCreation: false,
    newChapter : {
      number: 1,
      title: "gnegne",
      color: "#6d071a",
      planche_1: {},
      planche_2: {}
    },
    isSelectMediaModalOpen: false,
    plancheNumber: null,
    isChapterUpdate: false,
    file1:null
  }),
  async created () {
   await this.getChapterList();
   this.chapitres = this.chapters;
  },
  computed:{
    ...mapGetters('Chapter', ['chapters'])
  },
  methods: {
    ...mapActions('Chapter', ['saveChapter', 'getChapterList']),
    createChapitre() {
      this.isChapterCreation = true
    },
    openMediaSelectModal(planche_number) {
      this.plancheNumber = planche_number
      if(this.newChapter['planche_' + planche_number]) this.newChapter['planche_' + planche_number].planche_number = planche_number
      else this.newChapter['planche_' + planche_number] = {planche_number}
      this.isSelectMediaModalOpen = true
    },
    selectMedia(media){
      this.newChapter['planche_' + this.plancheNumber] = {...this.newChapter['planche_' + this.plancheNumber], ...media}
      this.plancheNumber = null
      this.isSelectMediaModalOpen = false
    },
    updateChapitre(media){
      this.newChapter = {...media}
      this.isChapterUpdate = true
    },
    async save(){
      if(!this.newChapter.number || !this.newChapter.title || !this.newChapter.color) return this.$bvToast.toast('Veuillez remplir toutes les informations du chapitres', { title: `Erreur` })
      if(!this.newChapter.planche_1.id && !this.newChapter.planche_2.id) return this.$bvToast.toast('Veuillez choisir au moins une planche', { title: `Erreur` })
      let chapterObject = {
        title: this.newChapter.title,
        number: this.newChapter.number,
        color: this.newChapter.color,
        planche_1_id : this.newChapter.planche_1?.id,
        planche_2_id : this.newChapter.planche_2?.id,
      }
      if(this.isChapterUpdate) {
        chapterObject.id = this.newChapter.id
      }
      await this.saveChapter(chapterObject)
    await this.getChapterList();
   this.chapitres = this.chapters;

      this.resetNewChapter()
      
    },
    resetNewChapter(){
      this.newChapter = {
        newChapter : {
          planche_1: {},
          planche_2: {}
        }
      }
      this.isChapterCreation = false
      this.isChapterUpdate = false
    }
  },
}
</script>

<style lang="scss">
  .chapitres {
    .chapitres-list {
      .chapitre {
        
        width: 22%;
        cursor: pointer;
        height: 220px;
        border: 2px solid black;
        // border-radius: 25px;
        .chapitre-preview {
          img {
            width: 45%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .chapitre-creation{
      .planches-selection {
        height: 35vh;
        // background: red;
        .planche {
          width: 50%;
          height: 100%;
          position: relative;
          z-index: 2;
          .planche-overlay {
            transition: all 0.5s ease;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index:3;
          }
          &:hover{
            .planche-overlay {
              background: rgba(0,0,0,0.4);
            }
          }
          .planche-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            
          }
          .twn-button{
            position: absolute;
            z-index: 4;
          }
        }
      }
      .color-preview{
        width: 80px;
        height: 40px;
      }

    }
  }
</style>