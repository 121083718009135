import extraChaptersData from "./extraChaptersData"
import extraUsersData from "./extraUsersData"
import residencesData from "./residencesData"
import commonSheet from "./commonSheet"
import usersSheet from "./usersSheet"
import residencesSheet from "./residencesSheet"

// One day in ms
const oneDay = (1 * 60 * 60 * 1000 * 24)

export default {
	types: {
		float: '#0.00',
		percentage: '0.00%',
		duration: {
			format: '[h]"h" mm"min"',
			transform: (time) => (time / oneDay)
		},
		date: {
			format: 'dd/MM/yyyy "à" HH"h"mm',
			transform: (time) => (time ? ((time / (86400 * 1000)) + 25569) : false) // Excel datetime value format
		}
	},
	remoteData: {
		users: {
			module: 'Logs',
			action: 'GetUsersTrackingData',
			payload: {
				logTypes: ['login', 'game', 'scenario']
			},
			groupByUniq: 'id'
		},
		chapters: {
			module: 'Logs',
			action: 'GetChaptersTrackingData',
			groupByUniq: 'id'
		},
		scenarios: {
			module: 'Scenarios',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id'
		}
	},
	extraData: {
		firstCreatedUserDate(data) {
			if (!data.users || data.users.length <= 0)
				return (new Date())

			let firstCreatedTime = (new Date(data.users[0].created_at)).getTime()

			data.users.forEach((user) => {
				const createdTime = (new Date(user.created_at)).getTime()

				if (createdTime < firstCreatedTime) {
					firstCreatedTime = createdTime
				}
			})

			return (new Date(firstCreatedTime))
		},
		chapters: extraChaptersData,
		users: extraUsersData,
		residences: residencesData
	},
	sheets: [
		commonSheet,
		usersSheet,
		residencesSheet
	]
}