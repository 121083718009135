import Vue from "vue";
import {
  BIconList,
  BBreadcrumb,
  BTable,
  BPaginationNav,
  BCard,
  BFormInput,
  BFormDatepicker,
  BFormTags,
  IconsPlugin,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BButton,
  ModalPlugin,
  BFormTextarea,
  ToastPlugin,
  BTableLite,
  BListGroup,
  TabsPlugin,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BDropdownDivider,
  BPagination,
  BSpinner,
} from "bootstrap-vue";

Vue.component("BModal", BIconList);
Vue.component("BBreadcrumb", BBreadcrumb);
Vue.component("BListGroup", BListGroup);
Vue.component("BTable", BTable);
Vue.component("BTableLite", BTableLite);
Vue.component("BPaginationNav", BPaginationNav);
Vue.component("BPagination", BPagination);
Vue.component("BCard", BCard);
Vue.component("BFormInput", BFormInput);
Vue.component("BFormDatepicker", BFormDatepicker);
Vue.component("BFormTags", BFormTags);
Vue.component("BForm", BForm);
Vue.component("BFormGroup", BFormGroup);
Vue.component("BFormCheckbox", BFormCheckbox);
Vue.component("BFormCheckboxGroup", BFormCheckboxGroup);
Vue.component("BButton", BButton);
Vue.component("BFormTextarea", BFormTextarea);
Vue.component("BDropdown", BDropdown);
Vue.component("BDropdownItem", BDropdownItem);
Vue.component("BDropdownButton", BDropdownItemButton);
Vue.component("BDropdownDivider", BDropdownDivider);
Vue.component("BSpinner", BSpinner);
Vue.component("BFormCheckbox", BFormCheckbox);

Vue.use(IconsPlugin);
Vue.use(ModalPlugin);
Vue.use(ToastPlugin);
Vue.use(TabsPlugin);
