import gql from 'graphql-tag'


const GC_GET_PLANCHE_ZONES = gql`
query getPlancheZones($planche_id: uuid!) {
  planche_zone(where: {planche_id: {_eq: $planche_id}}) {
    id
    planche_id
    position
    zone_type
    order
    planche_zone_media(order_by: {game: {name: asc}}) {
      id
      created_at
      game {
        name
        id
      }
      video_comedien {
        id
        name
      }
      video_expert {
        name
        id
      }
      image {
        name
        id
      }
    }
  }
}
`
const GC_ADD_PLANCHE_ZONE = gql`
mutation insertPlancheZone($planche_zone: [planche_zone_insert_input!]!) {
  insert_planche_zone(objects: $planche_zone) {
    affected_rows
  }
}
`
const GC_UPDATE_PLANCHE_ZONE_BY_ID = gql`
mutation updatePlancheZone($planche_zone_id: uuid!, $planche_zone: planche_zone_set_input!) {
  update_planche_zone(where: {id: {_eq: $planche_zone_id}}, _set: $planche_zone) {
    affected_rows
  }
}
`
const GC_DELETE_PLANCHE_ZONE = gql`
mutation deletePlancheZone($zone_id: uuid!) {
  delete_planche_zone_media(where: {planche_zone_id: {_eq: $zone_id}}) {
    affected_rows
  }
  delete_planche_zone(where: {id: {_eq: $zone_id}}) {
    affected_rows
  }
}

`

export {
  GC_ADD_PLANCHE_ZONE,
  GC_GET_PLANCHE_ZONES,
  GC_UPDATE_PLANCHE_ZONE_BY_ID,
  GC_DELETE_PLANCHE_ZONE
}