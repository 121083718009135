import {
	GC_GET_GAME_LIST,
	GC_GET_GAME_TYPES,
	GC_GET_GAME_BY_ID,
	GC_ADD_GAME_ONE,
	GC_UPDATE_GAME_BY_ID,
	GC_DELETE_GAME_BY_ID,
	GC_GET_JAUGES,
	GC_CHECK_NAME_EXISTENCE,
	GC_GET_QUESTION_TYPES
} from "@/graphql/game"

import apollo from "@/apolloClient"

export default {
	namespaced: true,
	state: {
		list: [],
		items: {},
		type_list: [],
		jauges: [],
		question_type_list: []
	},
	getters:{
		listById(state) {
			return state.list.reduce((dict, game) => {
				dict[game.id] = game

				return dict
			}, {})
		},
		typeById(state) {
			return state.type_list.reduce((dict, type) => {
				dict[type.id] = type

				return dict
			}, {})
		},
	},
	mutations: {
		SET_GAME_TYPE_LIST(state, types) {
			state.type_list = types
		},
		SET_QUESTION_TYPE_LIST(state, types){
			state.question_type_list = types
		},
		SET_GAME_LIST(state, games) {
			state.list = games || []
		},
		SET_GAME(state, game) {
			if (!game || !game.id) return
			state.items[game.id] = game
		},
		SET_JAUGE_LIST(state, jauges){
			state.jauges = jauges
		}
	},
	actions: {
		async getTypeList({ commit }) {
			const response = await apollo.query({ query: GC_GET_GAME_TYPES })

			commit("SET_GAME_TYPE_LIST", response.data.game_type)
		},
		async getJaugeList({commit}){
			const response = await apollo.query({ query: GC_GET_JAUGES })

			commit("SET_JAUGE_LIST", response.data.jauges)
		},
		async getQuestionTypeList({commit}){
			const response = await apollo.query({ query: GC_GET_QUESTION_TYPES })

			commit("SET_QUESTION_TYPE_LIST", response.data.question_type)
		},
		async getList({ commit }) {
			const response = await apollo.query({ query: GC_GET_GAME_LIST })

			commit("SET_GAME_LIST", response.data.game)
		},
		async getByID({ commit }, id) {
			let response = await apollo.query({
				query: GC_GET_GAME_BY_ID,
				variables: { id }
			})

			if (!response.data.game || response.data.game.length <= 0) {
				commit("SET_GAME", null)
				return
			}

			commit("SET_GAME", response.data.game[0])
		},
		async save({ dispatch }, data) {
			let response = null
			let result = {}

			//Check name availability
			const availability = await apollo.query({
				query: GC_CHECK_NAME_EXISTENCE,
				variables: {
					name: data.name
				}
			})

			if (
				(availability.data.game.length > 0 && !data.id) ||
				(availability.data.game.length > 0 && data.id && availability.data.game[0].id !== data.id)
			) {
				result.error = "name-taken"
				return result
			}

			// Copy and format data for GraphQL
			let gql_data = JSON.parse(JSON.stringify(data))

			// Format questions answers
			gql_data.questions = gql_data.questions.map((question) => {
				question.answers = { data: question.answers.map(answer => {
					if (!gql_data.id && answer.id){
						delete answer.id
					}
					return answer
				}) }
				if (!gql_data.id && question.id){
					delete question.id
				}
				return question
			})

			// Format headers
			gql_data.headers = gql_data.headers.map(header => {
				if (!gql_data.id && header.id){
					delete header.id
				}
				return header
			})

			// Format medias
			gql_data.medias = gql_data.medias.filter(media => media.media_id)

			// Format scores
			gql_data.scores = gql_data.scores.filter(score => score.jauge_id)

			// Format game type
			gql_data.gameType = gql_data.type.id
			delete gql_data.type

			// Update or add the game
			if (gql_data.id) {
				// Update remote data
				response = await apollo.mutate({
					mutation: GC_UPDATE_GAME_BY_ID,
					variables: gql_data
				})

				dispatch('Logs/ContentLog', {
					id: gql_data.id,
					action: 'content_update'
				}, {root:true})

				result.success = true
			} else {
				// Add remote data
				response = await apollo.mutate({
					mutation: GC_ADD_GAME_ONE,
					variables: gql_data
				})

				dispatch('Logs/ContentLog', {
					id: response.data.insert_game_one.id,
					action: 'content_create'
				}, {root:true})

				result.id = response.data.insert_game_one.id
			}

			// Update store data
			await dispatch("getByID", gql_data.id || result.id)

			return result
		},
		async deleteGameByID({dispatch}, id) {
			let response = await apollo.mutate({
				mutation: GC_DELETE_GAME_BY_ID,
				variables: { id }
			})

			dispatch('Logs/ContentLog', {
				id,
				action: 'content_delete'
			}, {root:true})
			return response.data.delete_game && response.data.delete_question
		}
	}
}
