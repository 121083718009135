export default (data) => {
	if (!data.users || data.users.length <= 0)
		return []

	// Get list of residences from users data
	const residenceDict = data.users.reduce((dict, user) => {
		let residenceId = user.residence_id

		if (!residenceId || !residenceId.trim()) {
			residenceId = 'NR'
		}

		// Create new residence if needed
		if (!dict[residenceId]) {
			dict[residenceId] = {
				residenceId,
				city: (user.city || ''),
				postCode: (user.cedex || ''),
				lotsCount: (user.nb_lots || '?'),
				users: []
			}
		}

		// Fill residence data if needed
		if (!dict[residenceId].city) {
			dict[residenceId].city = (user.city || '')
		}

		if (!dict[residenceId].postCode) {
			dict[residenceId].postCode = (user.cedex || '')
		}

		if (!dict[residenceId].lotsCount) {
			dict[residenceId].lotsCount = (user.nb_lots || '?')
		}

		// Add user to residence data
		dict[residenceId].users.push(user)

		return dict
	}, {})

	const residenceIds = Object.keys(residenceDict)
	let residences = []

	residenceIds.forEach((residenceId) => {
		residences.push(residenceDict[residenceId])
	})

	return residences
}