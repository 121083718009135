<template>
    <div class="user-detail mt-8 md:mt-0">
        <div class="flex flex-col">
            <div class="buttons flex flex-row justify-between">
                <router-link class="twn-button text-xs" :to="{ name: 'entity-list' }">
                    Retour
                </router-link>
                <button type="button" class="twn-button text-xs danger" @click="showDeleteModal" :disabled="hasPendingRequest" v-if="itemID">Supprimer</button>
            </div>
            <div class="twn-card entity-card my-10">
                <form class="flex flex-row flex-wrap justify-around" @submit="createOrEdit">
                    <div class="left">
                        <div class="input-group">
                            <label for="name">Nom</label>
                            <b-form-input v-model="entity.name" type="text" id="name"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="description">Description</label>
                            <b-form-textarea v-model="entity.description" id="description"></b-form-textarea>
                        </div>
                        <div class="input-group">
                            <b-form-checkbox v-model="entity.default" id="default">
                                Entité par défaut
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="right">
                        <div class="input-group" v-if="typeList">
                            <label for="type">Type</label>
                            <v-select
                                label="name"
                                :options="typeList"
                                v-model="entity.entity_type_id"
                                id="type"
                                class="twn-select"
                                :clearable="false"
                                :reduce="type => type.id"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="slug">Slug</label>
                            <b-form-input type="text" v-model="entity.slug" id="slug"></b-form-input>
                        </div>

                        <template v-if="entity.entity_type_id && typeDict[entity.entity_type_id].slug == 'map_point'">
                            <div class="input-group">
                                <label for="slug">X</label>
                                <b-form-input type="text" v-model="entity.data.x" id="slug"></b-form-input>
                            </div>
                            <div class="input-group">
                                <label for="slug">Z</label>
                                <b-form-input type="text" v-model="entity.data.z" id="slug"></b-form-input>
                            </div>
                        </template>

                        <div class="button-holder mb-0">
                            <button :disabled="hasPendingRequest" type="submit" class="twn-button text-sm" v-if="!itemID">Ajouter</button>
                            <button :disabled="hasPendingRequest" type="submit" class="twn-button text-sm" v-else>Enregister</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <b-modal ref="delete-modal-detail-entity" class="bootstrap" centered hide-footer id="delete-modal-detail-entity" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail-entity')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>
    </div>
</template>

<script>

import { mapState } from 'vuex'

import { FC_DELETE_ITEM } from '@/graphql/list'

export default {
    name: 'EntityDetail',
    props: {
        itemID: {
            type: String,
            required: false
        },
    },
    data() {
        return {
            pendingRequest: 0,
            entity: {
                name: '',
                description: '',
                default: false,
                slug: '',
                data: {},
                entity_type_id: null,
            },
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.Auth.userInfo,
            typeList: state => state.Entity.type_list,
            typeDict: state => state.Entity.type_list.reduce((dict, type) => {
                dict[type.id] = type

                return dict
            }, {})
        }),
        hasPendingRequest() {
            return (this.pendingRequest > 0)
        },
    },
    watch: {
        itemID: {
            async handler(entityID) {
                // Load current entity data, if needed
                if (entityID) {
                    // todo: handle invalid uuid response
                    await this.dispatchRequest('Entity/getByID', entityID)

                    const remoteData = this.$store.state.Entity.items[entityID]

                    if (remoteData) {
                        this.entity.id = (remoteData.id || null)
                        this.entity.name = (remoteData.name || '')
                        this.entity.description = (remoteData.description || '')
                        this.entity.default = (remoteData.default || false)
                        this.entity.slug = (remoteData.slug || '')
                        this.entity.data = (remoteData.data || {})
                        this.entity.entity_type_id = (remoteData.entity_type_id || null)
                    }
                } else {
                    this.entity = {
                        name: '',
                        description: '',
                        default: false,
                        slug: '',
                        data: {},
                        entity_type_id: null,
                    }
                }
            },
            immediate: true
        },
        'entity.entity_type_id': {
            async handler(typeID) {
                this.entity.data = {}

                if (!this.entity.entity_type_id) {
                    return
                }

                if (this.entity.entity_type_id && this.typeDict[this.entity.entity_type_id].slug == 'map_point') {
                    this.entity.data = {
                        x: 0,
                        z: 0,
                    }
                }
            },
            immediate: true,
        }
    },
    async mounted() {
        if (this.userInfo.email.indexOf('thewalkingnerds.com') != (this.userInfo.email.length - 19)) {
            this.$router.push({ name: 'entity-list' })
            return
        }

        if (this.$store.state.Entity.type_list.length <= 0) {
            await this.$store.dispatch('Entity/getTypeList')
        }

        if (!this.entity.entity_type_id) {
            this.entity.entity_type_id = this.typeList[0].id
        }
    },
    methods: {
        async dispatchRequest(action_name, args = null) {// todo mixin ?
            this.pendingRequest += 1

            let response = null

            if (args) {
                response = await this.$store.dispatch(action_name, args)
            } else {
                response = await this.$store.dispatch(action_name)
            }

            this.pendingRequest -= 1

            return response
        },
        showDeleteModal() {
            this.$refs['delete-modal-detail-entity'].show()
        },
        async deleteItem() {
            await this.$apollo.query({
                query: FC_DELETE_ITEM("entity"),
                variables: {
                    id: this.itemID
                }
            })
            this.$refs['delete-modal-detail-entity'].hide()
            this.$router.go(-1)
        },
        createOrEdit(e) {
            e.preventDefault()

            this.dispatchRequest('Entity/save', this.entity).then((response) => {
                // Update current url and reload local data
                if (response.id) {
                    this.$router.push({
                        name: 'entity-edit',
                        params: {
                            itemID: response.id
                        }
                    })
                }
            })
        },
    },
}
</script>
<style lang="scss">
.entity-card{
    > div{
        @apply my-5;
    }
    .left, .right{
        @apply w-full;
        > div {
            @apply flex flex-row justify-around items-center my-5 flex-no-wrap;
            > label {
                @apply text-right mr-5 w-1/3 text-xs;
            }
            > input{
                &:not(.is-invalid){
                    @apply border-line-top;
                }
                @apply w-2/3 flex-auto;
                border-radius: 0.275rem !important;
            }
            .twn-select {
                @apply w-2/3;
                .vs__dropdown-toggle {
                    @apply border-line-top;
                }
                &.is-invalid{
                    .vs__dropdown-toggle {
                        border-color: #dc3545;
                    }
                }
            }
        }
        @screen xs {
            > div > label {
                @apply text-sm;
            }
        }
    }
    @screen md {
        .left, .right {
            > div {
                @apply my-10;
            }
            @apply w-1/2;
            .button-holder{
                @apply flex flex justify-end items-end;
            }
        }
    }
    hr{
        box-sizing: border-box;
        border: 1px solid;
        @apply border-line-top rounded-lg;
    }
}
</style>