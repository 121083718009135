<template>
  <div class="c-characters-panel">
    <div class="search flex items-center pr-2">
      <b-icon-search class="h2 my-3 mx-2"></b-icon-search>
      <input
        v-model="searchStr"
        type="text"
        id="search"
        class="form-control w-4/5"
        placeholder="Rechercher un personnage"
      />
    </div>

    <div class="c-characters-panel__list pr-4 pl-1 py-2">
      <transition-group name="fade-x" tag="div">
        <div
          v-for="character in filteredCharacters"
          :key="character.name"
          :class="[
            'px-4 py-2 flex items-center justify-between hover:text-principal-selected rounded-lg mb-2',
            'duration-200 transform hover:shadow-twn hover:font-bold transition-all cursor-pointer',
          ]"
          @click="onCharacterClick(character)"
        >
          <div class="flex items-center">
            <img class="w-10 h-10 object-contain rounded-full shadow-twn overflow-hidden" :src="getAssetURLFromPath(character.avatar_path)" />
            <div class="ml-4 text-sm">{{ character.name }} - {{ character.job }}</div>
          </div>

          <div class="flex">
            <div
              class="transform hover:scale-150 duration-200 mr-6"
              @click.stop="onEditClick(character)"
            >
              <img src="@/assets/images/EDIT.svg" class="h-5" />
            </div>

            <div
              class="transform hover:scale-150 duration-200 mr-4"
              @click.stop="onDeleteClick(character)"
            >
              <img src="@/assets/images/DELETE.svg" class="h-5" />
            </div>
          </div>
        </div>
      </transition-group>
    </div>

    <hr class="my-4" />

    <button v-if="!selectedCharacter" class="twn-button" type="button" @click="onNewClick">
      Nouveau personnage
    </button>

    <div v-if="selectedCharacter" class="c-characters-panel__add-section px-2">
      <div>
        <label>Nom du personnage</label>
        <b-form-input v-model="selectedCharacter.name" type="text" />
      </div>

      <div class="mt-4">
        <label>Métier du personnage</label>
        <b-form-input v-model="selectedCharacter.job" type="text" />
      </div>

      <div class="m-4">
        <label>Avatar du personnage</label>
        <input type="file" ref="inputFile" class="input-file block" @change="onChooseAvatar">
        <img v-if="avatarPreview" class="w-48 h-48 object-contain rounded-full shadow-twn overflow-hidden m-auto" :src="avatarPreview">
      </div>

      <div class="flex justify-between">
        <button class="twn-button" type="button" :disabled="loading" @click="onCancelClick">Annuler</button>
        <button v-if="selectedCharacter && selectedCharacter.id" class="twn-button" type="button" :disabled="loading" @click="onSaveClick">Enregister</button>
        <button v-else class="twn-button" type="button" :disabled="loading" @click="onSaveClick">Créer</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  props: {
    characters: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  data: () => ({
    searchStr: null,
    selectedCharacter: null,
    newAvatar: null,
    avatarPreview: null,
    loading: false,
  }),
  computed: {
    filteredCharacters() {
      return this.characters.filter((character) => {
        if (!this.searchStr) return true;
        return character.name
          .toLowerCase()
          .includes(this.searchStr.toLowerCase());
      });
    },
  },
  methods: {
    ...mapActions("Assets", [
      "deleteFileFromAWS",
      "sendFileToAWS",
    ]),
    getAssetURLFromPath(assetPath){
      if (assetPath){
        return process.env.VUE_APP_AWS_S3_URL + '/' + assetPath;
      }
    },
    sanitizeFileName(filename) {
      return filename.replace(/\s/g, '_').replace(/[^a-zA-Z0-9-_.]/g, '')
    },
    async onChooseAvatar(e) {
      if (e.target.files && e.target.files[0]) {
        this.newAvatar = e.target.files[0]
        
        if (this.newAvatar.type.match(/image.*/)) {
          const reader = new FileReader()
          reader.onload = () => {
            this.avatarPreview = reader.result
          }
          reader.readAsDataURL(event.target.files[0])
        } else {
          var target = event.target || event.srcElement;
          target.value = ''
          this.newAvatar = null
          this.avatarPreview = null
        }
      }
    },
    onCharacterClick(character) {
      this.$emit('select-item', character)
    },
    onEditClick(character) {
      if (this.loading)
        return

      this.selectedCharacter = {
        id: character.id,
        name: character.name,
        job: character.job,
        avatar_path: character.avatar_path,
      }

      this.avatarPreview = this.getAssetURLFromPath(character.avatar_path)
    },
    async onDeleteClick(character) {
      if (this.loading || !character.id)
        return

      this.loading = true

      // Delete awatar file
      if (character.avatar_path) {
        await this.deleteFileFromAWS({ path: character.avatar_path })
      }

      // Delete character ind DB
      await this.$store.dispatch('Characters/delete', character.id)

      // Reload list
      await this.$store.dispatch('Characters/getList')

      this.loading = false
    },
    onNewClick() {
      if (this.loading)
        return

      this.selectedCharacter = {
        name: '',
        job: '',
        avatar_path: '',
      }
    },
    async onSaveClick() {
      if (this.loading || !this.selectedCharacter)
        return

      this.loading = true

      if (this.selectedCharacter.avatar_path) {
        await this.deleteFileFromAWS({ path: this.selectedCharacter.avatar_path })
      }

      if (this.newAvatar) {
        const res = await this.sendFileToAWS(this.newAvatar)

        if (res && res.status == 200) {
          this.selectedCharacter.avatar_path = `assets/${this.sanitizeFileName(this.newAvatar.name)}`
        }
      }

      // Save character
      await this.$store.dispatch('Characters/save', this.selectedCharacter)

      // Reload list
      await this.$store.dispatch('Characters/getList')

      this.selectedCharacter = null
      this.newAvatar = null
      this.avatarPreview = null
      this.loading = false
    },
    onCancelClick() {
      if (this.loading)
        return

      this.selectedCharacter = null
      this.newAvatar = null
      this.avatarPreview = null
    },
  },
}
</script>

<style lang="scss" scoped>
.c-characters-panel {
  @apply flex flex-col;

  &__list {
    height: 30vh;
  }
  
  &__add-section {
    // height: 40vh;
  }

  &__list,
  &__add-section {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>