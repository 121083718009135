import gql from 'graphql-tag'

const GC_GET_COURSE_LIST = gql`
query GetCourses {
    course {
        name
        description
        id
    }
}
`

export {
    GC_GET_COURSE_LIST
}