<template>
    <div>
        <cooc-list 
            listing="entity" 
            :fields="fields" 
            :filters="filters" 
            searchText="Rechercher une entité"
            :with-delete-button="false"
            editRouteName="entity-edit"
            modify-button-text="Éditer l'entité"
        >
            <template v-slot:buttons>
                <div class="md:flex flex-row justify-around hidden user-buttons w-1/3">
                    <button @click="addEntity" type="button" class="hidden md:block twn-button whitespace-no-wrap overflow-hidden text-xs mr-2">Ajouter une entité</button>
                </div>
            </template>
            <template v-slot:fab>
                <vue-fab
                    class="md:hidden"
                    fabItemAnimate="alive"
                    fabAliveAnimateBezier="ease-in-out"
                    mainBtnColor="#fff"
                    size="big"
                >
                    <fab-item class="twn-fab add" @clickItem="addEntity" color="#DC9799" :idx="0.5" title="Ajouter un utilisateur" icon="add" />
                </vue-fab>
            </template>
        </cooc-list>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import List from '@/components/List'

    export default {
        name: 'EntityList',
        props:{
            previousPage: {
                type: Number,
                required: false,
                default: null
            }
        },
        components: { 'cooc-list': List },
        data() {
            return {
                fields: [
                {
                    key: 'name',
                    label: 'Nom',
                    sortable: true
                },
                {
                    key: 'slug',
                    label: 'Slug',
                    sortable: true,
                },
                {
                    key: 'default',
                    label: 'Par défaut',
                    sortable: true,
                },
                {
                    key: 'type',
                    db: `type {
                        name,
                        slug
                    }`,
                    nested: 'name',
                    label: 'Type',
                    sortable: true,
                },
                ],
                filters: [
                    {
                        key:'type',
                        label: 'Type'
                    }
                ]
            }
        },
        computed: {
            ...mapState({
                typeList: state => state.Entity.type_list
            })
        },
        async mounted() {
            await this.$store.dispatch('Entity/getTypeList')
        },
        methods:{
            addEntity() {
                this.$router.push({ name: 'entity-edit' })
            },
        }
    }
</script>

<style lang="scss">
</style>