import apollo from '@/apolloClient'
import { GC_GET_COURSE_TYPES, GC_ADD_COURSE_ONE, GC_GET_COURSE, GC_DELETE_COURSE, GC_UPDATE_COURSE, GC_CHECK_NAME } from '@/graphql/course'
import { GC_GET_SCENARIO_TYPE_LIST } from '@/graphql/scenario'

export default {
    namespaced: true,
    state:{
        types:[],
        scenarioTypes: []
    },
    getters:{
        typeListFormat(state){
            return state.types.reduce((dict, type) => {
                dict[type.id] = type

                return dict
            }, {})
        },
    },
    actions:{
        async Delete({state}, id){
            const response = await apollo.mutate({
                mutation: GC_DELETE_COURSE,
                variables: {id}
            })

            return response.data.delete_course_by_pk
        },
        async GetCourse({state}, id){
            const response = await apollo.query({
                query: GC_GET_COURSE,
                variables: {
                    id
                }
            })

            return response.data.course_by_pk
        },
        async GetCourseTypes({state}){
            const response = await apollo.query({
                query:GC_GET_COURSE_TYPES
            })

            state.types = response.data.course_type
        },
        async GetScenarioTypes({state}){
            const response = await apollo.query({
                query: GC_GET_SCENARIO_TYPE_LIST
            })

            state.scenarioTypes = response.data.course_type;
        },
        async NameTaken({state},{name, id}){
            const response = await apollo.query({
                query: GC_CHECK_NAME,
                variables: {
                    name
                }
            })

			if (
				(response.data.course.length > 0 && !id) ||
				(response.data.course.length > 0 && id && response.data.course[0].id !== id)
			) {
                return true
            }
        },
        async saveCourse({state, dispatch}, course){
            let isTaken = await dispatch("NameTaken", {name: course.name, id:course.id})
            if (isTaken){
                return false;
            }

            if (course.type && course.type.slug == "bloc_course") {
				//Delete levels > 0
				if (course.levels.length > 1){
					course.levels.splice(1, course.levels.length - 1);
				}
            }

            let courseFormat = {
                name: course.name,
                description: course.description,
                type: course.type,
                levels: {data: []}
            }

            if (course.id){
                courseFormat.id = course.id;
            }

            course.levels.forEach((courseLevel) => {
                let sequences = [];
                let levelScenarioIds = courseLevel.scenarios.map(scenario => {return {'scenario_id':scenario.id}})
                
                courseLevel.sequences.forEach((sequence) => {
                    let data = [];

                    if (sequence.scenarios){
                        let scenarioIds = sequence.scenarios.map(scenario => {
                            return {
                                "scenario_id":scenario.id,
                                "order": scenario.order
                            }
                        })
                        data.push(...scenarioIds)
                    }

                    if (sequence.revisions){
                        data.push(...sequence.revisions);
                    }

                    let obj =
                    {
                        name: sequence.name,
                        title: sequence.title,
                        level_scenario_limit: parseInt(sequence.level_scenario_limit),
                        order: sequence.order,
                        scenarios: {data: data}
                    }

                    if (sequence.id){
                        obj.id = sequence.id;
                    }

                    sequences.push(obj)
                })

                let levelObj = {
                    name: courseLevel.name,
                    title: courseLevel.title,
                    order: courseLevel.order,
                    sequences: {data: [...sequences]},
                    scenarios: {data: [...levelScenarioIds]}
                }

                if (courseLevel.id){
                    levelObj.id = courseLevel.id
                }

                courseFormat.levels.data.push(levelObj)
            })
            let response;
            if (course.id != null){
                //Edit

                response = await apollo.mutate({
                    mutation: GC_UPDATE_COURSE,
                    variables: {
                        id: course.id,
                        course: courseFormat
                    }
                })

            } else {
                //Save
                response = await apollo.mutate({
                    mutation: GC_ADD_COURSE_ONE,
                    variables: {
                        course:courseFormat
                    }
                })

            }
            return response.data.insert_course_one
        }
    }
}