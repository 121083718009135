export default {
  data() {
    return {
      pendingStoreRequest: 0,
    }
  },
  computed: {
    hasPendingStoreRequest() {
      return (this.pendingStoreRequest > 0)
    },
  },
  methods: {
    async dispatchStoreRequest(action_name, args = null) {
      this.pendingStoreRequest += 1

      let response = null

      try {
        if (args) {
          response = await this.$store.dispatch(action_name, args)
        } else {
          response = await this.$store.dispatch(action_name)
        }
      } finally {
        this.pendingStoreRequest -= 1
      }
      
      return response
    },
  }
}
