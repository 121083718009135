<template>
  <section class="user-inscription">
    <div class="md:flex flex-row justify-end hidden user-buttons">
      <div class=" flex flex-row items-center align-self-end w-1/3 pr-2">
        <label class="mb-0" for="search">
          <b-icon-search class="h2 my-3 mx-2"></b-icon-search>
        </label>
        <input
          v-model="search"
          type="text"
          id="search"
          class="form-control w-4/5"
          placeholder="Rechercher un utilisateur"
          ref="search"
        />
      </div>

      <!-- <button @click="addUser" type="button" class="hidden md:block twn-button whitespace-no-wrap overflow-hidden text-xs mr-2">Ajouter un utilisateur</button> -->
      <button
        v-if="currentTab === 0 || currentTab === 1"
        @click="$refs['import-modal'].isImportModalOpen = true"
        type="button"
        class="twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs ml-auto"
      >
        Importer des utilisateurs
      </button>
      <button
        v-else
        class="twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs ml-auto"
        @click="exportTracks"
      >
        Exporter le suivi
      </button>
    </div>
    <div>
      <b-tabs
        v-model="currentTab"
        content-class="mt-3"
        @activate-tab="search = ''"
      >
        <b-tab title="Inscriptions à traiter" active>
          <b-table
            class="table_list min-w-0 bootstrap"
            responsive="lg"
            :current-page="page"
            :per-page="pageSize"
            borderless
            :busy="tableBusy"
            :items="getItemsList"
            :fields="fields"
            :tbody-tr-class="{ 'cursor-pointer': true }"
            :sort-by="defaultSortBy"
            :sort-desc="defaultSortDesc"
          >
            <!-- https://bootstrap-vue.js.org/docs/components/table/#tables -->
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-1"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>

            <template v-for="slotName in cellSlots" v-slot:[slotName]="scope">
              <slot :name="slotName" v-bind="scope"></slot>
            </template>

            <template v-slot:row-details="row">
              <slot name="row-details" :row="row"></slot>
            </template>
            <template #cell(validation)="data">
              <div class="flex">
                <button
                  type="button"
                  class="twn-button text-sm"
                  @click="validate(data.item)"
                >
                  Accepter
                </button>
                <button
                  type="button"
                  class="twn-button text-sm"
                  @click="refuse(data.item)"
                >
                  Refuser
                </button>
              </div>
            </template>
            <hr />
          </b-table>

          <b-pagination-nav
            class="m-auto style-paginate"
            v-model="page"
            :link-gen="linkGen"
            v-if="numPage"
            :number-of-pages="numPage"
            use-router
          >
          </b-pagination-nav>
        </b-tab>
        <b-tab title="Inscriptions téléchargées depuis un .CSV">
          <b-table
            class="table_list min-w-0 bootstrap"
            responsive="lg"
            :current-page="pageCSV"
            :per-page="pageSizeCSV"
            borderless
            :busy="tableBusyCSV"
            :items="getItemsListCSV"
            :fields="fieldsCSV"
            :tbody-tr-class="{ 'cursor-pointer': true }"
            :sort-by="defaultSortBy"
            :sort-desc="defaultSortDesc"
            @row-clicked="handleEdit"
          >
            <!-- https://bootstrap-vue.js.org/docs/components/table/#tables -->
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-1"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>

            <template v-for="slotName in cellSlots" v-slot:[slotName]="scope">
              <slot :name="slotName" v-bind="scope"></slot>
            </template>
            <template #cell(action)="data">
              <div class="flex">
                <button
                  type="button"
                  class="twn-button text-sm"
                  @click="sendAgain(data.item)"
                >
                  Renvoyer le code par mail
                </button>
              </div>
            </template>
            <hr />
          </b-table>
          <b-pagination-nav
            class="m-auto style-paginate"
            v-model="pageCSV"
            v-if="numPageCSV"
            :link-gen="linkGenCSV"
            :number-of-pages="numPageCSV"
            use-router
          >
          </b-pagination-nav>
        </b-tab>
        <b-tab title="Suivi des inscriptions" @click="listAllTracks">
          <b-table
            class="table_list min-w-0 bootstrap"
            responsive="lg"
            borderless
            :busy="tableBusy"
            :items="getTracksItems"
            :fields="fieldsTracks"
            :tbody-tr-class="{ 'cursor-pointer': true }"
            sort-by="created_at"
            :sort-desc="true"
          >
            <!-- https://bootstrap-vue.js.org/docs/components/table/#tables -->
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-1"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>

            <template v-for="slotName in cellSlots" v-slot:[slotName]="scope">
              <slot :name="slotName" v-bind="scope"></slot>
            </template>

            <template v-slot:row-details="row">
              <slot name="row-details" :row="row"></slot>
            </template>

            <hr />
          </b-table>

          <b-pagination-nav
            class="m-auto style-paginate"
            v-model="page"
            :link-gen="linkGen"
            v-if="numPage"
            :number-of-pages="numPage"
            use-router
          >
          </b-pagination-nav>
        </b-tab>
      </b-tabs>
    </div>

    <UserImportModal ref="import-modal"></UserImportModal>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import dptList from "@/assets/departments.json";
import UserImportModal from "@/components/UserImport_Modal";
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
import { GC_USER_INSCRIPTION_TRACK } from "@/graphql/auth.js";
import apollo from "@/apolloClient";
import * as XLSX from "xlsx";

export default {
  name: "UserList",
  data() {
    return {
      fieldsQuery: [
        {
          key: "lastname",
          label: "Nom",
          sortable: true
        },
        {
          key: "firstname",
          label: "Prénom",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "status",
          label: "Statut",
          sortable: true,
          formatter: value => {
            switch (value) {
              case 1:
                return "Locataire";
              case 2:
                return "Propriétaire";
              case 3:
                return "Autre";
              default:
                break;
            }
          }
        },
        {
          key: "adress",
          label: "Adresse",
          sortable: true
        },
        {
          key: "city",
          label: "Ville",
          sortable: true
        },
        {
          key: "cedex",
          label: "Code postal",
          sortable: true
        },
        {
          key: "structure",
          label: "Structure",
          sortable: true
        },
        {
          key: "reason",
          label: "Motivation",
          sortable: true
        },
        {
          key: "code",
          label: "État",
          sortable: true,
          formatter: () => {
            return "Code pas encore envoyé";
          }
        },

        {
          key: "hasEnteredCode",
          label: "A entré son code?",
          sortable: true
        },
        {
          key: "dateHasEnteredCode",
          label: "Date de saisie du code",
          sortable: true
        },
        {
          key: "validation",
          label: "Décision",
          sortable: true
        }
      ],
      fields: [
        {
          key: "lastname",
          label: "Nom",
          sortable: true
        },
        {
          key: "firstname",
          label: "Prénom",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "status",
          label: "Statut",
          sortable: true,
          formatter: value => {
            switch (value) {
              case 1:
                return "Locataire";
              case 2:
                return "Propriétaire";
              case 3:
                return "Autre";
              default:
                break;
            }
          }
        },
        {
          key: "adress",
          label: "Adresse",
          sortable: true
        },
        {
          key: "city",
          label: "Ville",
          sortable: true
        },
        {
          key: "cedex",
          label: "Code postal",
          sortable: true
        },
        {
          key: "structure",
          label: "Structure",
          sortable: true
        },
        {
          key: "reason",
          label: "Motivation",
          sortable: true
        },
        {
          key: "code",
          label: "État",
          sortable: true,
          formatter: () => {
            return "Code pas encore envoyé";
          }
        },

        {
          key: "validation",
          label: "Décision",
          sortable: true
        }
      ],
      fieldsCSV: [
        {
          key: "lastname",
          label: "Nom",
          sortable: true
        },
        {
          key: "firstname",
          label: "Prénom",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "status",
          label: "Statut",
          sortable: true,
          formatter: value => {
            switch (value) {
              case 1:
                return "Locataire";
              case 2:
                return "Propriétaire";
              case 3:
                return "Autre";
              default:
                break;
            }
          }
        },

        {
          key: "action",
          label: "Action"
        }
      ],
      fieldsTracks: [
        {
          key: "lastname",
          label: "Nom",
          sortable: true
        },
        {
          key: "firstname",
          label: "Prénom",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "created_at",
          label: "Date",
          sortable: true,
          formatter: date => {
            return moment(date)
              .format("DD/MM/YYYY HH:mm")
              .split(":")
              .join("h");
          }
        },
        {
          key: "type",
          label: "État",
          sortable: true,
          formatter: value => {
            switch (value) {
              case 0:
                return "Accès demandé par l'utilisateur";
              case 1:
                return "Code envoyé par mail";
              case 2:
                return "Code rentré par l'utilisateur";
              case 3:
                return "Mail de confirmation envoyé";
              case 4:
                return "Compte activé";
              case 5:
                return "Inscription refusée";
              default:
                break;
            }
          }
        }
      ],

      filters: [
        {
          key: "organization",
          label: "Office",
          multiple: true
        },
        {
          key: "role",
          label: "Rôle"
        },
        {
          key: "experienced",
          label: "Expérimenté"
        }
      ],
      dptList: dptList,
      page: 1,
      pageCSV: 1,
      filterInfo: {
        select: []
      },
      criteria: {
        select: []
      },
      tableBusy: false,
      tableBusyCSV: false,
      searchStr: "",
      selectedID: "",
      totalWait: 0,
      totalCSV: 0,
      selectedFilter: -1,
      appliedFilters: [],
      mobile_edit_menu: -1,
      filterApplied: false,
      search: "",
      currentTab: 0
    };
  },
  props: {
    previousPage: {
      type: Number,
      required: false,
      default: null
    },
    pageSize: {
      type: Number,
      required: false,
      default: 20
    },
    pageSizeCSV: {
      type: Number,
      required: false,
      default: 20
    },

    defaultSortBy: {
      type: String,
      required: false,
      default: ""
    },
    defaultSortDesc: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    UserImportModal
  },
  async mounted() {
    await this.listAllTracks();
    await this.getList();
    if (this.previousPage) {
      this.page = this.previousPage;
      this.$router.push({
        name: this.$router.currentRoute.name,
        query: {
          page: this.page
        }
      });
    }
  },
  computed: {
    ...mapGetters("List", {
      getRawItemsList: "getItemsList"
    }),
    ...mapGetters("Auth", ["tracks"]),
    getTracksItems() {
      const array = this.tracks;
      if (this.search.length > 0) {
        const results = array.filter(item => {
          const queriable =
            item.firstname + " " + item.lastname + " " + item.email;

          return queriable.toLowerCase().includes(this.search);
        });
        return results;
      } else {
        return array;
      }
    },
    getItemsList() {
      const array = this.getRawItemsList
        .map(item => ({
          ...item,
          _showDetails: false
        }))
        .filter(item => item.code === null);

      if (this.search.length > 0) {
        const results = array.filter(item => {
          const queriable =
            item.firstname + " " + item.lastname + " " + item.email;

          return queriable.includes(this.search);
        });
        return results;
      } else {
        return array;
      }
    },
    getItemsListCSV() {
      const array = this.getRawItemsList
        .map(item => ({
          ...item,
          _showDetails: false
        }))
        .filter(item => item.code !== null && item.code !== "");
      if (this.search.length > 0) {
        const results = array.filter(item => {
          const queriable =
            item.firstname + " " + item.lastname + " " + item.email;

          return queriable.includes(this.search);
        });
        return results;
      } else {
        return array;
      }
    },

    cellSlots() {
      return Object.keys(this.$scopedSlots).filter(
        slotName => slotName.substring(0, 4) === "cell"
      );
    },
    numPage() {
      if (this.getItemsList && this.pageSize) {
        return Math.ceil(this.getItemsList.length / this.pageSize);
      } else return 1;
    },
    numPageCSV() {
      if (this.getItemsListCSV && this.pageSizeCSV) {
        return Math.ceil(this.getItemsListCSV.length / this.pageSizeCSV);
      } else return 1;
    }
  },
  methods: {
    ...mapActions("List", ["listItems"]),
    ...mapActions("EditItem", ["clearCurrentID"]),
    ...mapActions("Auth", ["updateInscription", "listAllTracks"]),
    exportTracks() {
      var filename = "suivi_inscription.xlsx";
      var data = [["Nom", "Prénom", "Mail", "Date", "État"]];
      this.tracks.forEach(track => {
        let type = "";
        switch (track.type) {
          case 0:
            type = "Accès demandé par l'utilisateur";
            break;
          case 1:
            type = "Code envoyé";
            break;
          case 2:
            type = "Code rentré par l'utilisateur";
            break;
          case 3:
            type = "Mail de confirmation envoyé";
            break;
          case 4:
            type = "Compte activé";
            break;
          case 5:
            type = "Inscription refusée";
            break;
          default:
            break;
        }
        const row = [
          track.lastname,
          track.firstname,
          track.email,
          moment(track.created_at)
            .format("DD/MM/YYYY HH:MM")
            .split(":")
            .join("h"),
          type
        ];
        data.push(row);
      });
      var ws_name = "SheetJS";

      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(data);

      /* add worksheet to workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      /* write workbook */
      XLSX.writeFile(wb, filename);
    },
    handleEdit(user) {
      this.$router.push({
        name: "users-inscription-edit",
        params: {
          itemID: user.id
        }
      });
    },
    addUser() {
      this.clearCurrentID();
      this.$router.push({ name: "users-create" });
    },
    importUserList() {
      this.$refs["inputFile"].click();
      // console.log("IMPORT USER LIST");
    },
    linkGen(page) {
      return page === 1 ? "?" : "?page_wait=" + page;
    },
    linkGenCSV(page) {
      return page === 1 ? "?" : "?page_csv=" + page;
    },

    async getList() {
      let fields = [...this.fieldsQuery];
      fields.pop();
      fields.push({ key: "updated_at" });
      let data = {
        table: "user_inscription",
        fields: fields,
        orderBy: this.orderBy
        // where: '{code: {_is_null: true}}'
      };
      await this.listItems(data);
      // this.createFilters();
    },
    async getListTracks() {
      let fields = [...this.fieldsQuery];
      fields.pop();
      fields.push({ key: "updated_at" });
      let data = {
        table: "user_inscription_tracks",
        fields: fields,
        orderBy: this.orderBy
      };
      await this.listItems(data);
    },

    async validate({ id, adress, email, firstname, lastname }) {
      let code = (firstname + lastname + (adress ? adress : ""))
        .replace(/\s/g, "")
        .replace(/-/g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      // TODO envoyer le mail validation
      await this.updateInscription({ id, code });
      await this.sendEmail({
        user: {
          firstname,
          lastname,
          email
        },
        code,
        context: "validation"
      });
      await this.getList();
    },
    async sendAgain(user) {
      await this.sendEmail({
        user: {
          lastname: user.lastname,
          firstname: user.firstname,
          email: user.email
        },
        code: user.code,
        context: "validation"
      });
    },

    async refuse(user) {
      await this.updateInscription({ id: user.id, code: "" });
      await this.sendEmail({ user, code: "", context: "refus" });
      await this.getList();
    },
    async sendEmail({ user, code, context }) {
      let emailobj = {
        context,
        user: user.email,
        variables: { code }
      };
      let type = 0;
      switch (context) {
        case "validation":
          type = 1;
          break;
        case "refus":
          type = 5;
          break;
      }
      try {
        await axios.post(
          process.env.VUE_APP_API + "send",
          JSON.stringify(emailobj)
        );
        this.$bvToast.toast("Mail de confirmation envoyé à : " + user.email, {
          title: `Confirmation`
        });
        try {
          const response_track = await apollo.mutate({
            mutation: GC_USER_INSCRIPTION_TRACK,
            variables: {
              lastname: user.lastname,
              firstname: user.firstname,
              email: user.email,
              type: type
            }
          });
        } catch (error) {
          console.log(error);
          throw error;
        }
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err, { title: `Erreur` });
      }

      // TODO envoyer le mail de bienvenu
    }
  }
};
</script>
<style lang="scss">
// .user-buttons{
//     flex-basis: 33%;
// }
.user-inscription {
  .table_list {
    overflow-x: auto;
  }
}
#search.form-control {
  @apply text-principal-gray;
  border: none;
}
</style>
