<template>
  <b-modal
    id="modal-1"
    hide-footer
    v-if="isImportModalOpen"
    v-model="isImportModalOpen"
    @hide="resetFile"
    title="Importer des utilisateurs"
  >
    <p
      v-if="info.message"
      :class="`text-${info.color}-600 text-sm mb-4 text-center`"
    >
      {{ info.message }}
    </p>
    <div class="flex items-center">
      <button
        @click="$refs.inputFile.click()"
        type="button"
        class="mr-2 twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
      >
        Choisir un fichier
      </button>
      <span v-if="selectedFile">{{ selectedFile.name }}</span>
    </div>
    <p class="text-xs my-4">
      Rappel : le fichier doit contenir dans l'ordre les colonnes email, nom,
      prénom, statut (locataire = 1, propriétaire = 2 , autre = 3), numéro de
      département, adresse, structure
    </p>
    <input
      type="file"
      ref="inputFile"
      hidden
      class="input-file"
      @click="$event.target.value = null"
      @change="fileSelected"
    />
    <p class="text-center" v-if="loading">Chargement...</p>
    <ul v-if="fileErrors.length" class="mb-4">
      <li v-for="err in fileErrors" :key="err" class="text-red-600">
        - {{ err }}
      </li>
    </ul>

    <!-- Data preview -->
    <p v-if="dataPreview && dataPreview.length > 0" class="font-bold text-sm">
      Aperçu
    </p>
    <table class="text-xs" v-if="dataPreview && dataPreview.length > 0">
      <tbody>
        <tr>
          <th>email</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["email"] || "-" }}
          </td>
        </tr>
        <tr>
          <th>nom</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["nom"] || "-" }}
          </td>
        </tr>
        <tr>
          <th>prenom</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["prénom"] || "-" }}
          </td>
        </tr>
        <tr>
          <th>profil</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["profil"] || "-" }}
          </td>
        </tr>
        <tr>
          <th>ville</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["ville"] || "-" }}
          </td>
        </tr>
        <tr>
          <th>CP</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["CP"] || "-" }}
          </td>
        </tr>
        <tr>
          <th>adresse</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["adresse"] || "-" }}
          </td>
        </tr>
        <tr>
          <th>structure</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["structure"] || "-" }}
          </td>
        </tr>
        <tr>
          <th>nb_lots</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["nombre de lots de la résidence"] || "-" }}
          </td>
        </tr>
        <tr>
          <th>tel</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["tel"] || "-" }}
          </td>
        </tr>
        <tr>
          <th>id</th>
          <td v-for="(user, index) in dataPreview" :key="index">
            {{ user["id"] || "-" }}
          </td>
        </tr>
      </tbody>
    </table>
    <button
      @click="importList"
      :disabled="!isFileValid"
      type="button"
      class="mx-auto twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
    >
      Importer la liste
    </button>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import * as XLSX from "xlsx";
import axios from "axios";
export default {
  name: "UserImportModal",
  data: () => ({
    isImportModalOpen: false,
    fileError: "",
    selectedFile: null,
    allowedExt: ["xlsx", "xls"],
    fileErrors: [],
    isFileValid: false,
    statusAlias: { locataire: 1, copropriétaire: 2, autre: 3 },
    info: { color: "", message: "" },
    dataPreview: [],
    loading: false
  }),
  methods: {
    ...mapActions("Auth", ["inscription", "checkRegisteredEmails"]),
    async fileSelected(e) {
      this.selectedFile = e.target.files[0];
      this.info.message = "";
      this.info.color = "";
      this.fileError = "";
      this.fileErrors = [];
      this.dataPreview = [];
      this.loading = true;
      const ext = this.selectedFile.name.split(".").pop();
      if (!this.allowedExt.includes(ext)) {
        this.info.message = "Veuillez choisir un fichier au format XLSX ou XLS";
        this.info.color = "red";
        return;
      }
      var reader = new FileReader();
      reader.onload = async e => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array", codepage: 1147 });

        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];

        let jsonData = XLSX.utils.sheet_to_json(worksheet);
        let errors = this.checkFileDatas(worksheet, jsonData);
        let dup = this.findDuplicates(jsonData.filter(data => data.email));
        // errors = [...errors, ...this.checkEmails(jsonData)]
        if (errors.length) {
          this.fileErrors = errors;
          this.loading = false;
        } else if (dup.length) {
          this.fileErrors = dup.map(
            email =>
              "Cette adresse mail à été trouvée plusieur fois dans le document : " +
              email
          );
          this.loading = false;
        } else {
          let emailAlreadyRegistered = await this.checkRegisteredEmails(
            jsonData
              .filter(data => data.email)
              .map(data => data.email.toLowerCase())
          );
          if (emailAlreadyRegistered.length) {
            jsonData = jsonData.filter(
              data =>
                !emailAlreadyRegistered.find(
                  registered => data.email === registered.email
                )
            );
          }
          // Generate preview
          if (jsonData.length > 2) {
            this.dataPreview = [
              jsonData[0],
              jsonData[Math.round(jsonData.length / 2) - 1]
            ];
          } else if (jsonData.length > 0) {
            this.dataPreview = [jsonData[0]];
          }

          this.jsonData = jsonData;
          this.isFileValid = true;
          this.loading = false;
        }
      };
      reader.readAsArrayBuffer(this.selectedFile);
    },
    resetFile() {
      this.selectedFile = null;
      this.fileError = "";
      this.fileErrors = [];
      this.$refs.inputFile.value = "";
      this.isFileValid = false;
    },
    checkFileDatas(sheet, arr) {
      let errors = [];

      // Check file headers name
      const validHeaders = [
        "email",
        "nom",
        "prénom",
        "profil",
        "ville",
        "CP",
        "adresse",
        "structure",
        "nombre de lots de la résidence",
        "tel",
        "id"
      ];

      for (let i = 0; i < 20; i++) {
        const cellRef = XLSX.utils.encode_cell({ c: i, r: 0 });
        const col = sheet[cellRef]?.v;

        if (col && validHeaders.indexOf(col) < 0) {
          errors.push("Colonne " + i + ' inconnue : "' + col + '"');
        }
      }

      // Check values
      arr.forEach((user, i) => {
        if (!user.nom || !user["prénom"]) {
          errors.push(
            "Il manque une information obligatoire (nom, prénom) sur la ligne " +
              (user.__rowNum__ + 1)
          );
        }

        if (user.email && !this.validateEmail(user.email.replace(/\s/g, "")))
          errors.push("Vérifiez le format du mail sur la ligne " + (i + 2));

        if (user.tel && (user.tel + "").length !== 10) {
          errors.push(
            "Vérifiez le format du numéro de téléphone sur la ligne " + (i + 2)
          );
        }
      });
      return errors;
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async importList() {
      let usersData = [];
      for (const {
        email,
        nom,
        prénom: prenom,
        profil,
        ville,
        CP,
        adresse,
        structure,
        "nombre de lots de la résidence": nb_lots,
        tel,
        id
      } of this.jsonData) {
        let userData = {
          email: email ? email.replace(/\s/g, "") : null,
          firstname: prenom,
          lastname: nom,
          city: ville,
          cedex: CP + "",
          adress: adresse,
          structure: structure,
          status: profil ? this.statusAlias[profil] : null,
          phone: tel,
          nb_lots: nb_lots ? parseInt(nb_lots) : null,
          code: (prenom + nom + (adresse ? adresse : ""))
            .replace(/\s/g, "")
            .replace(/-/g, "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase(),
          residence_id: id
        };
        usersData.push(userData);
      }
      if (usersData.length) {
        try {
          await this.inscription(usersData);
          usersData.forEach(({ code, email }) => {
            if (email) this.sendWelcomeEmail({ code, email });
          });
          this.info.message = "La liste a bien été importé";
          this.info.color = "green";
          setTimeout(() => {
            this.info.message = "";
            this.info.color = "";
          }, 2000);
          this.resetFile();
        } catch (error) {
          if (
            error.graphQLErrors[0].extensions.code === "constraint-violation"
          ) {
            this.info.message =
              "Une ou plusieurs adresses email sont déjà utilisé pour une inscription veuillez les retirer du document";
            this.info.color = "red";
          } else {
            this.info.message = error.message;
            this.info.color = "red";
          }
        }
      }
    },
    async sendWelcomeEmail({ email, code }) {
      // has to be stringified
      // post api => /send
      let emailobj = {
        context: "validation",
        user: email,
        variables: { code }
      };
      axios.post(process.env.VUE_APP_API + "send", JSON.stringify(emailobj));
      // TODO envoyer le mail de bienvenu
    },
    findDuplicates(arr) {
      let noDup = [];
      let dup = [];

      arr.forEach(user => {
        if (noDup.includes(user.email.toLowerCase())) dup.push(user.email);
        else noDup.push(user.email.toLowerCase());
      });
      return dup;
    }
  }
};
</script>

<style lang="scss">
#modal-1 {
  .modal-dialog {
    max-width: 600px;
  }

  table {
    margin: 0.5rem auto 1.5rem auto;

    td,
    th {
      padding-right: 1rem;
    }
  }
}
</style>
