<template>
  <div>
    <div class="mt-8">
      <div class="mb-4">Stagiaires</div>
      <div class="border-line-left border rounded p-5">
        <div
          v-for="(intern, idx) in interns"
          :key="idx"
          class="grid grid-cols-12 gap-4 my-2"
        >
          <div class="col-span-3">
            <v-select
              class="w-full"
              :options="userNameList"
              :filterable="true"
              label="name"
              placeholder="Nom/Prénom de l'utilisateur"
              v-model="intern.user"
            />
          </div>

          <b-form-input
            class="col-span-3"
            v-if="intern.user"
            :value="intern.user.userOrganisations[0] ? intern.user.userOrganisations[0].organisations.name : null"
            type="text"
            disabled
          />
          <b-form-input
            class="col-span-3"
            v-if="intern.user"
            :value="intern.user.position"
            type="text"
            disabled
          />
          <b-form-input
            class="col-span-2"
            v-if="intern.user"
            :value="intern.user.seniority ? 'Expérimenté' : 'Novice'"
            type="text"
            disabled
          />

          <div
            class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1"
            @click="onRemoveInternClick(idx)"
          >
            <div class="shadow-twn rounded-full text-center w-8 h-8">-</div>
          </div>
        </div>
        <div
          class="flex justify-center text-xl font-bold items-center cursor-pointer"
          :class="{ 'mt-8': !!interns.length }"
          @click="onAddInternClick"
        >
          <div class="shadow-twn rounded-full text-center w-8 h-8">+</div>
        </div>
        <div class="w-1/2 ml-auto mt-8 border-t border-principal-gray">
          <div class="flex justify-between px-4 mt-4">
            <div>Total de stagiaires</div>
            <div>{{ nbInterns }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  model: {
    prop: "interns",
    event: "change",
  },
  props: {
    creating: Boolean,
    interns: {
      type: [Array, Object],
      default: () => [],
    },
  },
  computed: {
    ...mapState('Utils', ['userNameList']),
    nbInterns() {
      return this.interns.filter((intern) => intern && intern.user).length;
    },
  },
  methods: {
    onAddInternClick() {
      if (this.interns.length && ![...this.interns].pop().user) return;

      this.interns.push({});
    },
    onRemoveInternClick(idx) {
      this.interns.splice(idx, 1);
    },
  },
};
</script>