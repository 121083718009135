<template>
  <div
    id="nav"
    class="bg-gray-background text-principal-gray p-4"
    @mouseover="open()"
    @mouseleave="close()"
  >
    <div class="my-4 logo-container h-6">
      <div class="flex flex-row items-center">
        <img class="logo" src="@/assets/images/afpols_logo.svg" alt="" />
      </div>
    </div>
    <div class="nav-links w-full flex-grow" @click="toggleMobile">
      <router-link
        :to="{ name: 'dashboard' }"
        class="items-center navbar__nav-link"
      >
        <div>
          <svg
            version="1.1"
            class="twn-icon fill-current"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background:new 0 0 100 100;"
            xml:space="preserve"
          >
            <g>
              <path
                class="st0"
                d="M79.3,36.5c2.2-3.2,3.7-5.4,3.9-5.8c0.3-0.4,0.2-0.9-0.1-1.3c-0.3-0.3-0.9-0.4-1.3-0.1
                                c-1.4,1-35,23.8-38.1,26.9c-1.7,1.7-2.6,3.9-2.6,6.3s0.9,4.6,2.6,6.3c1.7,1.7,4,2.6,6.3,2.6s4.6-0.9,6.3-2.6
                                c2.2-2.2,14.6-20.2,21.8-30.7c5.7,6.6,8.8,15,8.8,24c0,4.5-0.8,9-2.4,13.2c-0.2,0.5,0.1,1.1,0.6,1.3c0.1,0,0.2,0.1,0.4,0.1
                                c0.4,0,0.8-0.2,0.9-0.6c1.7-4.5,2.6-9.2,2.6-14C89,52.6,85.5,43.5,79.3,36.5z M54.9,67.5c-2.7,2.7-7.1,2.7-9.8,0
                                c-1.3-1.3-2-3-2-4.9s0.7-3.6,2-4.9c2.1-2.1,20.9-15.1,33.5-23.7c-0.4,0.6-0.8,1.2-1.3,1.9c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.2,0.4
                                C68.6,48.7,57,65.5,54.9,67.5z"
              />
              <path
                class="st0"
                d="M70.3,31.2c0.5,0.3,1.1,0.2,1.4-0.3c0.3-0.5,0.2-1.1-0.3-1.4C65,25.4,57.6,23.2,50,23.2c-21.5,0-39,17.5-39,39
                                c0,4.9,0.9,9.6,2.7,14c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1c0.5-0.2,0.8-0.8,0.6-1.3c-1.7-4.1-2.5-8.6-2.5-13.2
                                c0-20.4,16.6-37,37-37C57.2,25.2,64.2,27.3,70.3,31.2z"
              />
              <path
                class="st0"
                d="M49,32.6v1.7c0,0.6,0.4,1,1,1s1-0.4,1-1v-1.7c0-0.6-0.4-1-1-1S49,32,49,32.6z"
              />
              <path
                class="st0"
                d="M80,61.6h-1.7c-0.6,0-1,0.4-1,1s0.4,1,1,1H80c0.6,0,1-0.4,1-1S80.6,61.6,80,61.6z"
              />
              <path
                class="st0"
                d="M21.8,61.6H20c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.7c0.6,0,1-0.4,1-1S22.3,61.6,21.8,61.6z"
              />
            </g>
          </svg>
          <span
            :class="isItemIsThePageWhereUserIs('dashboard') ? 'font-black' : ''"
            >Tableau de bord</span
          >
        </div>
      </router-link>
      <router-link
        class="items-center navbar__nav-link"
        :to="{ name: 'users-list' }"
      >
        <div>
          <svg
            class="twn-icon fill-current"
            data-name="Calque 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <defs></defs>
            <path
              class="cls-1"
              d="M51.24,49.49A12.28,12.28,0,1,1,63.52,37.21,12.3,12.3,0,0,1,51.24,49.49Zm0-22.56A10.28,10.28,0,1,0,61.52,37.21,10.29,10.29,0,0,0,51.24,26.93Z"
            />
            <path
              class="cls-1"
              d="M68,72.47H66V64.73a8,8,0,0,0-8-8H44.47a8,8,0,0,0-8,8v7.74h-2V64.73a10,10,0,0,1,10-10H58a10,10,0,0,1,10,10Z"
            />
            <path
              class="cls-1"
              d="M77.07,53.38a9.49,9.49,0,1,1,9.49-9.49A9.5,9.5,0,0,1,77.07,53.38Zm0-17a7.49,7.49,0,1,0,7.49,7.49A7.5,7.5,0,0,0,77.07,36.4Z"
            />
            <path
              class="cls-1"
              d="M89,72.47a1,1,0,0,1-1-1V66.4a5.79,5.79,0,0,0-5.78-5.79H72a1,1,0,0,1,0-2H82.17A7.8,7.8,0,0,1,90,66.4v5.07A1,1,0,0,1,89,72.47Z"
            />
            <path
              class="cls-1"
              d="M25.06,53.38a9.49,9.49,0,1,1,9.48-9.49A9.5,9.5,0,0,1,25.06,53.38Zm0-17a7.49,7.49,0,1,0,7.48,7.49A7.5,7.5,0,0,0,25.06,36.4Z"
            />
            <path
              class="cls-1"
              d="M13.17,72.47a1,1,0,0,1-1-1V66.4A7.81,7.81,0,0,1,20,58.61H30.15a1,1,0,0,1,0,2H20a5.8,5.8,0,0,0-5.79,5.79v5.07A1,1,0,0,1,13.17,72.47Z"
            />
          </svg>
          <span
            :class="
              isItemIsThePageWhereUserIs('users-list') ? 'font-black' : ''
            "
            >Utilisateurs</span
          >
        </div>
      </router-link>

      <div class="flex flex-col">
        <div
          @click="onItemClick('inscriptions')"
          @mouseover="openedItem = 'inscriptions'"
          class="navbar__nav-link flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-key twn-icon "
          >
            <path
              d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
            ></path>
          </svg>

          <span>Inscriptions</span>
        </div>
      </div>
      <transition name="fade-x">
        <div
          v-if="openedItem === 'inscriptions'"
          class="flex flex-col navbar__nav-link-children"
        >
          <!-- <router-link :to="{ name: 'scenario-list' }" class="flex items-center navbar__nav-link">
                            <svg class="twn-icon fill-current" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><path class="cls-1" d="M69.48,19.5h-7v-7a1,1,0,0,0-1-1h-46a1,1,0,0,0-1,1v65a1,1,0,0,0,1,1h7v7a1,1,0,0,0,1,1h34a13,13,0,0,0,13-13v-53A1,1,0,0,0,69.48,19.5Zm-53,57v-63h44V66.37a2.14,2.14,0,0,1-2.13,2.13H53.48a1,1,0,0,0-1,1v4.87a2.14,2.14,0,0,1-2.13,2.13Zm52-3a11,11,0,0,1-11,11h-33v-6H50.35a4.13,4.13,0,0,0,4.13-4.13V70.5h3.87a4.13,4.13,0,0,0,4.13-4.13V21.5h6Z"/><path class="cls-1" d="M25.52,28.5h26a1,1,0,0,0,0-2h-26a1,1,0,1,0,0,2Z"/><path class="cls-1" d="M25.52,38.5h26a1,1,0,0,0,0-2h-26a1,1,0,1,0,0,2Z"/><path class="cls-1" d="M52.48,47.5a1,1,0,0,0-1-1h-26a1,1,0,1,0,0,2h26A1,1,0,0,0,52.48,47.5Z"/><path class="cls-1" d="M82,19.5a3.5,3.5,0,0,0-3.5,3.5V80A3.5,3.5,0,0,0,81,83.34V87.5a1,1,0,0,0,2,0V83.34A3.5,3.5,0,0,0,85.48,80V23A3.5,3.5,0,0,0,82,19.5ZM83.48,80a1.5,1.5,0,0,1-3,0V38.5h3Zm0-43.5h-3V23a1.5,1.5,0,0,1,3,0Z"/></svg>
                            <span>Scénarios</span>
                        </router-link> -->
          <router-link
            :to="{ name: 'users-inscription' }"
            class="flex items-center navbar__nav-link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check twn-icon"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>

            <span>Nouvelle version</span>
          </router-link>
          <router-link
            :to="{ name: 'users-inscription-old' }"
            class="flex items-center navbar__nav-link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-clock twn-icon"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>

            <span>Ancienne version</span>
          </router-link>
        </div>
      </transition>

      <!-- <router-link class="items-center navbar__nav-link" :to="{ name: 'promotions-list' }">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="twn-icon fill-current" viewBox="0 0 78.11 79.45"><defs></defs><path class="a" d="M77.7,28.25,39.65.19a1,1,0,0,0-1.19,0L.41,28.25a1,1,0,0,0,0,1.61L10.77,37.5v32a1,1,0,0,0,.43.82,1,1,0,0,0,.92.12l4.75-1.74L24,79a1,1,0,0,0,.83.43.93.93,0,0,0,.29,0,1,1,0,0,0,.71-1V64.91A23.22,23.22,0,0,0,62.28,45.82V41.23L77.7,29.86a1,1,0,0,0,0-1.61ZM24.24,45.82a.5.5,0,0,0-.07.08A.86.86,0,0,0,24,46a.36.36,0,0,0,0,.09.55.55,0,0,0-.08.15,1.29,1.29,0,0,0-.06.25s0,.07,0,.11v28.6l-5.76-8.35a1,1,0,0,0-.82-.43,1,1,0,0,0-.34.06L12.77,68V37.48l17-12.5,11.37,8.4Zm36,0A21.21,21.21,0,0,1,25.83,62.41V48.6l12.63,9.31a1,1,0,0,0,1.19,0L60.28,42.7Zm-21.23,10L26.52,46.63l16.9-12.44a1,1,0,0,0,0-1.61L30.36,22.93a1,1,0,0,0-1.18,0L11.76,35.75l-9.07-6.7L39.05,2.24,75.42,29.05Z"/></svg>
                    <span :class="isItemIsThePageWhereUserIs('promotions-list') ? 'font-black' : ''">Promotions</span>
                </div>
            </router-link> -->
      <!-- <router-link class="items-center navbar__nav-link" :to="{ name: 'course-list' }">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="twn-icon fill-current" viewBox="0 0 78.11 79.45"><defs></defs><path class="a" d="M77.7,28.25,39.65.19a1,1,0,0,0-1.19,0L.41,28.25a1,1,0,0,0,0,1.61L10.77,37.5v32a1,1,0,0,0,.43.82,1,1,0,0,0,.92.12l4.75-1.74L24,79a1,1,0,0,0,.83.43.93.93,0,0,0,.29,0,1,1,0,0,0,.71-1V64.91A23.22,23.22,0,0,0,62.28,45.82V41.23L77.7,29.86a1,1,0,0,0,0-1.61ZM24.24,45.82a.5.5,0,0,0-.07.08A.86.86,0,0,0,24,46a.36.36,0,0,0,0,.09.55.55,0,0,0-.08.15,1.29,1.29,0,0,0-.06.25s0,.07,0,.11v28.6l-5.76-8.35a1,1,0,0,0-.82-.43,1,1,0,0,0-.34.06L12.77,68V37.48l17-12.5,11.37,8.4Zm36,0A21.21,21.21,0,0,1,25.83,62.41V48.6l12.63,9.31a1,1,0,0,0,1.19,0L60.28,42.7Zm-21.23,10L26.52,46.63l16.9-12.44a1,1,0,0,0,0-1.61L30.36,22.93a1,1,0,0,0-1.18,0L11.76,35.75l-9.07-6.7L39.05,2.24,75.42,29.05Z"/></svg>
                    <span :class="isItemIsThePageWhereUserIs('course-list') ? 'font-black' : ''">Parcours</span>
                </div>
            </router-link> -->
      <!-- <router-link class="items-center navbar__nav-link" :to="{ name: 'organisations-list' }" v-if="userInfo.role == 'superadmin'">
                <div>
                    <svg class="twn-icon fill-current" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><path class="cls-1" d="M63.38,74.1a1,1,0,0,0,1-1V49.39a1,1,0,0,0-2,0V73.1A1,1,0,0,0,63.38,74.1Z"/><path class="cls-1" d="M71.38,74.1a1,1,0,0,0,1-1V52.44a1,1,0,0,0-2,0V73.1A1,1,0,0,0,71.38,74.1Z"/><path class="cls-1" d="M28.73,74.1H46.62a1,1,0,1,0,0-2H28.73a1,1,0,0,0,0,2Z"/><path class="cls-1" d="M28.73,62.55H46.62a1,1,0,0,0,0-2H28.73a1,1,0,0,0,0,2Z"/><path class="cls-1" d="M28.73,51H46.62a1,1,0,1,0,0-2H28.73a1,1,0,0,0,0,2Z"/><path class="cls-1" d="M28.73,39.45H46.62a1,1,0,0,0,0-2H28.73a1,1,0,0,0,0,2Z"/><path class="cls-1" d="M28.73,27.9H46.62a1,1,0,0,0,0-2H28.73a1,1,0,1,0,0,2Z"/><path class="cls-1" d="M91.38,83.65h-11V44.09a1,1,0,0,0-.62-.93L56,33.42V15.35a1,1,0,0,0-1-1H20.62a1,1,0,0,0-1,1v68.3h-11a1,1,0,1,0,0,2H91.38a1,1,0,0,0,0-2ZM54,34.09V83.65H21.62V16.35H54ZM78.38,83.65H56V35.58l22.38,9.18Z"/></svg>
                    <span :class="isItemIsThePageWhereUserIs('organisations-list') ? 'font-black' : ''">Offices</span>
                </div>
            </router-link> -->
      <div v-if="isUserSuperAdmin" class="flex flex-col">
        <div
          @click="onItemClick('medias')"
          @mouseover="openedItem = 'medias'"
          class="navbar__nav-link flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="twn-icon fill-current"
            viewBox="0 0 80.73 80.73"
          >
            <defs></defs>
            <path
              class="a"
              d="M15.62,9.52a6.1,6.1,0,1,0,6.09,6.1A6.11,6.11,0,0,0,15.62,9.52Zm0,10.19a4.1,4.1,0,1,1,4.09-4.09A4.1,4.1,0,0,1,15.62,19.71Z"
            />
            <path
              class="a"
              d="M19.41,30.23V27.85a13.77,13.77,0,0,0,2.17-.9l1.69,1.68a1,1,0,0,0,1.41,0l3.95-3.95a1,1,0,0,0,0-1.41L27,21.58a12.11,12.11,0,0,0,.9-2.17h2.38a1,1,0,0,0,1-1V12.82a1,1,0,0,0-1-1H27.85A12.11,12.11,0,0,0,27,9.65L28.63,8a1,1,0,0,0,0-1.41L24.68,2.6a1,1,0,0,0-1.41,0L21.59,4.28a12.51,12.51,0,0,0-2.18-.9V1a1,1,0,0,0-1-1H12.82a1,1,0,0,0-1,1V3.38a12.11,12.11,0,0,0-2.17.9L8,2.6a1,1,0,0,0-1.41,0l-3.95,4A1,1,0,0,0,2.6,8L4.28,9.65a13.77,13.77,0,0,0-.9,2.17H1a1,1,0,0,0-1,1v5.59a1,1,0,0,0,1,1H3.39a12.85,12.85,0,0,0,.89,2.17L2.6,23.27a1,1,0,0,0,0,1.41l4,3.95a1,1,0,0,0,1.41,0L9.65,27a13.77,13.77,0,0,0,2.17.9v2.38a1,1,0,0,0,1,1h5.59A1,1,0,0,0,19.41,30.23Zm-2-3.15v2.15H13.82V27.08a1,1,0,0,0-.76-1A10.92,10.92,0,0,1,10,24.85,1,1,0,0,0,8.78,25L7.26,26.51,4.72,24l1.52-1.51a1,1,0,0,0,.14-1.23,10.8,10.8,0,0,1-1.26-3.06,1,1,0,0,0-1-.76H2V13.82H4.14a1,1,0,0,0,1-.76A10.52,10.52,0,0,1,6.38,10a1,1,0,0,0-.15-1.23L4.72,7.26,7.26,4.72,8.77,6.23A1,1,0,0,0,10,6.38a11,11,0,0,1,3.06-1.27,1,1,0,0,0,.76-1V2h3.59V4.14a1,1,0,0,0,.77,1,10.77,10.77,0,0,1,3.05,1.27,1,1,0,0,0,1.23-.15L24,4.72l2.54,2.54L25,8.77A1,1,0,0,0,24.85,10a10.43,10.43,0,0,1,1.27,3.06,1,1,0,0,0,1,.76h2.14v3.59H27.09a1,1,0,0,0-1,.76,10.71,10.71,0,0,1-1.27,3.06A1,1,0,0,0,25,22.46L26.51,24,24,26.51,22.46,25a1,1,0,0,0-1.23-.15,10.68,10.68,0,0,1-3.06,1.26A1,1,0,0,0,17.41,27.08Z"
            />
            <path
              class="a"
              d="M69,69.14V1.06a1,1,0,0,0-1-1H26.29a1,1,0,0,0-1,1,1,1,0,0,0,1,1H67V68.14H16.62v-34a1,1,0,0,0-2,0v35a1,1,0,0,0,1,1H68A1,1,0,0,0,69,69.14Z"
            />
            <path
              class="a"
              d="M79.73,10.65h-6a1,1,0,0,0,0,2h5V78.73H28.35v-4.2a1,1,0,0,0-2,0v5.2a1,1,0,0,0,1,1H79.73a1,1,0,0,0,1-1V11.65A1,1,0,0,0,79.73,10.65Z"
            />
            <path
              class="a"
              d="M38.08,13.82H59a1,1,0,0,0,0-2H38.08a1,1,0,0,0,0,2Z"
            />
            <path
              class="a"
              d="M38.08,25H59a1,1,0,0,0,0-2H38.08a1,1,0,0,0,0,2Z"
            />
            <path
              class="a"
              d="M23,35.23a1,1,0,0,0,1,1H59a1,1,0,0,0,0-2H24A1,1,0,0,0,23,35.23Z"
            />
            <path class="a" d="M59,45.43H24a1,1,0,0,0,0,2H59a1,1,0,0,0,0-2Z" />
          </svg>
          <span>Gestion des contenus</span>
        </div>

        <transition name="fade-x">
          <div
            v-if="openedItem === 'medias'"
            class="flex flex-col navbar__nav-link-children"
          >
            <!-- <router-link :to="{ name: 'scenario-list' }" class="flex items-center navbar__nav-link">
                            <svg class="twn-icon fill-current" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><path class="cls-1" d="M69.48,19.5h-7v-7a1,1,0,0,0-1-1h-46a1,1,0,0,0-1,1v65a1,1,0,0,0,1,1h7v7a1,1,0,0,0,1,1h34a13,13,0,0,0,13-13v-53A1,1,0,0,0,69.48,19.5Zm-53,57v-63h44V66.37a2.14,2.14,0,0,1-2.13,2.13H53.48a1,1,0,0,0-1,1v4.87a2.14,2.14,0,0,1-2.13,2.13Zm52-3a11,11,0,0,1-11,11h-33v-6H50.35a4.13,4.13,0,0,0,4.13-4.13V70.5h3.87a4.13,4.13,0,0,0,4.13-4.13V21.5h6Z"/><path class="cls-1" d="M25.52,28.5h26a1,1,0,0,0,0-2h-26a1,1,0,1,0,0,2Z"/><path class="cls-1" d="M25.52,38.5h26a1,1,0,0,0,0-2h-26a1,1,0,1,0,0,2Z"/><path class="cls-1" d="M52.48,47.5a1,1,0,0,0-1-1h-26a1,1,0,1,0,0,2h26A1,1,0,0,0,52.48,47.5Z"/><path class="cls-1" d="M82,19.5a3.5,3.5,0,0,0-3.5,3.5V80A3.5,3.5,0,0,0,81,83.34V87.5a1,1,0,0,0,2,0V83.34A3.5,3.5,0,0,0,85.48,80V23A3.5,3.5,0,0,0,82,19.5ZM83.48,80a1.5,1.5,0,0,1-3,0V38.5h3Zm0-43.5h-3V23a1.5,1.5,0,0,1,3,0Z"/></svg>
                            <span>Scénarios</span>
                        </router-link> -->
            <router-link
              to="/media/chapitre"
              class="flex items-center navbar__nav-link"
            >
              <svg
                class="twn-icon fill-current"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <defs></defs>
                <path
                  class="cls-1"
                  d="M69.48,19.5h-7v-7a1,1,0,0,0-1-1h-46a1,1,0,0,0-1,1v65a1,1,0,0,0,1,1h7v7a1,1,0,0,0,1,1h34a13,13,0,0,0,13-13v-53A1,1,0,0,0,69.48,19.5Zm-53,57v-63h44V66.37a2.14,2.14,0,0,1-2.13,2.13H53.48a1,1,0,0,0-1,1v4.87a2.14,2.14,0,0,1-2.13,2.13Zm52-3a11,11,0,0,1-11,11h-33v-6H50.35a4.13,4.13,0,0,0,4.13-4.13V70.5h3.87a4.13,4.13,0,0,0,4.13-4.13V21.5h6Z"
                />
                <path
                  class="cls-1"
                  d="M25.52,28.5h26a1,1,0,0,0,0-2h-26a1,1,0,1,0,0,2Z"
                />
                <path
                  class="cls-1"
                  d="M25.52,38.5h26a1,1,0,0,0,0-2h-26a1,1,0,1,0,0,2Z"
                />
                <path
                  class="cls-1"
                  d="M52.48,47.5a1,1,0,0,0-1-1h-26a1,1,0,1,0,0,2h26A1,1,0,0,0,52.48,47.5Z"
                />
                <path
                  class="cls-1"
                  d="M82,19.5a3.5,3.5,0,0,0-3.5,3.5V80A3.5,3.5,0,0,0,81,83.34V87.5a1,1,0,0,0,2,0V83.34A3.5,3.5,0,0,0,85.48,80V23A3.5,3.5,0,0,0,82,19.5ZM83.48,80a1.5,1.5,0,0,1-3,0V38.5h3Zm0-43.5h-3V23a1.5,1.5,0,0,1,3,0Z"
                />
              </svg>
              <span>Chapitres</span>
            </router-link>
            <router-link
              to="/media/planche"
              class="flex items-center navbar__nav-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="twn-icon fill-current p-1"
                viewBox="0 0 77.48 77.67"
              >
                <defs></defs>
                <path
                  class="a"
                  d="M43.74,17.14a1,1,0,0,0,1-1V7.76a1,1,0,1,0-2,0v8.38A1,1,0,0,0,43.74,17.14Z"
                />
                <path
                  class="a"
                  d="M49.19,21.6a1,1,0,0,0,1-1V3.3a1,1,0,1,0-2,0V20.6A1,1,0,0,0,49.19,21.6Z"
                />
                <path
                  class="a"
                  d="M54.65,17.14a1,1,0,0,0,1-1V7.76a1,1,0,1,0-2,0v8.38A1,1,0,0,0,54.65,17.14Z"
                />
                <path
                  class="a"
                  d="M60.11,21.6a1,1,0,0,0,1-1V3.3a1,1,0,1,0-2,0V20.6A1,1,0,0,0,60.11,21.6Z"
                />
                <path
                  class="a"
                  d="M65.56,23.9a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V22.9A1,1,0,0,0,65.56,23.9Z"
                />
                <path
                  class="a"
                  d="M71,2.3a1,1,0,0,0-1,1V20.6a1,1,0,1,0,2,0V3.3A1,1,0,0,0,71,2.3Z"
                />
                <path
                  class="a"
                  d="M76.48,6.76a1,1,0,0,0-1,1v8.38a1,1,0,0,0,2,0V7.76A1,1,0,0,0,76.48,6.76Z"
                />
                <path
                  class="a"
                  d="M25.77,34.63a1,1,0,0,0-.5-.87L1.5,20.08a1,1,0,0,0-1,0A1,1,0,0,0,0,21V48.3a1,1,0,0,0,.5.87,1,1,0,0,0,.5.13,1,1,0,0,0,.5-.13L25.27,35.5A1,1,0,0,0,25.77,34.63ZM2,46.58V22.68l20.77,12Z"
                />
                <path
                  class="a"
                  d="M70.8,51.48H36.22a1,1,0,0,0-1,1V76.67a1,1,0,0,0,1,1H70.8a1,1,0,0,0,1-1V52.48A1,1,0,0,0,70.8,51.48Zm-1,24.19H37.22V67.25l7.34-7.34,7.67,7.67a1,1,0,0,0,1.41,0L56.24,65l7.19,7.19a1,1,0,0,0,1.41,0l1.46-1.46,3.5,3.5Zm0-4.27L67,68.6a1,1,0,0,0-1.41,0l-1.46,1.45-7.19-7.18a1,1,0,0,0-1.41,0l-2.6,2.59-7.66-7.67a1,1,0,0,0-1.42,0l-6.63,6.63V53.48H69.8Z"
                />
                <path
                  class="a"
                  d="M64.12,63.64a4.3,4.3,0,1,0-4.3-4.3A4.3,4.3,0,0,0,64.12,63.64Zm0-6.6a2.3,2.3,0,1,1-2.3,2.3A2.3,2.3,0,0,1,64.12,57Z"
                />
                <path
                  class="a"
                  d="M28.76,63.28A26.3,26.3,0,0,1,14.69,49.73a1,1,0,0,0-1.82.83A28.35,28.35,0,0,0,28,65.13a1,1,0,0,0,.37.07,1,1,0,0,0,.38-1.92Z"
                />
                <path
                  class="a"
                  d="M65.13,38.83a26.34,26.34,0,0,1-1,7.14,1,1,0,0,0,.69,1.23,1.26,1.26,0,0,0,.27,0,1,1,0,0,0,1-.73,28.74,28.74,0,0,0,1-7.68,28.17,28.17,0,0,0-2-10.45,1,1,0,0,0-1.86.73A26.43,26.43,0,0,1,65.13,38.83Z"
                />
                <path
                  class="a"
                  d="M17.75,22.22a1,1,0,0,0,.77-.35,26.3,26.3,0,0,1,17.07-9.25,1,1,0,1,0-.23-2A28.25,28.25,0,0,0,17,20.58,1,1,0,0,0,17.11,22,1,1,0,0,0,17.75,22.22Z"
                />
              </svg>
              <span>Importer/Paramétrer une planche</span>
            </router-link>
            <router-link
              to="/games/list"
              class="flex items-center navbar__nav-link"
            >
              <svg
                class="twn-icon fill-current"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <defs></defs>
                <path
                  class="cls-1"
                  d="M50,57a7,7,0,1,0-7-7A7,7,0,0,0,50,57Zm0-12a5,5,0,1,1-5,5A5,5,0,0,1,50,45Z"
                />
                <path
                  class="cls-1"
                  d="M69.32,30.68a1,1,0,0,0,.7.3,1,1,0,0,0,.71-.3L76,25.41l5.27,5.27A1,1,0,0,0,82,31a1,1,0,0,0,.7-1.71L77.41,24l5.27-5.27a1,1,0,0,0-1.41-1.41L76,22.59l-5.27-5.27a1,1,0,0,0-1.41,1.41L74.59,24l-5.27,5.27A1,1,0,0,0,69.32,30.68Z"
                />
                <path
                  class="cls-1"
                  d="M30.68,69.32a1,1,0,0,0-1.41,0L24,74.59l-5.27-5.27a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.41L22.59,76l-5.27,5.27a1,1,0,0,0,0,1.41,1,1,0,0,0,.7.3,1,1,0,0,0,.71-.3L24,77.41l5.27,5.27A1,1,0,0,0,30,83a1,1,0,0,0,.7-.3,1,1,0,0,0,0-1.41L25.41,76l5.27-5.27A1,1,0,0,0,30.68,69.32Z"
                />
                <path
                  class="cls-1"
                  d="M85.49,62H64V38H85.49a1,1,0,0,0,0-2H64V14.51a1,1,0,0,0-2,0V36H38V14.51a1,1,0,1,0-2,0V36H14.51a1,1,0,1,0,0,2H36V62H14.51a1,1,0,0,0,0,2H36V85.49a1,1,0,0,0,2,0V64H62V85.49a1,1,0,1,0,2,0V64H85.49a1,1,0,1,0,0-2ZM62,62H38V38H62Z"
                />
              </svg>
              <span>Mini-jeux</span>
            </router-link>
            <router-link
              to="/media/library"
              class="flex items-center navbar__nav-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="twn-icon fill-current p-1"
                viewBox="0 0 77.48 77.67"
              >
                <defs></defs>
                <path
                  class="a"
                  d="M43.74,17.14a1,1,0,0,0,1-1V7.76a1,1,0,1,0-2,0v8.38A1,1,0,0,0,43.74,17.14Z"
                />
                <path
                  class="a"
                  d="M49.19,21.6a1,1,0,0,0,1-1V3.3a1,1,0,1,0-2,0V20.6A1,1,0,0,0,49.19,21.6Z"
                />
                <path
                  class="a"
                  d="M54.65,17.14a1,1,0,0,0,1-1V7.76a1,1,0,1,0-2,0v8.38A1,1,0,0,0,54.65,17.14Z"
                />
                <path
                  class="a"
                  d="M60.11,21.6a1,1,0,0,0,1-1V3.3a1,1,0,1,0-2,0V20.6A1,1,0,0,0,60.11,21.6Z"
                />
                <path
                  class="a"
                  d="M65.56,23.9a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V22.9A1,1,0,0,0,65.56,23.9Z"
                />
                <path
                  class="a"
                  d="M71,2.3a1,1,0,0,0-1,1V20.6a1,1,0,1,0,2,0V3.3A1,1,0,0,0,71,2.3Z"
                />
                <path
                  class="a"
                  d="M76.48,6.76a1,1,0,0,0-1,1v8.38a1,1,0,0,0,2,0V7.76A1,1,0,0,0,76.48,6.76Z"
                />
                <path
                  class="a"
                  d="M25.77,34.63a1,1,0,0,0-.5-.87L1.5,20.08a1,1,0,0,0-1,0A1,1,0,0,0,0,21V48.3a1,1,0,0,0,.5.87,1,1,0,0,0,.5.13,1,1,0,0,0,.5-.13L25.27,35.5A1,1,0,0,0,25.77,34.63ZM2,46.58V22.68l20.77,12Z"
                />
                <path
                  class="a"
                  d="M70.8,51.48H36.22a1,1,0,0,0-1,1V76.67a1,1,0,0,0,1,1H70.8a1,1,0,0,0,1-1V52.48A1,1,0,0,0,70.8,51.48Zm-1,24.19H37.22V67.25l7.34-7.34,7.67,7.67a1,1,0,0,0,1.41,0L56.24,65l7.19,7.19a1,1,0,0,0,1.41,0l1.46-1.46,3.5,3.5Zm0-4.27L67,68.6a1,1,0,0,0-1.41,0l-1.46,1.45-7.19-7.18a1,1,0,0,0-1.41,0l-2.6,2.59-7.66-7.67a1,1,0,0,0-1.42,0l-6.63,6.63V53.48H69.8Z"
                />
                <path
                  class="a"
                  d="M64.12,63.64a4.3,4.3,0,1,0-4.3-4.3A4.3,4.3,0,0,0,64.12,63.64Zm0-6.6a2.3,2.3,0,1,1-2.3,2.3A2.3,2.3,0,0,1,64.12,57Z"
                />
                <path
                  class="a"
                  d="M28.76,63.28A26.3,26.3,0,0,1,14.69,49.73a1,1,0,0,0-1.82.83A28.35,28.35,0,0,0,28,65.13a1,1,0,0,0,.37.07,1,1,0,0,0,.38-1.92Z"
                />
                <path
                  class="a"
                  d="M65.13,38.83a26.34,26.34,0,0,1-1,7.14,1,1,0,0,0,.69,1.23,1.26,1.26,0,0,0,.27,0,1,1,0,0,0,1-.73,28.74,28.74,0,0,0,1-7.68,28.17,28.17,0,0,0-2-10.45,1,1,0,0,0-1.86.73A26.43,26.43,0,0,1,65.13,38.83Z"
                />
                <path
                  class="a"
                  d="M17.75,22.22a1,1,0,0,0,.77-.35,26.3,26.3,0,0,1,17.07-9.25,1,1,0,1,0-.23-2A28.25,28.25,0,0,0,17,20.58,1,1,0,0,0,17.11,22,1,1,0,0,0,17.75,22.22Z"
                />
              </svg>
              <span>Bibliothèque de documents</span>
            </router-link>
            <router-link
              to="/media/list"
              class="flex items-center navbar__nav-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="twn-icon fill-current p-1"
                viewBox="0 0 77.48 77.67"
              >
                <defs></defs>
                <path
                  class="a"
                  d="M43.74,17.14a1,1,0,0,0,1-1V7.76a1,1,0,1,0-2,0v8.38A1,1,0,0,0,43.74,17.14Z"
                />
                <path
                  class="a"
                  d="M49.19,21.6a1,1,0,0,0,1-1V3.3a1,1,0,1,0-2,0V20.6A1,1,0,0,0,49.19,21.6Z"
                />
                <path
                  class="a"
                  d="M54.65,17.14a1,1,0,0,0,1-1V7.76a1,1,0,1,0-2,0v8.38A1,1,0,0,0,54.65,17.14Z"
                />
                <path
                  class="a"
                  d="M60.11,21.6a1,1,0,0,0,1-1V3.3a1,1,0,1,0-2,0V20.6A1,1,0,0,0,60.11,21.6Z"
                />
                <path
                  class="a"
                  d="M65.56,23.9a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V22.9A1,1,0,0,0,65.56,23.9Z"
                />
                <path
                  class="a"
                  d="M71,2.3a1,1,0,0,0-1,1V20.6a1,1,0,1,0,2,0V3.3A1,1,0,0,0,71,2.3Z"
                />
                <path
                  class="a"
                  d="M76.48,6.76a1,1,0,0,0-1,1v8.38a1,1,0,0,0,2,0V7.76A1,1,0,0,0,76.48,6.76Z"
                />
                <path
                  class="a"
                  d="M25.77,34.63a1,1,0,0,0-.5-.87L1.5,20.08a1,1,0,0,0-1,0A1,1,0,0,0,0,21V48.3a1,1,0,0,0,.5.87,1,1,0,0,0,.5.13,1,1,0,0,0,.5-.13L25.27,35.5A1,1,0,0,0,25.77,34.63ZM2,46.58V22.68l20.77,12Z"
                />
                <path
                  class="a"
                  d="M70.8,51.48H36.22a1,1,0,0,0-1,1V76.67a1,1,0,0,0,1,1H70.8a1,1,0,0,0,1-1V52.48A1,1,0,0,0,70.8,51.48Zm-1,24.19H37.22V67.25l7.34-7.34,7.67,7.67a1,1,0,0,0,1.41,0L56.24,65l7.19,7.19a1,1,0,0,0,1.41,0l1.46-1.46,3.5,3.5Zm0-4.27L67,68.6a1,1,0,0,0-1.41,0l-1.46,1.45-7.19-7.18a1,1,0,0,0-1.41,0l-2.6,2.59-7.66-7.67a1,1,0,0,0-1.42,0l-6.63,6.63V53.48H69.8Z"
                />
                <path
                  class="a"
                  d="M64.12,63.64a4.3,4.3,0,1,0-4.3-4.3A4.3,4.3,0,0,0,64.12,63.64Zm0-6.6a2.3,2.3,0,1,1-2.3,2.3A2.3,2.3,0,0,1,64.12,57Z"
                />
                <path
                  class="a"
                  d="M28.76,63.28A26.3,26.3,0,0,1,14.69,49.73a1,1,0,0,0-1.82.83A28.35,28.35,0,0,0,28,65.13a1,1,0,0,0,.37.07,1,1,0,0,0,.38-1.92Z"
                />
                <path
                  class="a"
                  d="M65.13,38.83a26.34,26.34,0,0,1-1,7.14,1,1,0,0,0,.69,1.23,1.26,1.26,0,0,0,.27,0,1,1,0,0,0,1-.73,28.74,28.74,0,0,0,1-7.68,28.17,28.17,0,0,0-2-10.45,1,1,0,0,0-1.86.73A26.43,26.43,0,0,1,65.13,38.83Z"
                />
                <path
                  class="a"
                  d="M17.75,22.22a1,1,0,0,0,.77-.35,26.3,26.3,0,0,1,17.07-9.25,1,1,0,1,0-.23-2A28.25,28.25,0,0,0,17,20.58,1,1,0,0,0,17.11,22,1,1,0,0,0,17.75,22.22Z"
                />
              </svg>
              <span>Médias</span>
            </router-link>
          </div>
        </transition>
      </div>
    </div>
    <div
      @click="toggleNav"
      class="arrows bootstrap md:visible md:block text-center cursor-pointer w-full"
    >
      <b-icon-arrow-up class="hidden h1 up-arrow m-auto"></b-icon-arrow-up>
      <b-icon-arrow-right class="right-arrow h1 m-0"></b-icon-arrow-right>
      <div class="left-arrow hidden justify-between items-center">
        <span
          class="uppercase ml-auto mr-auto font-semibold opacity-75 ease-linear"
          >Réduire</span
        >
        <b-icon-arrow-left class="left-arrow h1 mr-3"></b-icon-arrow-left>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import tailwindConf from "../../tailwind.config";

export default {
  name: "Navbar",
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      publicPath: process.env.BASE_URL,
      openedItem: null
    };
  },
  computed: {
    ...mapState("Auth", ["userInfo"]),
    isUserSuperAdmin() {
      return this.userInfo.role == "superadmin";
    }
  },
  methods: {
    ...mapActions("Auth", ["logout"]),
    ...mapActions("Header", ["toggleNav", "openNav", "closeNav"]),
    toggleMobile(e) {
      if (this.window.width <= parseInt(tailwindConf.theme.screens.sm, 10)) {
        this.toggleNav(e);
      }
    },
    open() {
      this.openNav();
    },
    close() {
      this.closeNav();
      this.openedItem = null;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    onItemClick(itemName) {
      this.openedItem = this.openedItem === itemName ? null : itemName;
    },
    isItemIsThePageWhereUserIs(item) {
      return this.$route.matched.some(({ name }) => name === item);
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
<style lang="scss">
#nav {
  @apply border-line-left left-0 right-auto overflow-y-auto flex flex-col items-center;
  border-right-width: 1px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transform: translate3d(0px, -100vh, 0) !important;
  overflow-x: hidden;
  // width: $navbarLength;
  width: 100%;
  z-index: 99;

  > .arrows {
    @apply block;
    .up-arrow {
      @apply block;
    }
    .right-arrow {
      @apply hidden;
    }
  }

  .logo {
    @apply w-full;
    max-width: 5rem;
  }

  &.nav-open {
    transform: translate3d(0px, 0, 0) !important;
    .logo {
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  }

  > div:not(.arrows) {
    @apply flex flex-col;

    &.logo-container {
      @apply self-start;
    }
    &.nav-links {
      @apply text-xs tracking-tighter leading-loose;
      opacity: 0.9;

      .navbar__nav-link {
        div {
          @apply flex items-center;
        }
        &:hover {
          @apply text-principal-selected no-underline cursor-pointer;
        }
      }

      div,
      a {
        .twn-icon {
          @apply mb-0 transition duration-100 ease-linear;
          width: 40px;
          height: 40px;
        }
        span {
          @apply transition duration-100 ease-linear tracking-normal text-base absolute;
          @screen md {
            @apply text-sm;
          }
          left: 70px;
        }
        .navbar__nav-link-children {
          a {
            @apply mt-4;
          }
          span {
            @apply text-xs;
          }
        }
      }

      > * {
        @apply my-1 p-1 rounded;
        @screen xs {
          @apply my-3;
        }
      }
    }
  }

  @screen md {
    @apply flex flex-col;
    overflow-y: none;
    width: $navbarLength;

    transform: translate3d(0px, 0, 0) !important;

    .arrows {
      .up-arrow {
        @apply hidden;
      }
    }
    .right-arrow {
      @apply hidden m-0;
    }
    .left-arrow {
      @apply flex visible m-0;
    }
    .nav-links {
      span {
        @apply opacity-100;
      }
    }
    &:not(.nav-open) {
      // @apply justify-between;
      // .arrows{
      //     @apply mt-0;
      // }
      .left-arrow {
        @apply hidden;
      }
      .right-arrow {
        @apply block visible;
      }
      width: 5rem !important;
      // .logo{
      //     @apply w-full;
      // }
      .nav-links {
        // .twn-icon {
        //     margin: 0 !important;
        // }
        span {
          @apply opacity-0;

          // @apply hidden;
        }
      }
    }
  }
}
</style>
