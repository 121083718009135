<template>
  <div class="select-container">
    <span class="select-label">{{label}}</span>
    <v-select
    class="twn-select flex-grow"
    :name="ikey"
    v-model="value"
    label="name"
    :options="options"
    :searchable="false"
    :clearable="multiple"
    :multiple="multiple"
    @input="update"
    @pointerdown.stop
    ></v-select>
  </div>
</template>


<script>
import store from "../../store"

export default {
  props: {
    ikey: String,
    label: String,
    data_source: Object,
    multiple: Boolean,
    getData: Function,
    putData: Function,
  },
  data() {
    return {
      value: undefined
    }
  },
  computed: {
    options() {
      // Static options
      if (this.data_source.options)
        return this.data_source.options

      // Options from the vuex store state
      if (this.data_source.store) {
        // With or without a sub module
        if (this.data_source.store.module) {
          return store.state[this.data_source.store.module][this.data_source.store.state]
        } else {
          return store.state[this.data_source.store.state]
        }
      } else if (this.data_source.storeFn) {
        return this.data_source.storeFn(store)
      }

      return []
    },
  },
  watch: {
    options: {
      handler(options, oldOptions) {
        this.reloadValueData()
      },
      immediate: true,
    },
    value(val, oldVal) {
      if (val == oldVal)
        return
      
      // Use custom event to make them available outside of the current Vue.js context
      this.$el.dispatchEvent(new CustomEvent('content-update', { bubbles: true, composed: true }))
    }
  },
  mounted() {
    this.reloadValueData()
  },
  methods: {
    reloadValueData() {// todo
      this.value = this.getData(this.ikey)

      if (this.options.length > 0) {
        if (this.value == undefined) {
          this.value = this.options[0]
        } else {
          // Find the right options by id
          const option = this.options.find((opt) => {
            if (opt.id == this.value) {
              return true
            }

            return false
          })

          this.value = (option || this.options[0])
        }

        this.update(this.value)
      }
    },
    update(option) {
      if (this.ikey)
        this.putData(this.ikey, option.id)
    },
  },
}
</script>

<style lang="scss">
.select-container {
  display: flex;
  word-break: break-all;

  .select-label {
    color: $textLight;
    min-width: 70px;
    line-height: 34px;
    vertical-align: middle;
  }

  .twn-select {
    padding-right: 10px;
  }
}
</style>
