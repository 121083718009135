import gql from "graphql-tag";

const GC_USER_INFOS = gql`
  query getCurrentUser($id: uuid!) {
    users(where: { id: { _eq: $id } }) {
      id
      email
      role
    }
  }
`;

const CG_GET_ORGA = gql`
  query getCurrentOrga($id: uuid!) {
    organisations(where: { admin_id: { _eq: $id } }) {
      id
      name
    }
  }
`;

const GC_USER_INSCRIPTION = gql`
  mutation insertUserInscription($user: [user_inscription_insert_input!]!) {
    insert_user_inscription(objects: $user) {
      affected_rows
    }
  }
`;
const GC_USER_INSCRIPTION_UPDATE = gql`
  mutation updateUserInscription($id: uuid!, $code: String!) {
    update_user_inscription(
      _set: { code: $code }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

const GC_GET_USER_INSCR_BY_ID = gql`
  query getUserInscriptionData($id: uuid!) {
    user_inscription(where: { id: { _eq: $id } }) {
      id
      firstname
      lastname
      email
      status
      adress
      structure
      city
      cedex
      nb_lots
      residence_id
      canSendData
      code
      hasEnteredCode
    }
  }
`;
const GC_USER_INSCRIPTION_TRACK = gql`
  mutation MyMutation(
    $type: Int!
    $lastname: String!
    $firstname: String!
    $email: String!
  ) {
    insert_user_inscription_tracks(
      objects: {
        type: $type
        lastname: $lastname
        firstname: $firstname
        email: $email
      }
    ) {
      affected_rows
    }
  }
`;
const GC_GET_USER_INSCR_TRACKS = gql`
  query MyQuery {
    user_inscription_tracks {
      id
      updated_at
      type
      created_at
      email
      firstname
      lastname
    }
  }
`;
const GC_CHECK_REGISTERED_EMAILS = gql`
  query checkRegisteredEmails($emails: [String!]!) {
    user_inscription(where: { email: { _in: $emails } }) {
      id
      email
    }
    users(where: { email: { _in: $emails } }) {
      id
      email
    }
    imported_users_with_mail(where: { mail: { _in: $emails } }) {
      id
      email: mail
    }
  }
`;

const IMPORT_USERS_WITH_MAIL = gql`
  mutation importUsersWithMail(
    $objects: [imported_users_with_mail_insert_input!]!
  ) {
    insert_imported_users_with_mail(objects: $objects) {
      affected_rows
      returning {
        id
        mail
      }
    }
  }
`;
const CREATE_IMPORT = gql`
  mutation createImport($type: String!, $name: String!) {
    insert_imports_one(object: { type: $type, name: $name }) {
      id
    }
  }
`;
const GET_IMPORTS = gql`
  query getMailImports($type: String!) {
    imports(where: { type: { _eq: $type } }, order_by: { created_at: desc }) {
      id
      type
      created_at
      name
      imported_with_mail {
        acquisition_id
        id
        import_id
        is_linked
        last_sent_mail_date
        mail
        updated_at
        was_mail_sent
        created_at
      }
      imported_mailless {
        acquisition_id
        created_at
        firstname
        registred
        id
        import_id
        lastname
        updated_at
        zipcode
      }
    }
  }
`;
const IMPORT_USERS_MAILLESS = gql`
  mutation importUserMailless(
    $objects: [imported_users_mailless_insert_input!]!
  ) {
    insert_imported_users_mailless(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_ONE_MAILLESS = gql`
  mutation toggleMaillessRegistred($registred: Boolean!, $id: uuid!) {
    update_imported_users_mailless(
      where: { id: { _eq: $id } }
      _set: { registred: $registred }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_ONE_WITH_MAIL = gql`
  mutation updateImportWithMail(
    $id: uuid!
    $last_sent_mail_date: date!
    $was_mail_sent: Boolean!
  ) {
    update_imported_users_with_mail(
      where: { id: { _eq: $id } }
      _set: {
        last_sent_mail_date: $last_sent_mail_date
        was_mail_sent: $was_mail_sent
      }
    ) {
      affected_rows
    }
  }
`;
const GET_SPONTANEOUS = gql`
  query getSpontaneous {
    user_inscription(
      where: {
        created_at: { _gte: "02-20-2022" }
        residence_id: { _is_null: true }
      }
      order_by: { created_at: desc }
    ) {
      id
      email
      firstname
      user_id
      lastname
      created_at
    }
    users(
      where: { created_at: { _gte: "02-20-2022" } }
      order_by: { created_at: desc }
    ) {
      id
      email
      created_at
      firstname: first_name
      lastname: name
    }
  }
`;

/**
 * "02-20-2022" is the date of the switch to the new authentification process,
 * any user_inscription row before this date can not be considered as a
 * spontaneous inscription.
 */

const SEARCH_IMPORTS_INSCRIPTIONS = gql`
  query searchImportsInscriptions($query: String!) {
    mailless: imported_users_mailless(
      where: {
        _or: [{ lastname: { _like: $query } }, { firstname: { _like: $query } }]
      }
    ) {
      firstname
      lastname
      created_at
      registred
    }
    mail: imported_users_with_mail(
      where: { _or: [{ mail: { _like: $query } }] }
    ) {
      mail
      acquisition_id
      was_mail_sent
      last_sent_mail_date
      is_linked
      created_at
    }
    spontaneous: user_inscription(
      where: {
        _or: [
          { firstname: { _like: $query } }
          { lastname: { _like: $query } }
          { email: { _like: $query } }
        ]
      }
    ) {
      email
      firstname
      lastname
      created_at
    }
  }
`;

export {
  GC_USER_INFOS,
  CG_GET_ORGA,
  GC_USER_INSCRIPTION,
  GC_USER_INSCRIPTION_UPDATE,
  GC_GET_USER_INSCR_BY_ID,
  GC_USER_INSCRIPTION_TRACK,
  GC_GET_USER_INSCR_TRACKS,
  IMPORT_USERS_WITH_MAIL,
  GC_CHECK_REGISTERED_EMAILS,
  CREATE_IMPORT,
  GET_IMPORTS,
  IMPORT_USERS_MAILLESS,
  UPDATE_ONE_MAILLESS,
  UPDATE_ONE_WITH_MAIL,
  GET_SPONTANEOUS,
  SEARCH_IMPORTS_INSCRIPTIONS,
};
