import gql from 'graphql-tag';

const GC_GET_USER_N_ORGAS = gql`
query GetUserInfo ($id:uuid!){
    users(where: {id: {_eq: $id}}) {
        id
        email
        first_name
        name
        onv_status
        structure
        role
        adress
        city
        cedex
        nb_lots
        residence_id
        phone
        userOrganisations {
            organisations {
                name
                id
            }
        }
    }
    organisations_aggregate(distinct_on: id) {
        nodes {
            name
            id
        }
    }
    users_aggregate(distinct_on: experienced) {
        nodes {
            experienced
        }
    }
}
`


const GC_EDIT_USER = gql`
mutation UpdateUser($id:uuid!, $email:String!, $status: Int,$onv_status: Int, $role: String, $fName:String!, $lName:String!, $adress: String, $cedex: String, $city: String, $phone: String, $structure: String, $residence_id: String, $nb_lots: Int) {
    update_users_by_pk(pk_columns: {id: $id}, _set: {
        email: $email,
        first_name: $fName,
        name: $lName,
        role: $role,
        adress: $adress,
        cedex: $cedex,
        city: $city,
        phone: $phone,
        structure: $structure,
        residence_id: $residence_id,
        nb_lots: $nb_lots,
        onv_status: $onv_status,
    }) {
      id
    }
    # delete_user_organisations(where: {user: {_eq: $id}}) {
    #     affected_rows
    # }
    # insert_user_organisations(objects: $orgas) {
    #     affected_rows
    # }
  }
`
const GC_EDIT_USER_INSCR = gql`
mutation UpdateUserInscr($id:uuid!, $email:String!, $firstname:String!, $lastname:String!, $structure: String!) {
    update_user_inscription_by_pk(pk_columns: {id: $id}, _set: {
        email: $email,
        firstname: $firstname,
        lastname: $lastname,
        structure: $structure,
    }) {
      id
    }
  }
`

const GC_ADD_USER_ONE = gql`
    mutation AddUser($email:String!, $role: String, $fName:String!, $lName:String, $id: uuid) {
        insert_users_one(
            object: {
                email: $email,
                first_name: $fName,
                name: $lName,
                role: $role,
                id: $id,
            }
        ){
            id
        }
    }
`
const GC_ADD_USER_INSCRIPTION_ONE = gql`
    mutation AddUserInscription($email:String!, $user_id: uuid!, $fName:String!, $lName:String) {
      insert_user_inscription_one(
            object: {
                email: $email,
                firstname: $fName,
                user_id: $user_id,
                lastname: $lName
            }
        ){
            id
        }
    }
`

const GC_EDIT_ORGA_ONE = gql`
mutation UpdateOrga($id: uuid!, $orga:organisations_set_input!) {
    update_organisations_by_pk(pk_columns: {id: $id}, _set: $orga) {
      id
    }
  }
`

const GC_ADD_ORGA_ONE = gql`
mutation AddOrga($orga:organisations_insert_input!) {
    insert_organisations_one(object: $orga) {
      id
    }
  }
`

const GC_GET_ORGA_ONE = gql`
query GetOrga($id: uuid!) {
    organisations_by_pk(id: $id) {
      name
      id
      city
      address2
      address
      post_code
      country
      admin_id
      federation_id
      housing
      hlm
      admin{
          id
          name
      }
      userOrganisations(distinct_on: user) {
        user
      }
    }
  }
`

export {
    GC_GET_USER_N_ORGAS,
    GC_EDIT_USER,
    GC_EDIT_ORGA_ONE,
    GC_GET_ORGA_ONE,
    GC_ADD_USER_ONE,
    GC_ADD_ORGA_ONE,
    GC_EDIT_USER_INSCR,
    GC_ADD_USER_INSCRIPTION_ONE
}