<template>
  <div>
    <List
      listing="promotion"
      :fields="fields"
      search-text="Rechercher une promotion"
      :pageSize="pageSize"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddPromotionClick"
          >
            Ajouter une nouvelle promotion
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from "@/components/List";
import { mapActions } from "vuex";

export default {
  components: {
    List,
  },
  props: {
    pageSize:{
      type: Number,
      default: 30,
      required: false
    }
  },
  data: () => ({
    fields: [
      {
        key: "name",
        label: "Nom de la promotion",
        sortable: true,
      },
      {
        key: "course_id",
        label: "Parcours associé",
        sortable: true,
        formatter: (courseID) => {
          return 'TODO ' + courseID
        }
      },
      {
        key: "start_date",
        label: "Date de début",
        sortable: true,
      },
      {
        key: "end_date",
        label: "Date de fin",
        sortable: true,
      },
      {
        key: "user_id",
        label: "Nombre de stagiaires",
        sortable: true,
        db: `users_aggregate {
          nodes {
            user_id
          }
        }`,
        nested: 'user_id'
      },
    ],
  }),

  methods: {
    ...mapActions("EditItem", ["clearCurrentID"]),
    onAddPromotionClick() {
      this.clearCurrentID();
      this.$router.push({ name: "promotions-create" });
    },
  },
};
</script>