<template>
  <section class="user-inscription">
    <div class="md:flex w-100 flex-row justify-between hidden user-buttons">
      <div class=" flex flex-row items-center align-self-end w-1/3 pr-2">
        <label class="mb-0" for="search">
          <b-icon-search class="h2 my-3 mx-2"></b-icon-search>
        </label>
        <input
          v-model="searchQuery"
          type="search"
          id="search"
          class="form-control w-4/5"
          placeholder="Rechercher un utilisateur"
          ref="search"
          @input="handleSearch"
        />
      </div>

      <!-- <button @click="addUser" type="button" class="hidden md:block twn-button whitespace-no-wrap overflow-hidden text-xs mr-2">Ajouter un utilisateur</button> -->
      <!-- <button
        v-if="currentTab === 0 || currentTab === 1"
        @click="$refs['import-modal'].isImportModalOpen = true"
        type="button"
        class="twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs ml-auto"
      >
        Importer des utilisateurs
      </button> -->
      <!-- <button
        v-else
        class="twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs ml-auto"
        @click="exportTracks"
      >
        Exporter le suivi
      </button>-->

      <b-dropdown id="dropdown-1" text="Importer" class="m-md-2">
        <b-dropdown-item @click="importWithMailModal = true"
          >Importer des utilisateurs avec adresse mail</b-dropdown-item
        >
        <b-dropdown-item @click="importMaillessModal = true"
          >Importer des utilisateurs sans adresse mail</b-dropdown-item
        >
      </b-dropdown>
    </div>

    <div>
      <b-tabs
        v-if="!searchQuery.length"
        v-model="currentTab"
        content-class="mt-3"
        @activate-tab="search = ''"
      >
        <b-tab title="Import utilisateurs avec adresse mail" active>
          <TableMail ref="mail" />
        </b-tab>
        <b-tab title="Import utilisateurs sans adresse mail">
          <TableMailless ref="mailless" />
        </b-tab>
        <b-tab title="Inscription spontanées">
          <TableSpontaneous ref="spontaneous" />
        </b-tab>
      </b-tabs>
      <TableSearchResults v-else ref="search" @clear="handleClear" />
    </div>

    <!-- Modal import d'user avec mail -->
    <UserMailModal
      v-if="importWithMailModal"
      @close="importWithMailModal = false"
      @complete="handleCompleteImportMail"
    ></UserMailModal>

    <UserMaillessModal
      v-if="importMaillessModal"
      @close="importMaillessModal = false"
      @complete="handleCompleteImportMailless"
    ></UserMaillessModal>
  </section>
</template>
<script>
import UserMailModal from "@/components/UserImport_Modal_Mail";
import UserMaillessModal from "@/components/UserImport_Modal_Mailless";
import TableMail from "@/components/TableImportMail";
import TableMailless from "@/components/TableImportMailless";
import TableSpontaneous from "../components/TableSpontaneous.vue";
import TableSearchResults from "../components/TableSearchResults.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserInscription",
  data() {
    return {
      searchStr: "",
      searchQuery: "",
      currentTab: 0,
      importWithMailModal: false,
      importMaillessModal: false,
      searchTimeout: null,
    };
  },
  components: {
    UserMailModal,
    UserMaillessModal,
    TableMail,
    TableMailless,
    TableSpontaneous,
    TableSearchResults,
  },
  watch: {
    currentTab: async function(curr) {
      switch (curr) {
        case 0:
          await this.$refs.mail.load();

          break;
        case 1:
          await this.$refs.mailless.load();
          break;
        case 2:
          await this.$refs.spontaneous.load();
          break;
        default:
          break;
      }
    },
  },
  computed: {
    ...mapGetters("Auth", ["results"]),
  },
  methods: {
    ...mapActions("Auth", ["searchInscriptions"]),
    handleClear() {
      this.searchQuery = "";
      this.currentTab = 0;
    },
    handleSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        await this.searchInscriptions(`%${this.searchQuery.toLowerCase()}%`);
        this.currentTab = 3;
      }, 1000);
    },
    handleCompleteImportMail() {
      this.currentTab = 0;
      this.$refs.mail.load();
    },
    handleCompleteImportMailless(count) {
      this.$bvToast.toast(count + " utilisateurs importés.", {
        variant: "success",
        toaster: "b-toaster-top-center",
        solid: true,
        noCloseButton: true,
      });

      this.currentTab = 1;
      this.$refs.mailless.load();
    },
  },
};
</script>
<style lang="scss">
// .user-buttons{
//     flex-basis: 33%;
// }
.user-inscription {
  .table_list {
    overflow-x: auto;
  }
}
#search.form-control {
  @apply text-principal-gray;
  border: none;
}
</style>
