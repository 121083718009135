import utils from "@/utils/trackingReportTemplate"

import statusLabels from "../userStatus"

export default {
	name: 'Par ID d\'acquisition',
	content: [
		{
			cells: [
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'Données d\'inscriptions' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'Données de connexion' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'Données pédagogiques' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' }
			]
		},
		{
			cells: [
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'nb d\'utilisateurs' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'taux d\'inscription' },
				{ text: 'durée totale de connexion' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'durée moyenne de connexion par utilisateur' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'durée maximale de connexion' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'taux d\'avancement moyen' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'taux de réussite moyen' },
				{ text: '' },
				{ text: '' },
				{ text: '' }
			]
		},
		{
			table: 'residences',
			headers() {
				const statusIdList = Object.keys(statusLabels)

				let headers = ['ID d\'acquisition', 'Ville', 'CP', 'nb de lots']

				// Add user total login time headers
				headers.push('total')

				for (var i = 0; i < statusIdList.length; i++) {
					headers.push(statusLabels[statusIdList[i]].toLowerCase())
				}

				headers.push('total')

				// Add residence users total login time headers
				headers.push('total')

				for (var j = 0; j < statusIdList.length; j++) {
					headers.push(statusLabels[statusIdList[j]].toLowerCase())
				}
				
				// Add residence users average login time headers
				headers.push('total')

				for (var k = 0; k < statusIdList.length; k++) {
					headers.push(statusLabels[statusIdList[k]].toLowerCase())
				}
				
				// Add residence users max login time headers
				headers.push('total')

				for (var l = 0; l < statusIdList.length; l++) {
					headers.push(statusLabels[statusIdList[l]].toLowerCase())
				}

				// Add residence users average progression headers
				headers.push('total')

				for (var m = 0; m < statusIdList.length; m++) {
					headers.push(statusLabels[statusIdList[m]].toLowerCase())
				}
				
				// Add residence users average success headers
				headers.push('total')

				for (var n = 0; n < statusIdList.length; n++) {
					headers.push(statusLabels[statusIdList[n]].toLowerCase())
				}

				return headers
			},
			row: (residence) => {
				// Get user stats data for this residence
				const lotsCount = parseInt(residence.lotsCount, 10)
				const statusIdList = Object.keys(statusLabels)

				// Get residence users subscription data
				const userByStatus = utils.groupBy(residence.users, 'onv_status')

				let userData = [{ value: residence.users.length }]

				for (var i = 0; i < statusIdList.length; i++) {
					if (userByStatus[statusIdList[i]]) {
						userData.push({ value: userByStatus[statusIdList[i]].length })
					} else {
						userData.push({ value: 0 })
					}
				}

				userData.push({ value: (lotsCount > 0 ? (residence.users.length / lotsCount) : '?'), type: 'percentage' })

				// Add residence users total login time data
				userData.push({ value: utils.sumField(residence.users, 'activeTimeData', 'sum'), type: 'duration' })

				for (var j = 0; j < statusIdList.length; j++) {
					if (userByStatus[statusIdList[j]]) {
						userData.push({ value: utils.sumField(userByStatus[statusIdList[j]], 'activeTimeData', 'sum'), type: 'duration' })
					} else {
						userData.push({ value: 0, type: 'duration' })
					}
				}
				
				// Add residence users average login time data
				userData.push({ value: utils.averageField(residence.users, 'activeTimeData', 'average'), type: 'duration' })

				for (var k = 0; k < statusIdList.length; k++) {
					if (userByStatus[statusIdList[k]]) {
						userData.push({ value: utils.averageField(userByStatus[statusIdList[k]], 'activeTimeData', 'average'), type: 'duration' })
					} else {
						userData.push({ value: 0, type: 'duration' })
					}
				}
				
				// Add residence users max login time data
				userData.push({ value: utils.maxField(residence.users, 'activeTimeData', 'max'), type: 'duration' })

				for (var l = 0; l < statusIdList.length; l++) {
					if (userByStatus[statusIdList[l]]) {
						userData.push({ value: utils.maxField(userByStatus[statusIdList[l]], 'activeTimeData', 'max'), type: 'duration' })
					} else {
						userData.push({ value: 0, type: 'duration' })
					}
				}

				// Add residence users average progression data
				userData.push({ value: utils.averageField(residence.users, 'progression'), type: 'percentage' })

				for (var m = 0; m < statusIdList.length; m++) {
					if (userByStatus[statusIdList[m]]) {
						userData.push({ value: utils.averageField(userByStatus[statusIdList[m]], 'progression'), type: 'percentage' })
					} else {
						userData.push({ value: 0, type: 'percentage' })
					}
				}
				
				// Add residence users average success data
				// Only count user with at least one success rate (ie. exclude user with no game result)
				let users = residence.users.filter((user) => {
					return user.successRate > -1
				})

				userData.push({ value: utils.averageField(users, 'successRate'), type: 'percentage' })

				for (var n = 0; n < statusIdList.length; n++) {
					if (userByStatus[statusIdList[n]]) {
						// Only count user with at least one success rate (ie. exclude user with no game result)
						users = userByStatus[statusIdList[n]].filter((user) => {
							return user.successRate > -1
						})

						userData.push({ value: utils.averageField(users, 'successRate'), type: 'percentage' })
					} else {
						userData.push({ value: 0, type: 'percentage' })
					}
				}

				return [
					'residenceId',
					'city',
					'postCode',
					'lotsCount',
				].concat(userData)
			},
		},
		{},
		{},
		{
			cells: [
				{ text: 'Précisions sur le calcul' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'Nb d\'inscrit / nb de lots' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'durée totale de connexion / nb utilisateurs' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'nb de cases visionnées / nb de cases total' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
				{ text: 'nb bonnes réponses / nb total de questions' },
				{ text: '' },
				{ text: '' },
				{ text: '' },
			]
		}
	]
}