<template>
  <b-modal
    visible
    @hide="handleHide"
    id="modal-xl"
    size="xl"
    :title="
      'Detail de l\'import #' +
        data.id +
        ' créé le ' +
        moment(data.created_at).format('dddd DD MMMM YYYY')
    "
    hide-footer
  >
    <transition name="fade" mode="out-in">
      <div
        v-if="progressSendBatch > 0"
        class="progress mb-5"
        style="height: 3px;"
      >
        <div
          class="progress-bar"
          role="progressbar"
          :style="'width:' + progressSendBatch * 100 + '%'"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </transition>

    <div class="d-flex justify-content-end align-items-end">
      <button
        v-if="type === 'mail'"
        @click="sendBatch"
        type="button"
        class="mb-2 twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
      >
        Envoyer un mail à la liste
      </button>
    </div>

    <b-table :fields="fields" :busy="loading" :items="items">
      <template v-if="type === 'mail'" #cell(send_mail)="data">
        <button
          @click="send(data.item)"
          type="button"
          class="mr-2 twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
        >
          Envoyer un mail
        </button>
      </template>
      <template v-if="type === 'mailless'" #cell(toggle)="data">
        <div class="flex">
          <b-form-checkbox
            name="check-button"
            @change="toggle(data.item)"
            v-model="data.item.registred"
            switch
          >
            Inscrit
          </b-form-checkbox>
        </div>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import axios from "axios";
import TrueFalseVue from "./common/game/TrueFalse.vue";
export default {
  props: ["data", "type"],
  data() {
    return {
      moment: moment,
      loading: false,
      progressSendBatch: 0,
      mailFields: [
        {
          key: "acquisition_id",
          label: "ID d'acquisition",
          formatter: id => {
            if (id) return id;
            else return "ID non renseigné!";
          }
        },
        { key: "mail", label: "Mail" },
        {
          key: "is_linked",
          label: "Statut",
          formatter: bool => {
            if (bool) return "Inscrit";
            else return "Pas inscrit";
          }
        },
        {
          key: "was_mail_sent",
          label: "Mail envoyé",
          formatter: bool => {
            if (bool) return "Oui";
            else return "Non";
          }
        },
        {
          key: "last_sent_mail_date",
          label: "Date du dernier mail",
          formatter: date => {
            if (date) return moment(date).format("dddd DD MMMM YYYY");
            else return "—";
          }
        },
        { key: "send_mail", label: "" }
      ],
      maillessFields: [
        { key: "acquisition_id", label: "ID d'acquisition" },
        {
          key: "lastname",
          label: "NOM",
          formatter: data => {
            if (data !== null) return data;
            else return "—";
          }
        },
        {
          key: "firstname",
          label: "Prénom",
          formatter: data => {
            if (data !== null) return data;
            else return "—";
          }
        },
        {
          key: "zipcode",
          label: "Code postal",
          formatter: data => {
            if (data !== null) return data;
            else return "—";
          }
        },
        { key: "toggle", label: "" }
      ]
    };
  },
  computed: {
    fields() {
      if (this.type === "mail") return this.mailFields;
      else return this.maillessFields;
    },
    items() {
      if (this.type === "mail") return this.data.imported_with_mail;
      else return this.data.imported_mailless;
    }
  },

  methods: {
    ...mapActions("Auth", ["updateMailless", "updateLastSentMail"]),
    async toggle(payload) {
      await this.updateMailless({
        id: payload.id,
        registred: !payload.registred
      });
      this.$emit("reload");
    },
    handleHide() {
      this.$emit("close");
    },
    async send(user) {
      let emailobj = {
        context: "inscription",
        variables: { code: "ok" },
        user: user.mail
      };

      try {
        await axios.post(
          process.env.VUE_APP_API + "send",
          JSON.stringify(emailobj)
        );
        await this.updateLastSentMail(user.id);
        this.$emit("reload");
        user.was_mail_sent = true;
        user.last_sent_mail_date = Date.now();

        this.$bvToast.toast("Email envoyé à " + user.mail, {
          variant: "success",
          solid: true,
          noCloseButton: true,
          toaster: "b-toaster-top-center"
        });
      } catch (e) {
        this.$bvToast.toast(
          "Une erreur est survenue lors de l'envoi du mail.",
          {
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
            noCloseButton: true
          }
        );
      }
    },
    async sendBatch() {
      const waiter = [];
      this.items.forEach((user, i) => {
        if (!user.is_linked) {
          setTimeout(async () => {
            let emailobj = {
              context: "inscription",
              variables: { code: "ok" },
              user: user.mail
            };

            const send = axios.post(
              process.env.VUE_APP_API + "send",
              JSON.stringify(emailobj)
            );
            const upd = this.updateLastSentMail(user.id);
            waiter.push(send, upd);
            this.progressSendBatch = (i + 1) / this.items.length;
          }, i * 200);
        }
      });
      setTimeout(() => {
        Promise.all(waiter).then(() => {
          this.$bvToast.toast(
            "Un mail a été envoyé à chaque utilisateur non inscrit de cet import.",
            {
              variant: "success",
              toaster: "b-toaster-top-center",
              solid: true,
              noCloseButton: true
            }
          );
          this.progressSendBatch = 0;
        });
      }, (this.items.length + 1) * 200);
    }
  }
};
</script>
