export default {
	zoneOrders(chapter) {
		// Create a dictionary with all zone id and their absolute order in this chapter
		let zoneOrders = chapter.planche_1.planche_zones.reduce((dict, zone, index) => {
			dict[zone.id] = index

			return dict
		}, {})

		// Get second planche zone orders with a delta to get the absolute order (relative to chapter and not to planche)
		const p1Delta = chapter.planche_1.planche_zones.length

		chapter.planche_2.planche_zones.forEach((zone, index) => {
			zoneOrders[zone.id] = (index + p1Delta)
		})

		// Add count
		zoneOrders.count = (chapter.planche_1.planche_zones.length + chapter.planche_2.planche_zones.length)

		return zoneOrders
	},
	games(chapter) {
		// Get game IDs list for this chapter from zone data
		const zones = chapter.planche_1.planche_zones.concat(chapter.planche_2.planche_zones)

		return zones.reduce((games, zone) => {
			// Add game id from all zone associated with one or more game
			zone.planche_zone_media.forEach((data) => {
				if (data.game_id) {
					games.push(data.game_id)
				}
			})

			return games
		}, [])
	}
}