import utils from "@/utils/trackingReportTemplate"

import statusLabels from "../userStatus"

export default {
	name: 'Global',
	content: [
		{ text: 'DONNEES D\'INSCRIPTION' },
		{},
		{ text: 'Nb d\'utilisateurs' },
		{
			text: 'Tous',
			value: (data) => data.users.length,
		},
		{
			text: (groupName) => (statusLabels[groupName] || ''),
			value: (groupData) => groupData.length,
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'onv_status'
			}
		},
		{},
		{},
		{ text: 'Nb d\'utilisateurs ayant terminé' },
		{
			text: 'Tous',
			value: (data) => utils.countMatch(data.users, (user) => {
				return (user.progression == 1)
			})
		},
		{
			text: (groupName) => (statusLabels[groupName] || ''),
			value: (groupData) => utils.countMatch(groupData, (user) => {
				return (user.progression == 1)
			}), 
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'onv_status'
			}
		},
		{},
		{ text: 'Nb d\'utilisateurs en cours' },
		{
			text: 'Tous',
			value: (data) => utils.countMatch(data.users, (user) => {
				return (user.progression > 0 && user.progression < 1)
			})
		},
		{
			text: (groupName) => (statusLabels[groupName] || ''),
			value: (groupData) => utils.countMatch(groupData, (user) => {
				return (user.progression > 0 && user.progression < 1)
			}), 
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'onv_status'
			}
		},
		{},
		{
			text: 'Nb de résidence (ID d\'acquisition) ayant au moins 1 inscrit',
			value: (data) => utils.countDistinct(data.users, 'experiences', true)
		},
		{},
		{ text: 'Nb de nouveau utilisateurs par semaine (en moyenne)' },
		{
			text: 'Tous',
			value: (data) => utils.groupAverage(utils.groupByWeek(data.users, 'createdWeekId', data.firstCreatedUserDate), (groupByWeekData) => groupByWeekData.length),
			type: 'float'
		},
		{
			text: (groupName) => (statusLabels[groupName] || ''),
			value: (groupData, data) => utils.groupAverage(utils.groupByWeek(groupData, 'createdWeekId', data.firstCreatedUserDate), (groupByWeekData) => groupByWeekData.length), 
			type: 'float',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'onv_status'
			}
		},
		{},
		{
			text: 'Taux d\'inscription',
			value: (data) => (utils.countMatch(data.users, (user) => (user.progression > 0)) / data.users.length),
			type: 'percentage'
		},
		{},
		{},
		{ text: 'DONNEES DE CONNEXION' },
		{},
		{ text: 'Durée totale de connexion' },
		{
			text: 'Pour tous les utilisateurs',
			value: (data) => utils.sumField(data.users, 'activeTimeData', 'sum'),
			type: 'duration'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData) => utils.sumField(groupData, 'activeTimeData', 'sum'),
			type: 'duration',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'onv_status'
			}
		},
		{},
		{ text: 'Durée moyenne de connexion par utilisateur' },
		{
			text: 'Pour tous les utilisateurs',
			value: (data) => utils.averageField(data.users, 'activeTimeData', 'average'),
			type: 'duration'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData) => utils.averageField(groupData, 'activeTimeData', 'average'),
			type: 'duration',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'onv_status'
			}
		},
		{},
		{ text: 'Durée maximale de connexion' },
		{
			text: 'Pour tous les utilisateurs',
			value: (data) => utils.maxField(data.users, 'activeTimeData', 'max'),
			type: 'duration'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData) => utils.maxField(groupData, 'activeTimeData', 'max'),
			type: 'duration',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'onv_status'
			}
		},
		{},
		{ text: 'Nb d\'utilisateurs actifs par semaine (en moyenne)' },
		{
			text: 'Pour tous les utilisateurs',
			value: (data) => {
				// Init weeks dictionary
				let activeUsersPerWeek = utils.fillMissingWeek({}, () => 0, data.firstCreatedUserDate)

				// Count active users
				activeUsersPerWeek = data.users.reduce((dict, user) => {
					const weekIds = Object.keys(user.activeTimeData.weekIds)

					weekIds.forEach((weekId) => {
						if (!dict[weekId])
							dict[weekId] = 0

						dict[weekId] += 1
					})

					return dict
				}, {})

				// Return average
				return utils.groupAverage(activeUsersPerWeek, (activeUsersCount) => activeUsersCount)
			},
			type: 'float'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData, data) => {
				// Init weeks dictionary
				let activeUsersPerWeek = utils.fillMissingWeek({}, () => 0, data.firstCreatedUserDate)

				// Count active users
				activeUsersPerWeek = groupData.reduce((dict, user) => {
					const weekIds = Object.keys(user.activeTimeData.weekIds)

					weekIds.forEach((weekId) => {
						if (!dict[weekId])
							dict[weekId] = 0

						dict[weekId] += 1
					})

					return dict
				}, {})

				// Return average
				return utils.groupAverage(activeUsersPerWeek, (activeUsersCount) => activeUsersCount)
			},
			type: 'float',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'onv_status'
			}
		},
		{},
		{ text: 'DONNEES PEDAGOGIQUES' },
		{},
		{ text: 'Taux d\'avancement moyen' },
		{
			text: 'Pour tous les utilisateurs',
			value: (data) => utils.averageField(data.users, 'progression'),
			type: 'percentage'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData) => utils.averageField(groupData, 'progression'),
			type: 'percentage',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'onv_status'
			}
		},
		{},
		{ text: 'Taux d\'avancement moyen Episodes' },
		{
			text: (groupName) => ('Taux d\'avancement moyen EP' + (groupName || '').toLowerCase()),
			value: (groupData, data) => {
				// Average user progression per chapter for the current chapter in the user list
				return utils.averageField(data.users, 'progressionPerChapter', groupData[0].id)
			},
			type: 'percentage',
			groupBy: {
				list: 'chapters',
				field: 'number'
			}
		},
		{},
		{ text: 'Taux de réussite moyen aux quiz' },
		{
			text: 'Pour tous les utilisateurs',
			value: (data) => {
				// Only count user with at least one success rate (ie. exclude user with no game result)
				const users = data.users.filter((user) => {
					return user.successRate > -1
				})

				return utils.averageField(users, 'successRate')
			},
			type: 'percentage'
		},
		{
			text: (groupName) => ('Pour les utilisateurs ' + (statusLabels[groupName] || '').toLowerCase()),
			value: (groupData) => {
				// Only count user with at least one success rate (ie. exclude user with no game result)
				const users = groupData.filter((user) => {
					return user.successRate > -1
				})

				return utils.averageField(users, 'successRate')
			},
			type: 'percentage',
			groupNames: Object.keys(statusLabels),
			groupBy: {
				list: 'users',
				field: 'onv_status'
			}
		},
		{},
		{ text: 'Taux de réussite moyen Episodes' },
		{
			text: (groupName) => ('Taux de réussite moyen EP' + (groupName || '').toLowerCase()),
			value: (groupData, data) => {
				// Average users success rate for the current chapter
				const results = data.users.reduce((results, user) => {
					// Only count user with a success rate in this chapter (exclude user who don't have visited this chapter)
					const successRate = user.successRatePerChapter[groupData[0].id]

					if (successRate != undefined) {
						results.sum += successRate
						results.count += 1
					}

					return results
				}, {
					sum: 0,
					count: 0
				})

				// Compute average success rate
				if (results.count > 0) {
					return (results.sum / results.count)
				}

				return 0
			},
			type: 'percentage',
			groupBy: {
				list: 'chapters',
				field: 'number'
			}
		}
	]
}