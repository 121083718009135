<template>
  <b-modal
    id="modal-1"
    hide-footer
    v-model="isImportModalOpen"
    @hide="handleHide"
    title="Importer des utilisateurs sans adresse mail"
  >
    <p
      v-if="info.message"
      :class="`text-${info.color}-600 text-sm mb-4 text-center`"
    >
      {{ info.message }}
    </p>
    <div class="flex items-center">
      <button
        @click="$refs.inputFile.click()"
        type="button"
        class="mr-2 twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
      >
        Choisir un fichier
      </button>
      <span v-if="selectedFile">{{ selectedFile.name }}</span>
    </div>
    <p class="text-xs my-4">
      Rappel : le fichier doit contenir dans l'ordre les colonnes
      id_acquisition, nom, prenom, code_postal.
    </p>
    <input
      type="file"
      ref="inputFile"
      hidden
      class="input-file"
      @click="$event.target.value = null"
      @change="fileSelected"
    />
    <p class="text-center" v-if="loading">Chargement...</p>
    <ul v-if="fileErrors.length" class="mb-4">
      <li v-for="err in fileErrors" :key="err" class="text-red-600">
        - {{ err }}
      </li>
    </ul>

    <!-- Data preview -->
    <p v-if="dataPreview && dataPreview.length > 0" class="font-bold text-sm">
      Aperçu
    </p>
    <div class="import-table" v-if="dataPreview && dataPreview.length > 0">
      <table class="text-xs" v-if="dataPreview && dataPreview.length > 0">
        <thead>
          <tr>
            <th>ID d'acquisition</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Code postal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in dataPreview" :key="index">
            <td>
              {{ user["id_acquisition"] || "-" }}
            </td>
            <td>
              {{ user["nom"] || "-" }}
            </td>
            <td>
              {{ user["prenom"] || "-" }}
            </td>
            <td>
              {{ user["code_postal"] || "-" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button
      @click="importList"
      :disabled="!isFileValid"
      type="button"
      v-if="!imported"
      class="mx-auto twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
    >
      Importer la liste
    </button>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import * as XLSX from "xlsx";
import axios from "axios";
export default {
  name: "UserImportModal",
  data: () => ({
    isImportModalOpen: true,
    fileError: "",
    selectedFile: null,
    allowedExt: ["csv", "xls"],
    fileErrors: [],
    isFileValid: false,
    dataPreview: [],
    loading: false,
    imported: false,
    info: {},
  }),
  methods: {
    ...mapActions("Auth", [
      "inscription",
      "checkRegisteredEmails",
      "importUsersMailless",
    ]),
    async fileSelected(e) {
      this.selectedFile = e.target.files[0];
      this.fileError = "";
      this.fileErrors = [];
      this.dataPreview = [];
      this.loading = true;
      const ext = this.selectedFile.name.split(".").pop();
      if (!this.allowedExt.includes(ext)) {
        this.$bvToast.toast(
          "Veuillez choisir un fichier au format XLS, XLXS ou CSV",
          {
            variant: "warning",
            toaster: "b-toaster-top-center",
            solid: true,
            noCloseButton: true,
          }
        );
        return;
      }
      var reader = new FileReader();
      reader.onload = async (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array", codepage: 1147 });

        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];

        let jsonData = XLSX.utils.sheet_to_json(worksheet);
        let errors = this.checkFileDatas(worksheet, jsonData);
        if (errors.length) {
          this.fileErrors = errors;
          this.loading = false;
        } else {
          // Generate preview
          if (jsonData.length > 0) {
            this.dataPreview = jsonData;
          }

          this.jsonData = jsonData;
          this.isFileValid = true;
          this.loading = false;
        }
      };
      reader.readAsArrayBuffer(this.selectedFile);
    },
    resetFile() {
      this.selectedFile = null;
      this.fileError = "";
      this.fileErrors = [];
      this.$refs.inputFile.value = "";
      this.isFileValid = false;
    },
    checkFileDatas(sheet, arr) {
      let errors = [];
      // Check file headers name
      const validHeaders = ["id_acquisition", "nom", "prenom", "code_postal"];
      for (let i = 0; i < 20; i++) {
        const cellRef = XLSX.utils.encode_cell({ c: i, r: 0 });
        const col = sheet[cellRef]?.v;
        if (col && validHeaders.indexOf(col) < 0) {
          errors.push("Colonne " + i + ' inconnue : "' + col + '"');
        }
      }
      return errors;
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async importList() {
      let usersData = [];
      for (const { id_acquisition, nom, prenom, code_postal } of this
        .jsonData) {
        let userData = {
          acquisition_id: id_acquisition,
          lastname: nom,
          firstname: prenom,
          zipcode: code_postal.toString(),
        };
        usersData.push(userData);
      }
      if (usersData.length) {
        try {
          const count = await this.importUsersMailless({
            data: usersData,
            name: this.selectedFile.name,
          });

          this.imported = true;
          this.$nextTick(() => {
            this.$emit("complete", count);
            this.handleHide();
          });
        } catch (e) {
          this.$bvToast.toast(e, {
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
            noCloseButton: true,
          });
        }
      }
    },
    handleHide() {
      this.resetFile();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
#modal-1 {
  .modal-dialog {
    max-width: 600px;
  }

  table {
    margin: 0.5rem auto 1.5rem auto;

    td,
    th {
      padding-right: 1rem;
    }
  }
}
</style>
