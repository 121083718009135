<template>
    <section class="library">
      <button @click="isSelectMediaModalOpen = true" type="button" class="mb-4 twn-button">Ajouter un document</button>
        <div v-show='docsList.length' ref='list' class="document-list">
            <b-card
            v-for='(item) in docsList' :key='item.id'
            :data-id='item.id'
    :title="item.title"
    tag="article"
    style="max-width: 20rem;"
    class="mb-2"
  >
  <hr class="mb-4">
    <p v-if='item.chapter'><b>Chapitre lié : </b>{{retrieveChapter(item.chapter)}}</p>
    <p v-else>Ce document n'est lié à aucun chapitre</p>
<br>
          <button type="button" @click='handleEdit(item)' class="mb-4 twn-button">Modifier ce document</button>
  </b-card>
        </div>
        <div v-if='docsList.length ===0'>
            <p>Vous n'avez ajouté aucun documents à la bibliothèque des utilisateurs</p>
        </div>
         <b-modal
			ref="select-modal"
			centered
			id="select-modal"
			hide-footer
			title="Choissez un document"
      v-model="isSelectMediaModalOpen"
    @hide="isSelectMediaModalOpen = false"

      size="lg"
		>
			<MediaSearchList type="file_pdf" selectOnly @select-media="handleSelect"></MediaSearchList>
		</b-modal>
        <ModalDocument v-if='selectedFile' @hide='selectedFile=null' :isEdit="false" @send='send' :file='selectedFile'/>
        <ModalDocument v-if='selectedDoc' :isEdit="true" @send='send' @delete="handleDelete" @hide='handleHideEdit' :file='selectedDoc'/>
    </section>
</template>
<script>
import MediaSearchList from '@/components/MediaSearchList'
// import Toggle from '../components/Toggle.vue';
import { mapGetters, mapActions } from 'vuex'
import ModalDocument from '@/components/Library_Modal'

import Sortable from 'sortablejs'
export default {
     components: {
    MediaSearchList,
    // Toggle,
    ModalDocument
    },

        data() {
        return {
            fields:[
                { key: 'order', label: 'Ordre' },
                { key: 'title', label: 'Titre' },
                { key: 'chapter', label: 'Chapitre lié' },
                { key: 'actions', label: 'Actions' }
                ],
            isSelectMediaModalOpen:false,
            selectedFile:null,
            selectedDoc:null,
            isNewDocModalOpen:false,
            isEditModalOpen:false,
            documents:[],
            
        }
    },
    computed:{
        ...mapGetters('Library', ['docsList']),
        ...mapGetters('Chapter', ['chapters'])
    },
   async mounted() {
        await this.getChapterList();
      await this.getDocumentsList();
      this.documents = this.docsList;
      const self = this;
       new Sortable(document.querySelector('.document-list'), {
    animation: 150,
    ghostClass: 'blue-background-class',
    onEnd: async function(evt) {
		self.handleReorder()
    await self.getDocumentsList()
    console.log('ok')
	}   
       })
       },
    methods:{
    ...mapActions('Library', ['saveDocument', 'getDocumentsList', 'deleteDocument']),
    ...mapActions('Chapter', ['saveChapter', 'getChapterList']),
    async handleReorder() {
        const list = this.$refs['list'].children;
        list.forEach((element,i) => {
            console.log(i)
            const id = element.dataset.id;
            console.log(id)
            console.log(this.docsList)
            let item = this.docsList.find(item => item.id === parseInt(id))
            item.order = i;
            this.saveDocument(item)
        });

    },
        handleSelect(e) {

            this.$bvModal.hide('select-modal');
            this.selectedFile = e;
            this.isNewDocModalOpen=true;
            
        },
   async handleDelete(id) {
       console.log(id)
       let ok = await this.deleteDocument(id)
         await this.getDocumentsList();

   },
   handleHideEdit(e) {
       if(e !== 'conf') {
           this.selectedDoc=null
       }
   },
    async send(doc) {
        await this.saveDocument(doc)
        this.isNewDocModalOpen=false;
        this.isEditModalOpen=false;
        this.documents = await this.getDocumentsList();
    },
    retrieveChapter(id) {
        const response = this.chapters.find(item => item.id === id)
        if(response) {
        return this.chapters.find(item => item.id === id).title
            }
            else {
                return 'Aucun chapitre lié'
            }
    },
    handleEdit(item) {
        this.selectedDoc = item;
        this.isEditModalOpen = true;
        console.log('ok', this.isEditModalOpen)
        console.log('ok', this.selectedDoc)
    },
    },
}
</script>
<style lang="scss">
.document-list {
    display:flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    // align-items: flex-start;
    .table_library {
        user-select: none;
    }
    .card {
        cursor:grab;
        &:active {
            cursor: grabbing;
        }
        width:300px;
        h4 {
            font-size: 20px;
            font-weight: bold;
        }
        p {
            margin-bottom: 50px;
        }
        button {
            position: absolute;
            bottom: 0px;
            right: 20px;
            cursor:pointer;
        }
        margin:10px
    }
}
</style>