import { GC_ADD_CHAPTER, GC_UPDATE_CHAPTER, GC_CHAPTER_LIST } from '@/graphql/chapter'
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {
      list:[]
    },
    getters:{
      chapters(state) {
        return state.list
      }
    },
    mutations: {
      SET_LIST(state, data) {
        state.list = data
      }
    },
    actions: {
      async saveChapter(ctx, chapter) {
        if(chapter.id) {
          const { id } = chapter
          delete chapter.id
          let response = await apollo.mutate({
            mutation: GC_UPDATE_CHAPTER,
            variables: {
              chapter,
              chapter_id: id
            }
          })
        } else {
          let response = await apollo.mutate({
            mutation: GC_ADD_CHAPTER,
            variables: {
              chapter
            }
          })

        }
      },
      async getChapterList({commit}) {
        return new Promise((resolve, reject) => {
          apollo.query({
            query: GC_CHAPTER_LIST
          }).then(response => {
            response.data.chapter.map(chapter => {
              if(chapter.planche_1) chapter.planche_1.url = process.env.VUE_APP_AWS_S3_URL + '/' + chapter.planche_1.path
              if(chapter.planche_2) chapter.planche_2.url = process.env.VUE_APP_AWS_S3_URL + '/' + chapter.planche_2.path
            }),
            commit('SET_LIST', response.data.chapter)
            resolve()
          })
          .catch(e => reject(e))
        })
      }
    }
}