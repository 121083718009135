import Vue from "vue";
import VueRouter from "vue-router";
import Authentication from "@/views/Authentication";
import Dashboard from "@/views/Dashboard";
import UserList from "@/views/UserList";
import UserInscription from "@/views/UserInscription";
import UserInscriptionOld from "@/views/UsersInscriptionOld";
import UserInscriptionDetail from "@/views/UserInscriptionDetail";
import OrganizationList from "@/views/OrganizationList";
import NotFound from "@/views/NotFound";
import UserDetail from "@/views/UserDetail";
import OrganizationDetail from "@/views/OrganizationDetail";
import ScenarioList from "@/views/ScenarioList";
import ScenarioDetail from "@/views/ScenarioDetail";
import MediaList from "@/views/Medias";
import Planche from "@/views/Planche";
import Chapitres from "@/views/Chapitres";
import Library from "@/views/Library";
import GamesList from "@/views/MinigamesList";
import GameCreation from "@/views/GameCreation";
import PromotionList from "@/views/Promotions/PromotionList";
import PromotionDetail from "@/views/Promotions/PromotionDetail";
import PathList from "@/views/Paths/PathList";
import PathDetails from "@/views/Paths/PathDetails";
import CourseDetail from "@/views/Courses/CourseDetail";
import CourseList from "@/views/Courses/CourseList";
import EntityList from "@/views/EntityList";
import EntityDetail from "@/views/EntityDetail";

import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Tableau de bord",
      breadcrumb: "Tableau de bord",
    },
  },
  {
    path: "/auth",
    name: "auth",
    component: Authentication,
    meta: {
      title: "Authentication",
      layout: "no-sidebar",
      isAuth: true,
    },
  },
  {
    path: "/user/list",
    name: "users-list",
    props: true,
    component: UserList,
    meta: {
      breadcrumb: "Utilisateurs",
      title: "Liste des utilisateurs",
    },
  },
  {
    path: "/user/inscription",
    name: "users-inscription",
    props: true,
    component: UserInscription,
    meta: {
      breadcrumb: "Inscriptions",
      title: "Liste des inscriptions",
    },
  },
  {
    path: "/user/inscription/old",
    name: "users-inscription-old",
    props: true,
    component: UserInscriptionOld,
    meta: {
      breadcrumb: "Inscriptions (Ancienne version)",
      title: "Liste des inscriptions (Ancienne version)",
    },
  },
  {
    path: "/user/inscription/edit",
    name: "users-inscription-edit",
    props: true,
    component: UserInscriptionDetail,
    meta: {
      breadcrumb: "Détail de l'utilisateur",
      title: "Détail de l'utilisateur",
    },
  },
  {
    path: "/user/edit",
    name: "users-edit",
    component: UserDetail,
    props: true,
    meta: {
      breadcrumb: "Utilisateurs",
      title: "Editer un utilisateur",
    },
  },
  {
    path: "/user/create",
    name: "users-create",
    component: UserDetail,
    props: true,
    meta: {
      breadcrumb: "Utilisateurs",
      title: "Ajouter un utilisateur",
    },
  },
  {
    path: "/promotion/list",
    name: "promotions-list",
    component: PromotionList,
    meta: {
      breadcrumb: "Promotions",
      title: "Liste des promotions",
    },
  },
  {
    path: "/promotion/edit",
    name: "promotions-edit",
    component: PromotionDetail,
    props: true,
    meta: {
      breadcrumb: "Promotions",
      title: "Éditer une promotion",
    },
  },
  {
    path: "/promotion/create",
    name: "promotions-create",
    component: PromotionDetail,
    props: true,
    meta: {
      breadcrumb: "Ajouter une promotion",
      title: "Ajouter une promotion",
    },
  },
  {
    path: "/course/create",
    name: "course-create",
    component: CourseDetail,
    props: true,
    meta: {
      breadcrumb: "Ajouter un parcours",
      title: "Ajouter un parcours",
    },
  },
  {
    path: "/course/edit/:itemID?",
    name: "course-edit",
    component: CourseDetail,
    props: true,
    meta: {
      breadcrumb: "Editer un parcours",
      title: "Editer un parcours",
    },
  },
  {
    path: "/course/list",
    name: "course-list",
    component: CourseList,
    props: true,
    meta: {
      breadcrumb: "Liste des parcours",
      title: "Liste des parcours",
    },
  },
  {
    path: "/path/list",
    name: "paths-list",
    component: PathList,
    meta: {
      breadcrumb: "Parcours",
      title: "Liste des parcours",
    },
  },
  {
    path: "/path/edit",
    name: "paths-edit",
    component: PathDetails,
    props: true,
    meta: {
      breadcrumb: "Parcours",
      title: "Éditer un parcours",
    },
  },
  {
    path: "/path/create",
    name: "paths-create",
    component: PathDetails,
    props: true,
    meta: {
      breadcrumb: "Parcours",
      title: "Créer un parcours",
    },
  },
  {
    path: "/organisation/list/",
    name: "organisations-list",
    props: true,
    component: OrganizationList,
    meta: {
      breadcrumb: "Gestion des offices",
      title: "Liste des offices",
    },
  },
  {
    path: "/organisation/edit",
    name: "organisations-edit",
    component: OrganizationDetail,
    props: true,
    meta: {
      breadcrumb: "Offices",
      title: "Editer un office",
    },
  },
  {
    path: "/organisation/create",
    name: "organisations-create",
    component: OrganizationDetail,
    props: true,
    meta: {
      breadcrumb: "Offices",
      title: "Ajouter un office",
    },
  },
  {
    path: "/scenario/list",
    name: "scenario-list",
    props: true,
    component: ScenarioList,
    meta: {
      breadcrumb: "Scénarios",
      title: "Liste des scénarios",
    },
  },
  {
    path: "/scenario/edit/:itemID?",
    name: "scenario-edit",
    component: ScenarioDetail,
    props: true,
    meta: {
      title: "Editer un scénario",
      noMargin: true,
    },
  },
  {
    path: "/media/list",
    name: "media-list",
    component: MediaList,
    meta: {
      breadcrumb: "Medias",
      title: "Liste des assets",
    },
  },
  {
    path: "/media/library",
    name: "media-lib",
    component: Library,
    meta: {
      breadcrumb: "Bibliothèque de documents",
      title: "Bibliothèque de documents",
    },
  },
  {
    path: "/media/planche",
    name: "media-planche",
    component: Planche,
    meta: {
      breadcrumb: "Planche",
      title: "Zonage des planches",
    },
  },
  {
    path: "/media/chapitre",
    name: "media-chapitres",
    component: Chapitres,
    meta: {
      breadcrumb: "Chapitres",
      title: "Gestion des chapitres",
    },
  },
  {
    path: "/games/list",
    name: "games-list",
    props: true,
    component: GamesList,
    meta: {
      breadcrumb: "Minijeux",
      title: "Liste des minijeux",
    },
  },
  {
    path: "/game/edit/:gameTypeSlug/:itemID?",
    name: "game-edit",
    component: GameCreation,
    props: true,
    meta: {
      breadcrumb: "Minijeux",
      titleFn: (route, store) => {
        if (route && store) {
          let type = store.state.Games.type_list.find((type) => {
            return type.slug == route.params.gameTypeSlug;
          });
          if (type && !route.params.itemID) {
            return `Créer un ${type.name ? type.name : ""}`;
          } else if (type && route.params.itemID) {
            return `Modifier le ${type.name ? type.name : ""}`;
          }
        }
        return "Minijeu";
      },
    },
  },
  {
    path: "/entity/list",
    name: "entity-list",
    component: EntityList,
    meta: {
      breadcrumb: "Entities",
      title: "Liste des entités",
    },
  },
  {
    path: "/entity/edit/:itemID?",
    name: "entity-edit",
    component: EntityDetail,
    props: true,
    meta: {
      title: "Editer une entité",
    },
  },
  {
    path: "*",
    component: NotFound,
    name: "notfound",
    meta: {
      breadcrumb: "404",
      layout: "no-sidebar",
      title: "404 - Not Found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/backoffice/",
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (process.env.VUE_APP_ADMIN_SECRET) {
    return next();
  }
  if (
    to.path !== "/auth" &&
    to.path !== "/password-forgot" &&
    to.name !== "password-reset"
  ) {
    if (store.getters["Auth/isLoggedIn"]) {
      await store.dispatch("Auth/getCurrentUserInfo");
      return next();
    } else {
      return next("/auth");
    }
  } else {
    if (store.getters["Auth/isLoggedIn"]) {
      await store.dispatch("Auth/getCurrentUserInfo");
      return next("/");
    } else {
      return next();
    }
  }
});

export default router;
