<template>
    <div class="game-simple">
        <Draggable v-model="questions" handle=".handle" v-bind="dragOptions">
            <div class="" v-for="(question, i) in questions" :key="i">
                <QuestionSimple
                :question="question"
                :number="i"
                :options="options"
                class="my-5"
                @delete-question="deleteQuestion"
                @update-question="updateQuestion">
                
                </QuestionSimple>
            </div>
        </Draggable>
        <div @click="addQuestions" class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl ">+</div>

    </div>
</template>
<script>

import Draggable from 'vuedraggable'
import QuestionSimple from '@/components/Games/Question_simple'

export default {
    name: 'QuizSimple',
    components: {
        QuestionSimple,
        Draggable
    },
    props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
    },
    data: () => ({
        options:{
            fixedAnswers: true,
            noCorrection: true,
        },
    }),
    created () {
        if(!this.game.id) {
            this.addQuestions()
        }
    },
    computed: {
        questions:{
            get() {
                if (!this.game)
                    return []
                return JSON.parse(JSON.stringify(this.game.questions));
            },
            set(value){
                this.$emit('update-all-questions', value)
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    methods:{
        addQuestions(){
            let question = [{
                answers:[{
                    text: '',
                    is_correct: true,
                }],
                text: '',
                correction: '',
            }]
            this.$emit('add-questions', question)
        },
        updateQuestion(i, data){
            this.$emit('update-question', i, data)
        },
        deleteQuestion(i){
            this.$emit('delete-questions', [i])
        }
        
    }
}
</script>

<style lang="scss">

</style>