<template>
  <div class="c-promotion-detail">
    <div class="buttons flex flex-row justify-around md:justify-between">
      <button type="button" class="twn-button text-xs" @click="goBack">
        Retour
      </button>
      <button
        type="button"
        class="twn-button text-xs danger"
        @click="showDeleteModal(data.item.id)"
        v-if="!creating"
      >
        Supprimer la promotion
      </button>
    </div>

    <div class="twn-card my-10">
      <div class="flex">
        <div v-if="creating" class="uppercase font-principal-bold text-sm">
          Ajouter une promotion
        </div>
        <div v-else class="uppercase font-principal-bold text-sm">
          Éditer la promotion
        </div>
      </div>

      <div class="mt-8">
        <div class="flex">
          <div class="flex-1 mr-8">
            <label>Nom de la promotion</label>
            <b-form-input v-model="fields.name" type="text" />
          </div>
          <div class="flex-1">
            <label>Parcours associé</label>
            <v-select
              :options="courseList"
              :filterable="true"
              label="name"
              placeholder="Parcours"
              v-model="fields.course_id"
            />
          </div>
        </div>

        <div class="mt-8">
          <div class="mb-4">Dates de session</div>
          <div class="flex items-center">
            <div class="mr-4">Du</div>
            <b-form-datepicker
              class="w-64 mr-4"
              v-model="fields.start_date"
            ></b-form-datepicker>
            <div class="mr-4">Au</div>
            <b-form-datepicker
              class="w-64 mr-4"
              v-model="fields.end_date"
            ></b-form-datepicker>
          </div>
        </div>

        <PromotionInternsForm v-model="userComplete" :creating="creating" />
        <PromotionSequenceForm
          v-model="fields.sequences"
          :interns="userComplete"
          :creating="creating"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PromotionSequenceForm from "./PromotionSequenceForm";
import PromotionInternsForm from "./PromotionInternsForm";
import { GC_GET_COURSE_LIST } from '@/graphql/promotion'

export default {
  components: {
    PromotionSequenceForm,
    PromotionInternsForm,
  },

  props: ["itemID"],

  data: () => ({
    creating: false,
    fields: {
      name: null,
      path: null,
      start_date: null,
      end_date: null,
      sequences: [],
      users: [],
    },
    promotionSequences: [],
    userComplete: [],
    courseList: []
  }),

  computed: {
    ...mapGetters("EditItem", ["getCurrentID"]),
  },
  created() {
    if (this.getCurrentID === -1 || !this.itemID) {
      this.creating = true;
    }
    this.getCourseList()
  },
  methods: {
    goBack() {
      this.$router.push({ name: "promotions-list" });
    },
    async getCourseList(){
      const response = await this.$apollo.query({
        query: GC_GET_COURSE_LIST
      })
      this.courseList = response.data.course
    },
    showDeleteModal(id) {
      this.selectedID = id;
      this.$refs["delete-modal-detail"].show();
    },
  },
  watch:{
    userComplete(val){
      if (val){
        this.fields.users = val.map(data => {
          if (data.user){
            return {
              user_id: data.user.id,
            }
          }
        }).filter(e => e)
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.c-promotion-detail__sequences {
  gap: 2em 4em;
}
</style>