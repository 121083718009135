import apollo from '@/apolloClient'
import {
    CG_GET_ORGAS,
    CG_GET_STATUS,
    GC_GET_USERS,
    GC_GET_FEREDATIONS
} from '@/graphql/utils'


export default {
    namespaced: true,
    state:{
        organisationNameList:[],
        statusNameList:[],
        userNameList: [],
        federationList: [],
        logTypes: []
    },
    getters:{
        getOrgaNameList(state){
            return state.organisationNameList;
        },
        getStatusNameList(state){
            return state.statusNameList;
        },
        getUserNameList(state){
            return state.userNameList;
        }
    },
    mutations:{
        SET_ORGA_LIST(state, orgaList){
            state.organisationNameList = orgaList;
        },
        SET_STATUS_LIST(state, statusList){
            if (statusList && Array.isArray(statusList)){
                let filteredArr = statusList.filter(e => e.experienced !== null);
                state.statusNameList = filteredArr;
            }
        },
        SET_USER_LIST(state, userList){
            state.userNameList = userList;
        },
        SET_FEREDATIONS_LIST(state, fedList){
            state.federationList = fedList
        }
    },
    actions:{
        async initAll({state, dispatch}){
            if (state.organisationNameList.length == 0){
                await dispatch('getOrgas');
            }
            if (state.statusNameList.length == 0){
                await dispatch('getStatus');
            }
            if (state.userNameList.length == 0){
                await dispatch('getUsers');
            }
            if (state.federationList.length == 0){
                await dispatch('getFederations')
            }
        },
        async getOrgas({commit}){
            const response = await apollo.query({
                query: CG_GET_ORGAS
            })
            commit('SET_ORGA_LIST', response.data.organisations);
            return response;
        },
        async getStatus({commit}){
            const response = await apollo.query({
                query: CG_GET_STATUS
            })
            commit('SET_STATUS_LIST', response.data.users_aggregate.nodes);
            return response;
        },
        async getUsers({commit}){
            const response = await apollo.query({
                query: GC_GET_USERS
            })
            commit('SET_USER_LIST', response.data.users);
            return response;
        },
        async getFederations({commit}){
            const response = await apollo.query({
                query: GC_GET_FEREDATIONS
            })
            commit('SET_FEREDATIONS_LIST', response.data.federations);
            return response;
        },
    }
}