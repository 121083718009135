<template>
  <section class="planche-container">
    <section class="media-selection">
      <button type="button" class="mb-4 twn-button" @click="$bvModal.show('select-modal'); isCropping =false">Choisir un média</button>
    </section>
    <section class="media-preview flex" v-if="mediaSelected && !isCropping">
      <div class="img-container" style="position:relative">
        <img ref="img" v-if="mediaSelected" :src="mediaSelected.url" alt="">
        <div class="zone"  v-for="zone in selectedPlancheZones"   :key="zone.id" :style="'position:absolute;top:'+zone.position.top+'%;left:'+zone.position.left+'%;right:'+zone.position.right+'%;bottom:'+zone.position.bottom+'%'">
          <div class="zone-actions flex justify-items-center align-center">
            <b-icon-x class="delete-zone mb-0" @click="deleteZone(zone.id)"></b-icon-x>
            <b-icon-pencil @click="editPlancheZone(zone)"  class="update-zone m-auto block"></b-icon-pencil>
          </div>
        </div>
      </div>
      <div class="preview-action">
        <button type="button" class="mt-4 twn-button" @click="isCropping = true;">Créer une zone</button>
      </div>
    </section>
    <section class="media-cropper flex justify-between" v-else-if="mediaSelected && isCropping">
      <div class="cropper">
        <span v-if="!isCropReady">
                Chargement...
            </span>
        <vue-cropper
          ref="cropper"
          class="w-100"
          :src="mediaSelected.url"
          alt="Source Image"
          dragMode="move"
          @ready="setPlancheDimention"
        >
        </vue-cropper>
      </div>
      <div class="cropper-action flex flex-column p-4">
        <p class="mb-4">Informations de la zone</p>
        <v-select :loading="!isCropReady" @input="resetMedias" class="style-chooser text-sm mb-4" placeholder="Type de la zone" :options="zoneTypes" :reduce="(e) => e.slug" v-model="zone.zone_type"></v-select>
        <b-form-input class="w-50 mb-4" id="zone_order" placeholder="Numéro de la zone" type="number" v-model="zone.order"></b-form-input>
        <div v-if="zone.zone_type === 'game'" >
          <div v-for="(game, i) in zoneGames" :key="game.id" class="flex items-center justify-between mb-1">
            <button  :disabled="!isCropReady" type="button" class="twn-button text-sm w-25 mr-2" @click="isMiniGamesModalOpen = true;selectedGameIndex = i">Choisir le mini jeu</button>
            <p class="" v-if="game">{{game.name}}</p>
            <div class="game-actions flex">
              <div
                @click="addGame"
                class="plus rounded-full w-5 h-5 flex justify-center items-center cursor-pointer mr-2"
              >
                +
              </div>
              <div
                  class="w-5 h-5 cursor-pointer"
                  @click="deleteGame(i)"
                >
                  <img src="@/assets/images/NODE_CROSS.svg" class="w-100" />
                </div>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <button v-if="zone.zone_type === 'video_expert' || zone.zone_type === 'video_comedien' || zone.zone_type === 'image'" :disabled="!isCropReady" type="button" class="twn-button text-sm w-25 mr-2" @click="$bvModal.show('select-modal')" >Choisir une {{zoneTypes.find(type => type.slug === zone.zone_type).label}}</button>
          <p class="" v-if="zoneVideoExpert">{{zoneVideoExpert.name}}</p>
          <p class="" v-if="zoneVideoComedien">{{zoneVideoComedien.name}}</p>
          <p class="" v-if="zoneImage">{{zoneImage.name}}</p>
        </div>
        <button :disabled="!isCropReady" type="button" class="mt-4 twn-button " @click="validateZone">Valider la zone</button>
      </div>
      
    </section>
    <b-modal
			ref="select-modal"
			centered
			id="select-modal"
			hide-footer
			title="Choissez une planche"
      v-model="isSelectMediaModalOpen"
      size="lg"
		>
			<MediaSearchList selectOnly @select-media="selectMedia"></MediaSearchList>
		</b-modal>
    <b-modal
			ref="mini-game-modal"
			centered
			id="mini-game-modal"
			hide-footer
			title="Choissez un mini jeu"
      v-model="isMiniGamesModalOpen"
      size="lg"
		>
			<List
            :listing="itemListing"
            :fields="itemListFields"
            :filters="itemListFilters"
            :searchText="itemSearchText"
            :mobileFields="itemListFields"
            :read-only="true"
            @select-item="gameSelected">
            </List>
		</b-modal>
  </section>
</template>

<script>
import MediaSearchList from '@/components/MediaSearchList'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

import {mapGetters, mapActions} from 'vuex'
import List from '@/components/List'

export default {
  name: "Planche",
  components: {
    MediaSearchList,
    VueCropper,
    List
  },
  data: ()=>({
    isSelectMediaModalOpen: false,
    isMiniGamesModalOpen: false,
    mediaSelected: null,
    selectedPlancheZones: [],
    mediaSelectedSize: {
      width: null,
      height: null,
    },
    isCropReady: false,
    isCropping: false,
    zone: {
      position: null
    },
    zoneGames: [{}],
    zoneVideoExpert: {},
    zoneVideoComedien: {},
    zoneImage: {},
    selectedGameIndex: null,
    testZone: {},
    zoneTypes: [{label: 'Vidéo expert', slug: 'video_expert'},{label: 'Video comédien', slug: 'video_comedien'}, {label: 'Jeu', slug: 'game'}, {label: 'Image', slug: 'image'}, {label: 'Score', slug: 'score'}],
    isLoading: false,
    itemListing: 'game',
    itemListFields: [
      {
          key:'name',
          label: 'Nom',
          sortable: true
      },
      {
          key: 'type',
          db: `type:gameType {
              name
          }`,
          nested: 'name',
          label: 'Type',
          sortable: true,
      }
  ],
  itemListFilters: [
      {
          key:'type',
          label: 'Type'
      }
  ],
  itemSearchText: 'Rechercher un mini-jeu',
  editZone: null,
  pencilColor: "rgba(0,0,0,0)"
  }),
  methods: {
    ...mapActions('Planche', ["save", "getPlancheActionZones", "deletePlancheZone"]),
    ...mapActions('Assets', ["getAssetById"]),
    async selectMedia(media) {
      if(this.isCropping) {
        switch (this.zone.zone_type) {
          case 'video_expert':
            this.zoneVideoExpert = media
            break;
          case 'video_comedien':
            this.zoneVideoComedien = media
            break;
          case 'image':
            this.zoneImage = media
            break;
          default:
            break;
        }
      } else {
        this.mediaSelected = media
        this.selectedPlancheZones = await this.getPlancheActionZones(media.id)

      }
      
      this.$bvModal.hide('select-modal')
    },
    setPlancheDimention(event){
      this.mediaSelectedSize.width = event.target.width
      this.mediaSelectedSize.height = event.target.height
      if(this.editZone) {
        const {top, bottom, right, left} = this.editZone.position
        this.isCropping = true;
        const x = left * this.mediaSelectedSize.width / 100
        const y = top * this.mediaSelectedSize.height / 100
        const width = this.mediaSelectedSize.width - right * this.mediaSelectedSize.width / 100 -  x
        const height = this.mediaSelectedSize.height - bottom * this.mediaSelectedSize.height / 100 - y
        this.$refs.cropper.setData({x, y, width , height })
      }
      this.isCropReady = true
    },
    async validateZone(){
      const {x, y, width, height} = this.$refs.cropper.getData()      
      let top = y.toFixed(2) * 100 / this.mediaSelectedSize.height 
      let left = x.toFixed(2) * 100/ this.mediaSelectedSize.width 
      let right = (this.mediaSelectedSize.width -( x + width).toFixed(2)) * 100 / this.mediaSelectedSize.width 
      let bottom = (this.mediaSelectedSize.height - (y + height).toFixed(2)) * 100 / this.mediaSelectedSize.height 
      let planche_zone = {
        position: { top, left, bottom, right },
        planche_id: this.mediaSelected.id,
        zone_type: this.zone.zone_type,
        order: this.zone.order
      }
      switch (planche_zone.zone_type) {
        case 'video_expert':
          if(!this.zoneVideoExpert.id) return this.$bvToast.toast('Veuillez selectionner une vidéo expert', { title: `Erreur` });
          else planche_zone.planche_zone_media = {data: { video_expert_id: this.zoneVideoExpert.id}} 
          break;
        case 'video_comedien':
          if(!this.zoneVideoComedien.id) return this.$bvToast.toast('Veuillez selectionner une vidéo comédien', { title: `Erreur` });
          else planche_zone.planche_zone_media = {data: { video_comedien_id: this.zoneVideoComedien.id}} 
          break;
        case 'image':
          if(!this.zoneImage.id) return this.$bvToast.toast('Veuillez selectionner une image', { title: `Erreur` });
          else planche_zone.planche_zone_media = {data: { image_id: this.zoneImage.id}} 
          break;
        case 'game':
          var zoneGamesData = []
          this.zoneGames.forEach((game,i) => {
            if(game.id) zoneGamesData.push({game_id: game.id})
          })
          console.log(zoneGamesData)
          if(!zoneGamesData.length) return this.$bvToast.toast('Veuillez selectionner au moin un mini jeu', { title: `Erreur` });
          planche_zone.planche_zone_media = {data: zoneGamesData} 
          break;
      
        default:
          break;
      }
      this.zone.position = { top, left, bottom, right }
      this.zone.planche_id = this.mediaSelected.id
      if(this.editZone) await this.deletePlancheZone(this.editZone.id)
      await this.save({planche_zone})
      this.selectedPlancheZones = await this.getPlancheActionZones(this.mediaSelected.id)
      this.zone = { position: null }
      this.zoneVideoComedien = {}
      this.zoneImage = {}
      this.zoneVideoExpert = {}
      this.zoneGames = [{}]
      this.editZone = null
      this.isCropping = false
    },
    async deleteZone(zone_id) {
      await this.deletePlancheZone(zone_id)
      this.selectedPlancheZones = await this.getPlancheActionZones(this.mediaSelected.id)
    },
    gameSelected(game){
      this.zoneGames[this.selectedGameIndex] = game
      this.isMiniGamesModalOpen = false
      this.zoneGames.sort((a, b) => a.name.localeCompare(b.name))

    },
    addGame(){
      this.zoneGames.push({})
    },
    deleteGame(index){
      this.zoneGames.splice(index, 1)
      if(!this.zoneGames.length) this.addGame()
    },
    resetMedias() {
      this.zoneVideoComedien = {}
      this.zoneVideoExpert = {}
      this.zoneGames = [{}]
    },
    editPlancheZone(zone) {
      this.editZone = zone
      this.zone.zone_type = zone.zone_type
      this.zone.order = zone.order
      this.zoneGames = []
      
        this.isCropReady = false
      zone.planche_zone_media.forEach(zone_media => {
        if(zone_media.game) this.zoneGames.push(zone_media.game)
        if(zone_media.video_comedien) this.zoneVideoComedien = zone_media.video_comedien
        if(zone_media.video_expert) this.zoneVideoExpert = zone_media.video_expert
        if(zone_media.image) this.zoneImage = zone_media.image
      })
      this.isCropping = true;
    }
  },
}
</script>

<style lang="scss">
.img-container {
  width: 40%;
  img {
    width: 100%;
  }
}
.zone {
  transition: background-color 0.5s ease;
  background-color:rgba(0,0,0,0.2);
  border: 2px solid red;
  .zone-actions{
    position: relative;
    width: 100%;
    height: 100%;
    .update-zone, .delete-zone {
      opacity: 1;
      cursor: pointer;
      color: transparent;
      width: 40px;
      height: 40px;
      transition: all 0.5s ease;
      
        &:hover {
          color: red;
        }
    }

    .delete-zone {
      position: absolute;
      top: 0;
      right: 0;
    }

  }
  &:hover {
    background-color: rgba(0,0,0,0.8);
      color: white;
    .update-zone, .delete-zone{
      opacity: 1;
      color: white;
    }

  }
}
.media-selection{
  width: 100%;
  max-height: 200px;
  overflow: auto;
}
.modal-body {
  max-height: 80vh;
  overflow: auto;
}

.cropper, .cropper-action{
  width: 60%;
}
</style>