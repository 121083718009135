import apollo from '@/apolloClient'
import { GC_ADD_PLANCHE_ZONE, GC_GET_PLANCHE_ZONES, GC_UPDATE_PLANCHE_ZONE_BY_ID, GC_DELETE_PLANCHE_ZONE } from '@/graphql/planche'


export default {
    namespaced: true,
    state:{    },
    getters:{
    },
    mutations:{},
    actions:{
        async save(ctx, { id, planche_zone }){
          if (id) {
            // Update remote data
            const response = await apollo.mutate({
                mutation: GC_UPDATE_PLANCHE_ZONE_BY_ID,
                variables: {
                    id,
                    planche_zone
                }
            })

            console.log('update resssssssss', response);
          } else {
              // Add remote data
              const response = await apollo.mutate({
                  mutation: GC_ADD_PLANCHE_ZONE,
                  variables: {
                    planche_zone
                  }
              })

              console.log('insert resssssssss', response);
          }
        },
        async getPlancheActionZones({commit}, planche_id ){
            console.log(planche_id)
            const response = await apollo.query({
                query: GC_GET_PLANCHE_ZONES,
                variables: { planche_id }
            })
            return response.data.planche_zone;
        },
        async deletePlancheZone({commit}, zone_id ){
            const response = await apollo.query({
                query: GC_DELETE_PLANCHE_ZONE,
                variables: { zone_id }
            })
            return response.data;
        },
    }
}