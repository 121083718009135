import Rete from "rete";
import VueSelectControl from "./SelectControl.vue";

export class SelectControl extends Rete.Control {
  constructor(key, label, data_source, multiple = false) {
    super(key);
    this.component = VueSelectControl;
    this.props = { ikey: key, label, data_source, multiple };
  }
}
