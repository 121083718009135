import router from '@/router/index'
import { GC_GET_USER_INSCR_BY_ID } from '@/graphql/auth'
import { GC_GET_USER_N_ORGAS, GC_EDIT_USER,GC_EDIT_USER_INSCR, GC_ADD_USER_ONE, GC_ADD_USER_INSCRIPTION_ONE } from '@/graphql/edit'
import { GC_GET_ORGA_ONE, GC_EDIT_ORGA_ONE, GC_ADD_ORGA_ONE } from '../graphql/edit';
import apollo from '@/apolloClient'
import axios from 'axios'
import {
    GC_AUTH_TOKEN
} from '@/constants/jwt'
export default {
    namespaced: true,
    state: {
        currentID: -1,
        user: {
            id: '',
            firstName: '',
            name: '',
            email: '',
            organizations: [],
            status: ''
        },
        user_inscr:{},
        organization: {
            id: '',
            address: '',
            address2: '',
            post_code: 0,
            city: '',
            name: '',
            country: ''
        },
        organizationList: [],
        countryList: [],
        statusList: [],
        userList: [],
        federationList: []
    },
    getters:{
        getUser(state){
            return state.user;
        },
        getUserInscr(state){
            return state.user_inscr;
        },
        getOrganization(state){
            return state.organization;
        },
        getOrganizationList(state){
            return state.organizationList;
        },
        getStatusList(state){
            return state.statusList;
        },
        getCountryList(state){
            return state.countryList;
        },
        getCurrentID(state){
            return state.currentID;
        },
        getUserList(state){
            return state.userList;
        }
    },
    mutations: {
        SET_USER(state, user){
        console.log(user)
            let orgaArr = user.userOrganisations.map(orga => orga.organisations.id);
            state.user = {
                id: user.id,
                firstName: user.first_name,
                name: user.name,
                email: user.email,
                organizations: orgaArr,
                seniority: user.seniority,
                status: user.status,
                company: user.company,
                experienced: user.experienced,
                role: user.role,
                onv_status: user.onv_status,
                structure: user.structure,
                adress: user.adress,
                city: user.city,
                cedex: user.cedex,
                nb_lots: user.nb_lots,
                residence_id: user.residence_id,
                phone: user.phone,
            };
        },
        SET_USER_INSCR(state, user){
                state.user_inscr = user;
            },
        SET_ORGA(state, orga){
            state.organization = orga;
        },
        SET_ORGA_LIST(state, orgaList){
            state.organizationList = orgaList;
        },
        SET_STATUS_LIST(state, statusList){
            if (statusList && Array.isArray(statusList)){
                let filteredArr = statusList.filter(e => e.experienced !== null);
                state.statusList = filteredArr;
            }
        },
        SET_USER_LIST(state, userList){
            state.userList = userList;
        },
        SET_FEDERATIONS_LIST(state, fedList){
            state.federationList = fedList
        }
    },
    actions: {
        async initUserEditLists({commit, rootState}){
            commit('SET_ORGA_LIST', rootState['Utils'].organisationNameList);
            commit('SET_STATUS_LIST', rootState['Utils'].statusNameList);
        },
        async initOrgaEditLists({commit, rootState}){
            commit('SET_USER_LIST', rootState['Utils'].userNameList);
            commit('SET_FEDERATIONS_LIST', rootState['Utils'].federationList);
        },
        async setUser({commit, state}, userID){
            let id = userID;
            if (!id){
                router.push('/404')
                return;
            }
            state.currentID = id;
            const response = await apollo.query({
                query: GC_GET_USER_N_ORGAS,
                variables: {
                    id: id
                }
            })
            commit('SET_USER', response.data.users[0]);
            commit('SET_ORGA_LIST', response.data.organisations_aggregate.nodes);
            commit('SET_STATUS_LIST', response.data.users_aggregate.nodes);
        },
        async setUserInscr({commit, state}, userID){
            let id = userID;
            if (!id){
                router.push('/user/inscription')
                return;
            }
            state.currentID = id;
            const response = await apollo.query({
                query: GC_GET_USER_INSCR_BY_ID,
                variables: {
                    id: id
                }
            })
            console.log(response)
            commit('SET_USER_INSCR', response.data.user_inscription[0]);
        },
        async addUser({commit, dispatch, rootGetters}, userInfo){
        console.log('userInfo:', userInfo)
            let response;
            try {
                // Add user to auth api
                response = await axios.post(
                    process.env.VUE_APP_API + 'auth/adminregister',
                    {
                        email: userInfo.email,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem(GC_AUTH_TOKEN)
                        }
                    }
                    )
                    console.log('response:', response)
            } catch (error) {
                return {
                    error
                }
            }
            // let orgaArr = userInfo.organizations.map(id => {
            //     return {
            //         organisation: id
            //     }
            // })
            try {
                
                let res = await apollo.mutate({
                    mutation: GC_ADD_USER_ONE,
                    variables: {
                        id: response.data.message,
                        email: userInfo.email,
                        fName: userInfo.firstName,
                        lName: userInfo.name,
                        role: userInfo.role
                    }
                })
                dispatch('setUser', res.data.insert_users_one.id);
            } catch (error) {
                console.log('error:', error)
                
            }
        },
        async editUser({dispatch}, userInfo){
            console.log('userInfo:', userInfo)
            let orgaArr = userInfo.organizations.map(id => {
                return {
                    user: userInfo.id,
                    organisation: id
                }
            })
            
            await apollo.mutate({
                mutation: GC_EDIT_USER,
                variables: {
                    email: userInfo.email,
                    fName: userInfo.firstName,
                    lName: userInfo.name,
                    status: userInfo.status,
                    onv_status: userInfo.onv_status,
                    id: userInfo.id,
                    role: userInfo.role,
                    adress: userInfo.adress,
                    cedex: userInfo.cedex,
                    city: userInfo.city,
                    phone: userInfo.phone,
                    structure: userInfo.structure,
                    residence_id: userInfo.residence_id,
                    nb_lots: userInfo.nb_lots ? userInfo.nb_lots : null,
                }
            })
            dispatch('setUser', userInfo.id);
        },
        async editUserInscr({dispatch}, userInfo){
            console.log('userInfo:', userInfo)
            await apollo.mutate({
                mutation: GC_EDIT_USER_INSCR,
                variables: {
                    email: userInfo.email,
                    firstname: userInfo.firstname,
                    lastname: userInfo.lastname,
                    structure: userInfo.structure,
                    id: userInfo.id,
                }
            })
            dispatch('setUserInscr', userInfo.id);
        },
        async setOrganization({commit, state}, organizationID){
            let id = organizationID;
            if (!id){
                router.push('/404')
                return;
            }
            state.currentID = id;
            const response = await apollo.query({
                query: GC_GET_ORGA_ONE,
                variables: {
                    id: id
                }
            })
            commit('SET_ORGA', response.data.organisations_by_pk);
        },
        async editOrganization({dispatch}, orgaInfo){
            delete orgaInfo.__typename;
            if (orgaInfo.userOrganisations){
                delete orgaInfo.userOrganisations
            }
            if (orgaInfo.admin !== undefined){
                delete orgaInfo.admin
            }
            if (orgaInfo.federation !== undefined){
                delete orgaInfo.federation
            }
            await apollo.mutate({
                mutation: GC_EDIT_ORGA_ONE,
                variables: {
                    id: orgaInfo.id,
                    orga: orgaInfo
                }
            })
            dispatch('setOrganization', orgaInfo.id);
        },
        async addOrganization({dispatch}, orgaInfo){
            delete orgaInfo.__typename;
            let response = await apollo.mutate({
                mutation: GC_ADD_ORGA_ONE,
                variables: orgaInfo
            })
            dispatch('setOrganization', response.data.insert_organisations_one.id);
        },
        clearCurrentID({state}){
            state.currentID = -1;
        }
    }
}