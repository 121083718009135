<template>
  <div class="user-detail mt-8 md:mt-0">
    <div class="buttons flex flex-row justify-around md:justify-between">
      <button type="button" class="twn-button text-xs" @click="goBack">
        Retour
      </button>
      <button
        type="button"
        class="twn-button text-xs danger"
        @click="createOrEdit"
      >
        Enregistrer le profil
      </button>
    </div>
    <div class="flex flex-col">
      <div class="twn-card user-card my-10">
        <div class="uppercase font-principal-bold text-sm" v-if="creating">
          Ajouter un profil
        </div>
        <div class="uppercase font-principal-bold text-sm" v-else>
          éditer le profil
        </div>
        <div class="stars flex flex-row justify-end">
          <svg
            v-for="n in star.number"
            class="star"
            :key="n"
            v-bind:class="n <= star.colored ? 'complete' : ''"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 67.18 64.59"
          >
            <defs></defs>
            <title>Fichier 302</title>
            <g id="Calque_2" data-name="Calque 2">
              <g id="Calque_1-2" data-name="Calque 1">
                <path
                  class="cls-1"
                  d="M64.8,32.09A7.92,7.92,0,0,0,65,21a10.5,10.5,0,0,0-4.57-2.41l-12-1.68A3.45,3.45,0,0,1,46,15.24L40.73,4.41h0A8,8,0,0,0,30.14.8a7.53,7.53,0,0,0-3.61,3.61l-5.3,10.83a3.45,3.45,0,0,1-2.4,1.69l-12,1.68A8,8,0,0,0,.05,27.52a7.16,7.16,0,0,0,2.41,4.57l8.66,8.43a3.4,3.4,0,0,1,.73,2.64l-1.93,12a7.93,7.93,0,0,0,6.5,9.15,9.2,9.2,0,0,0,5-.73l10.84-5.77a3.08,3.08,0,0,1,2.89,0L46,63.62a9.87,9.87,0,0,0,3.61,1,8,8,0,0,0,7.94-8V55.2l-2.41-12a2.84,2.84,0,0,1,1-2.64Zm-12,5.06a7.54,7.54,0,0,0-2.17,7l2.17,12a3.06,3.06,0,0,1-2.41,3.61,2.47,2.47,0,0,1-2.17-.48L37.36,53.51a7.69,7.69,0,0,0-7.22,0L19.07,59.29A3.08,3.08,0,0,1,15,58.09a2.68,2.68,0,0,1-.24-1.93l2.16-12a8.23,8.23,0,0,0-2.16-7L5.59,28.72a3,3,0,0,1,0-4.33,6,6,0,0,1,1.68-1l12-1.69a8.06,8.06,0,0,0,6-4.33L30.62,6.58a3.17,3.17,0,0,1,4.09-1.45,4,4,0,0,1,1.45,1.45l5.29,10.83a7.81,7.81,0,0,0,6,4.09l12,1.69a3.37,3.37,0,0,1,2.88,3.37,2.52,2.52,0,0,1-1.2,2.16Z"
                />
              </g>
            </g>
          </svg>
        </div>
        <hr />
        <form
          class="flex flex-row flex-wrap justify-around"
          @submit="createOrEdit"
        >
          <div class="left">
            <div class="input-group">
              <label for="name">Nom</label>
              <b-form-input
                v-model="fields.lastname"
                type="text"
                id="name"
                :state="nameValidation"
              ></b-form-input>
            </div>
            <div class="input-group">
              <label for="firstName">Prénom</label>
              <b-form-input
                v-model="fields.firstname"
                type="text"
                id="firstName"
              ></b-form-input>
            </div>
            <div class="input-group mt-0 md:mt-10">
              <label for="email">Adresse e-mail</label>
              <b-form-input
                disabled
                v-model="fields.email"
                type="email"
                id="email"
                :state="emailValidation"
              ></b-form-input>
            </div>
          </div>
          <div class="right">
            <div class="input-group">
              <label for="structure">Structure</label>
              <b-form-input
                v-model="fields.structure"
                type="text"
                id="structure"
              ></b-form-input>
            </div>
            <div class="input-group">
              <label for="structure">Code de confirmation</label>
              <b-form-input
                disabled
                v-model="fields.code"
                type="text"
                id="code"
              ></b-form-input>
            </div>
            <div class="button-holder mb-0">
              <button
                @click.prevent="sendAgain"
                class="twn-button text-sm mr-2"
              >
                Réinitialiser le mot de passe
              </button>
              <button type="submit" class="twn-button text-sm" v-if="creating">
                Ajouter le profil
              </button>
              <button type="submit" class="twn-button text-sm" v-else>
                Valider les modifications
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="ml-auto">
        <button
          type="button"
          class="twn-button text-xs"
          @click="showDeleteModal"
          v-if="!creating"
        >
          Supprimer le profil
        </button>
      </div>
    </div>
    <b-modal
      ref="delete-modal-detail-user"
      class="bootstrap"
      centered
      hide-footer
      id="delete-modal-detail-user"
      hide-header
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <h3>Confirmer la suppression</h3>
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button
          type="button"
          class="mt-4 twn-button"
          @click="$bvModal.hide('delete-modal-detail-user')"
        >
          Retour
        </button>
        <button
          type="button"
          class="mt-4 twn-button danger"
          @click="deleteItem"
        >
          Supprimer
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { FC_DELETE_ITEM } from "@/graphql/list";
import router from "@/router/index";
import axios from "axios";

export default {
  name: "UserDetail",
  props: {
    itemID: {
      type: String,
      required: false
    },
    previousPage: {
      type: Number,
      required: false
    }
  },
  created() {
    if (this.userInfo.role == "superadmin") {
      this.isAdmin = true;
    } else if (this.userInfo.role == "organisationadmin") {
      this.isAdmin = false;
    } else {
      this.visitor = true;
    }
  },
  async mounted() {
    await this.setUserInscr(this.itemID);
    // if (this.getCurrentID !== -1 || this.itemID){
    //     let id = this.itemID;
    //     if (this.getCurrentID !== -1 && !this.itemID){
    //         id = this.getCurrentID;
    //     }
    //     await this.setUser(id)
    this.mapFields();
    console.log(this.fields);
    console.log(this.getUserInscr);
    // } else {
    //     this.creating = true;
    //     await this.initUserEditLists();
    //     this.organizationList = this.getOrganizationList;
    //     // this.statusList = this.getStatusList;
    // }
  },
  data() {
    return {
      star: {
        number: 8,
        colored: 2
      },
      selectedID: "",
      organizationList: [],
      isAdmin: false,
      visitor: false,
      selectedRole: null,
      selectedStatus: null,
      statusList: [
        {
          slug: 1,
          name: "Locataire"
        },
        {
          slug: 2,
          name: "Copropriétaire"
        },
        {
          slug: 3,
          name: "Autre"
        }
      ],
      experienceList: [
        {
          slug: true,
          name: "Navigation libre"
        },
        {
          slug: false,
          name: "Navigation guidée"
        }
      ],
      seniorityList: [
        {
          slug: 0,
          name: "Administrateur nouvellement formé"
        },
        {
          slug: 1,
          name: "Administrateur expérimenté"
        }
      ],
      fields: {
        id: "",
        firstName: "",
        name: "",
        organizations: [],
        status: null,
        email: "",
        seniority: null,
        company: "",
        experienced: false,
        role: null,
        residence_id: null,
        nb_lots: null
      },
      delayer: true,
      creating: false
    };
  },
  computed: {
    ...mapState("Auth", ["userInfo"]),
    ...mapGetters("EditItem", [
      "getUserInscr",
      "getOrganizationList",
      "getStatusList",
      "getCurrentID"
    ]),
    nameValidation() {
      return (
        this.fields.lastname !== undefined &&
        this.fields.lastname !== null &&
        this.fields.lastname !== ""
      );
    },
    emailValidation() {
      return (
        this.fields.email !== undefined &&
        this.fields.email !== null &&
        this.fields.email !== ""
      );
    },
    allValid() {
      return this.nameValidation && this.emailValidation;
    },
    roleList() {
      if (this.isAdmin) {
        return [
          { slug: "user", name: "Utilisateur" },
          { slug: "organisationadmin", name: "OfficeAdmin" },
          { slug: "superadmin", name: "SuperAdmin" }
        ];
      }
      return [
        { slug: "user", name: "Utilisateur" },
        { slug: "organisationadmin", name: "OfficeAdmin" }
      ];
    }
  },
  methods: {
    ...mapActions("EditItem", [
      "setUserInscr",
      "editUserInscr",
      "initUserEditLists",
      "addUser"
    ]),
    goBack() {
      router.push({
        name: "users-inscription",
        params: {
          previousPage: this.previousPage
        }
      });
    },
    showDeleteModal() {
      this.$refs["delete-modal-detail-user"].show();
    },
    async deleteItem() {
      await this.$apollo.query({
        query: FC_DELETE_ITEM("user_inscription"),
        variables: {
          id: this.fields.id
        }
      });
      this.$bvToast.toast("L'utilisateur a bien été supprimé", {
        title: `Erreur`
      });
      this.$refs["delete-modal-detail-user"].hide();
      this.$router.go(-1);
    },
    mapFields() {
      this.fields = this.getUserInscr;
      this.organizationList = this.getOrganizationList;
      // this.statusList = this.getStatusList;
    },
    createOrEdit(e) {
      e.preventDefault();
      if (this.creating) {
        this.confirmCreate();
      } else {
        this.confirmEdit();
      }
    },
    confirmEdit() {
      if (this.allValid && this.delayer) {
        this.editUserInscr(this.fields);
        this.delayer = false;
        this.$bvToast.toast("L'utilisateur a bien été modifié", {
          title: `Confirmation`
        });

        setTimeout(this.resetDelayer, 500);
      }
    },
    confirmCreate() {
      if (this.allValid && this.delayer) {
        this.delayer = false;
        //create
        this.addUser(this.fields);
        this.creating = false;
        setTimeout(this.resetDelayer, 500);
      }
    },
    async sendAgain() {
      await axios.post(`${process.env.VUE_APP_API}auth/reset`, {
        username: this.fields.email
      });
      this.$bvToast.toast(
        "Mail de réinitialisation envoyé à : " + this.fields.email,
        { title: `Confirmation` }
      );
    },
    async sendEmail({ context, email, code }) {
      let emailobj = {
        context,
        user: email,
        variables: { code }
      };
      axios.post(process.env.VUE_APP_API + "send", JSON.stringify(emailobj));
    },
    resetDelayer() {
      this.delayer = true;
    }
  }
};
</script>
<style lang="scss">
.user-card {
  > div {
    @apply my-5;
  }
  .left,
  .right {
    @apply w-full;
    > div {
      @apply flex flex-row justify-around items-center my-5 flex-no-wrap;
      > label {
        @apply text-right mr-5 w-1/3 text-xs;
      }
      > input {
        &:not(.is-invalid) {
          @apply border-line-top;
        }
        @apply w-2/3 flex-auto;
        border-radius: 0.275rem !important;
      }
      .twn-select {
        @apply w-2/3;
        .vs__dropdown-toggle {
          @apply border-line-top;
        }
        &.is-invalid {
          .vs__dropdown-toggle {
            border-color: #dc3545;
          }
        }
      }
    }
    @screen xs {
      > div > label {
        @apply text-sm;
      }
    }
  }
  @screen md {
    .left,
    .right {
      > div {
        @apply my-10;
      }
      @apply w-1/2;
      .button-holder {
        @apply flex flex justify-end items-end;
      }
    }
  }
  hr {
    box-sizing: border-box;
    border: 1px solid;
    @apply border-line-top rounded-lg;
  }
  .stars {
    .star {
      @apply w-8 mx-1 fill-current text-gray-star;
      &.complete {
        @apply text-principal-selected;
      }
    }
  }
}
</style>
