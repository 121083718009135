import statusLabels from "../userStatus"

export default {
	name: 'Utilisateurs',
	content: [
	{
		table: 'users',
		headers: ['Nom', 'Prénom', 'Email', 'Adresse', 'Téléphone', 'ID d\'acquisition', 'Nb de lots de la résidence', 'Code postal', 'Ville', 'Statut', 'Durée totale de connexion', 'Date dernière connexion', 'Taux d\'avancement global', 'Taux de réussite global'],
		row: (user) => {
			const timeData = user.activeTimeData

			return [
				'name',
				'first_name',
				'email',
				'adress',
				'phone',
				'residence_id',
				'nb_lots',
				'cedex',
				'city',
				{ value: (statusLabels[user.onv_status] || '-') },
				{ value: timeData.sum, type: 'duration' },
				{ value: timeData.lastSessionTimestamp, type: 'date' },
				{ value: user.progression, type: 'percentage' },
				{ value: (user.successRate > -1 ? user.successRate : '-'), type: 'percentage' },
			]
		},
	}
	]
}