<template>
	<div class="settings" >
		<div class="settings-control">
			<label>Identifiant</label>
			<input
			class="twn-input"
			type="text"
			name="name"
			ref="name"
			v-model="settings.name">
		</div>
		<div class="settings-control">
			<label>Titre</label>
			<input
			class="twn-input"
			name="title"
			type="text"
			v-model="settings.title">
		</div>
		<div class="settings-control">
			<label>Description</label>
			<textarea
			class="twn-input"
			rows="6"
			name="description"
			v-model="settings.description"></textarea>
		</div>
		<div class="settings-control">
			<label>Type</label>
			<v-select
			class="twn-select"
			name="type"
			label="name"
			:clearable="false"
			:options="scenarioTypeList"
			v-model="settings.type"
			></v-select>
		</div>
		<div class="settings-control">
			<label>Point de départ du joueur</label>
			<v-select
			class="twn-select"
			name="spawn_point"
			label="name"
			:clearable="false"
			:options="spawnPointList"
			v-model="settings.spawnPoint"
			></v-select>
		</div>
		<div class="settings-control">
			<label>Emplacement sur la carte du site</label>
			<v-select
			class="twn-select"
			name="map_point"
			label="name"
			:clearable="false"
			:options="mapPointList"
			v-model="settings.mapPoint"
			></v-select>
		</div>
		<div class="settings-control">
			<label>Scène(s) utilisée(s)</label>
			<v-select
			class="twn-select"
			name="scene"
			label="name"
			:clearable="false"
			:options="sceneList"
			:multiple="true"
			v-model="settings.scenes"
			></v-select>
		</div>
		<!-- <div class="settings-control">
			<label>Lieu</label>
			<v-select
			class="twn-select"
			name="location"
			label="name"
			:options="locationList"
			:clearable="true"
			v-model="settings.location"
			></v-select>
		</div> -->
		<div class="pt-4 text-xs text-right">
			<button
			type="button"
			class="twn-button"
			:disabled="hasPendingRequest"
			@click="onSaveClick">Enregistrer</button>
		</div>

		<div class="mt-10" v-if="scenario && scenario.contentLogs.length > 0">
			<div class="cursor-pointer select-none" @click="showContentHistory = !showContentHistory">
				<svg class="inline-block mr-2" :class="{'transform rotate-180':showContentHistory}" height="15px" id="Layer_1" version="1.1" viewBox="0 0 26 15" width="26px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon fill="#231F20" points="23.303,-0.002 12.467,10.834 1.63,-0.002 -0.454,2.082 12.467,15.002 14.551,12.918 25.387,2.082   "/></svg>
				<span v-if="showContentHistory">Cacher</span>
				<span v-else>Afficher</span> l'historique des modifications 
			</div>

			<div v-if="showContentHistory" class="mt-4">
				<div class="my-4 flex flex-row text-sm justify-between w-full pr-4" v-for="record in scenario.contentLogs" :key="record.created_at">
					<div>{{formatDate(record.created_at)}}</div>
					<div>{{record.user.first_name}} {{record.user.name}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'

	export default {
		props: {
			show: {
				type: Boolean,
				required: true,
				default: false,
			},
			scenario: {
				type: Object,
				required: true,
				default: () => {}
			},
			locationList: {
				type: Array,
				required: true,
				default: () => [],
			},
			entityList: {
				type: Array,
				required: true,
				default: () => [],
			},
			scenarioTypeList: {
				type: Array,
				required: true,
				default: () => [],
			},
			hasPendingRequest: {
				type: Boolean,
				required: true,
				default: false,
			},
		},
		data() {
			return {
				showContentHistory: false,
				settings: {},
			}
		},
		computed: {
			spawnPointList() {
				if (!this.entityList)
					return []

				return this.entityList.filter((entity) => {
					return entity.type.slug == 'spawn_point'
				})
			},
			mapPointList() {
				if (!this.entityList)
					return []

				return this.entityList.filter((entity) => {
					return entity.type.slug == 'map_point'
				})
			},
			sceneList() {
				if (!this.entityList)
					return []

				return this.entityList.filter((entity) => {
					return entity.type.slug == 'scene'
				})
			},
		},
		watch: {
			show: {
				handler(show) {
					if (show) {
						this.cloneSettings()
					}
				},
				immediate: true,
			},
			scenario: {
				handler() {
					this.cloneSettings()
				},
				immediate: true,
			},
		},
		methods: {
			formatDate(dt){
				return moment(new Date(dt)).locale('fr').format('LLLL')
			},
			cloneSettings() {
				if (!this.scenario)
					return

				// Get settings from scenario data
				this.settings = {
					name: this.scenario.name,
					title: this.scenario.title,
					description: this.scenario.description,
					type: this.scenario.type,
					spawnPoint: this.scenario.spawnPoint,
					mapPoint: this.scenario.mapPoint,
					scenes: this.scenario.scenes,
				}
			},
			async onSaveClick() {
				this.$emit('update-settings', this.settings)
			},
		},
	}
</script>

<style lang="scss" scoped>
.settings {
    .settings-control {
        @apply mb-6;

        & > label {
            @apply mb-1 text-xs;
            display: block;
        }

        & > input, & > textarea {
            @apply w-full;
        }

        & > textarea {
            resize: none;
        }
    }
}
</style>