import gql from "graphql-tag"

const GC_GET_COURSE = gql`
	query GetCourse($id: uuid!) {
		course_by_pk(id: $id) {
			id
			type
			name
			levels(order_by: {order: asc}) {
				id
				order
				name
				title
				sequences(order_by: {order: asc}) {
					id
					name
					order
					title
					level_scenario_limit
					scenarios(order_by: {order: asc}) {
						order
						scenario{
							name
							id
						}
					}
				}
				scenarios {
					scenario{
						name
						id
					}
				}
			}
		}
	}
`

const GC_ADD_COURSE_ONE = gql`
mutation AddCourse($course: course_insert_input!) {
  insert_course_one(object: $course) {
    id
  }
}`

const GC_UPDATE_COURSE = gql`
mutation UpdateCourse($id: uuid!, $course: course_insert_input!) {
	delete_course_by_pk(id: $id) {
		id
		name
	}

	insert_course_one(object: $course) {
    	id
  	}
}
`

const GC_GET_COURSE_TYPES = gql`
	query GetCourseTypes {
		course_type {
			id
			name
			slug
		}
	}
`

const SEARCH_SCENARIO = gql`
	query SearchScenario($name: String, $slug: String) {
		scenario(where: { name: { _like: $name }, scenarioType: { slug: { _eq: $slug } } }
				limit: 10) {
			id
			name
			scenario_type_id
		}
	}
`

const GC_DELETE_COURSE = gql`
mutation DeleteCourse($id: uuid!) {
	delete_course_by_pk(id: $id) {
		id
		name
	}
}
`

const GC_CHECK_NAME = gql`
query CheckName($name: String) {
  course(where: {name: {_eq: $name}}) {
    id
  }
}
`

export { GC_ADD_COURSE_ONE, GC_GET_COURSE_TYPES, GC_GET_COURSE, SEARCH_SCENARIO, GC_DELETE_COURSE, GC_UPDATE_COURSE, GC_CHECK_NAME }
