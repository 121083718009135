import { GC_ADD_DOCUMENT, GC_UPDATE_DOCUMENT, GC_DOCUMENT_LIST, GC_DELETE_DOCUMENT } from '@/graphql/library'
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {
      list:[]
    },
    getters:{
      docsList(state) {
        return state.list
      }
    },
    mutations: {
      SET_LIST(state, data) {
        state.list = data
      }
    },
    actions: {
      async saveDocument(ctx, document) {
        console.log(document)
        delete document.link_to_chapter
        if(document.id) {
          const { id } = document
          delete document.id
          let response = await apollo.mutate({
            mutation: GC_UPDATE_DOCUMENT,
            variables: {
              document:document,
              doc_id:id
            }
          })
        } else {

          let response = await apollo.mutate({
            mutation: GC_ADD_DOCUMENT,
            variables: {
              document
            }
          })

        }
      },
      async deleteDocument(ctx, id) {
        let response = await apollo.mutate({
          mutation: GC_DELETE_DOCUMENT,
          variables:{
            id:id
          }
        })
      },
      async getDocumentsList({commit}) {
        return new Promise((resolve, reject) => {
          apollo.query({
            query: GC_DOCUMENT_LIST
          }).then(response => {
          commit('SET_LIST', response.data.library)
          console.log(response.data.library[0].title)
            resolve()
          }).catch(e => reject(e))
        })
      }
    }
}