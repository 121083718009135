import gql from 'graphql-tag'

const GC_GET_MEDIA_LIST = gql`
query GetMediaList {
    media {
    	id
    	name
    	description
    	path
    	thumbnail_path
    	media_type_id
    }
  }
`
const GC_GET_MEDIA_BY_ID = gql`
query MyQuery($id: uuid!) {
	media(where: {id: {_eq: $id}}) {
	  name
	  title
	}
  }
  
  `;
export {
	GC_GET_MEDIA_LIST,
	GC_GET_MEDIA_BY_ID
}