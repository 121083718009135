<template>
  <b-modal
    id="modal-1"
    hide-footer
    v-model="isImportModalOpen"
    @hide="handleHide"
    title="Importer des utilisateurs avec adresse mail"
  >
    <transition name="fade" mode="out-in">
      <div
        v-if="progressSendBatch > 0"
        class="progress mb-5"
        style="height: 3px;"
      >
        <div
          class="progress-bar"
          role="progressbar"
          :style="'width:' + progressSendBatch * 100 + '%'"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </transition>

    <div class="flex items-center">
      <button
        @click="$refs.inputFile.click()"
        type="button"
        class="mr-2 twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
      >
        Choisir un fichier
      </button>
      <span v-if="selectedFile">{{ selectedFile.name }}</span>
    </div>
    <p class="text-xs my-4">
      Rappel : le fichier doit contenir dans l'ordre les colonnes adresse_mail,
      et id_acquisition
    </p>
    <input
      type="file"
      ref="inputFile"
      hidden
      class="input-file"
      @click="$event.target.value = null"
      @change="fileSelected"
    />
    <p class="text-center" v-if="loading">Chargement...</p>
    <ul v-if="fileErrors.length" class="mb-4">
      <li v-for="err in fileErrors" :key="err" class="text-red-600">
        - {{ err }}
      </li>
    </ul>

    <!-- Data preview -->
    <p v-if="dataPreview && dataPreview.length > 0" class="font-bold text-sm">
      Aperçu
    </p>
    <div class="import-table" v-if="dataPreview && dataPreview.length > 0">
      <table class="text-xs">
        <thead>
          <tr>
            <th>Mail</th>
            <th>ID d'acquisition</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in dataPreview" :key="index">
            <td>
              {{ user["adresse_mail"] || "-" }}
            </td>
            <td>
              {{ user["id_acquisition"] || "-" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex">
      <button
        @click="importList"
        :disabled="!isFileValid || imported === true"
        type="button"
        class="mx-auto twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
      >
        {{ imported ? "Import réalisé avec succès" : "Importer la liste" }}
      </button>
      <button
        v-if="imported"
        @click="sendBatch"
        type="button"
        class="mx-auto twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
      >
        Envoyer un mail à la liste importée
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import * as XLSX from "xlsx";
import axios from "axios";
export default {
  name: "UserImportModal",
  data: () => ({
    isImportModalOpen: true,
    fileError: "",
    selectedFile: null,
    allowedExt: ["csv", "xls", "csv"],
    fileErrors: [],
    isFileValid: false,
    dataPreview: [],
    loading: false,
    imported: false,
    newImportedImports: [],
    progressSendBatch: 0
  }),
  methods: {
    ...mapActions("Auth", [
      "inscription",
      "checkRegisteredEmails",
      "importUsersWithMail",
      "updateLastSentMail"
    ]),
    async fileSelected(e) {
      this.selectedFile = e.target.files[0];

      this.fileError = "";
      this.fileErrors = [];
      this.dataPreview = [];
      this.loading = true;
      const ext = this.selectedFile.name.split(".").pop();
      if (!this.allowedExt.includes(ext)) {
        this.$bvToast.toast(
          "Veuillez choisir un fichier au format XLS, XLXS ou CSV",
          {
            variant: "warning",
            toaster: "b-toaster-top-center",
            solid: true,
            noCloseButton: true
          }
        );
        return;
      }
      var reader = new FileReader();
      reader.onload = async e => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array", codepage: 1147 });

        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        let jsonData = XLSX.utils.sheet_to_json(worksheet);
        let errors = this.checkFileDatas(worksheet, jsonData);
        let dup = this.findDuplicates(jsonData.filter(data => data.email));
        // errors = [...errors, ...this.checkEmails(jsonData)]
        if (errors.length) {
          this.fileErrors = errors;
          this.loading = false;
        } else if (dup.length) {
          this.fileErrors = dup.map(
            email =>
              "Cette adresse mail à été trouvée plusieur fois dans le document : " +
              email
          );
          this.loading = false;
        } else {
          let emailAlreadyRegistered = await this.checkRegisteredEmails(
            jsonData
              .filter(data => data.adresse_mail)
              .map(data => data.adresse_mail.toLowerCase())
          );
          if (emailAlreadyRegistered.length) {
            jsonData = jsonData.filter(
              data =>
                !emailAlreadyRegistered.find(
                  registered => data.adresse_mail === registered.email
                )
            );
          }
          // Generate preview
          if (jsonData.length > 0) {
            this.dataPreview = jsonData;
          }

          this.jsonData = jsonData;
          this.isFileValid = true;
          this.loading = false;
        }
      };
      reader.readAsArrayBuffer(this.selectedFile);
    },
    resetFile() {
      this.selectedFile = null;
      this.fileError = "";
      this.fileErrors = [];
      this.$refs.inputFile.value = "";
      this.isFileValid = false;
    },
    checkFileDatas(sheet, arr) {
      let errors = [];

      // Check file headers name
      const validHeaders = ["adresse_mail", "id_acquisition"];

      for (let i = 0; i < 20; i++) {
        const cellRef = XLSX.utils.encode_cell({ c: i, r: 0 });
        const col = sheet[cellRef]?.v;

        if (col && validHeaders.indexOf(col) < 0) {
          errors.push("Colonne " + i + ' inconnue : "' + col + '"');
        }
      }

      // Check values
      arr.forEach((user, i) => {
        if (user.email && !this.validateEmail(user.email.replace(/\s/g, "")))
          errors.push("Vérifiez le format du mail sur la ligne " + (i + 2));
      });
      return errors;
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async importList() {
      let usersData = [];
      for (const { id_acquisition, adresse_mail } of this.jsonData) {
        let userData = {
          mail: adresse_mail ? adresse_mail.replace(/\s/g, "") : null,
          acquisition_id: id_acquisition
        };
        usersData.push(userData);
      }
      if (usersData.length) {
        try {
          const { affected_rows, returning } = await this.importUsersWithMail({
            name: this.selectedFile.name,
            data: usersData
          });
          this.newImportedImports = returning;
          this.$bvToast.toast(affected_rows + " utilisateurs importés.", {
            variant: "success",
            toaster: "b-toaster-top-center",
            solid: true,
            noCloseButton: true
          });
          this.imported = true;
        } catch (e) {
          this.$bvToast.toast(e, {
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
            noCloseButton: true
          });
        }
      } else {
        this.$bvToast.toast(
          "Le fichier ne contient aucun élément, ou bien les éléments importés sont déjà importés.",
          {
            variant: "warning",
            toaster: "b-toaster-top-center",
            solid: true,
            noCloseButton: true
          }
        );
      }
    },
    async sendBatch() {
      const waiter = [];
      this.newImportedImports.forEach((user, i) => {
        setTimeout(async () => {
          let emailobj = {
            context: "inscription",
            variables: { code: "ok" },
            user: user.mail
          };
          const send = axios.post(
            process.env.VUE_APP_API + "send",
            JSON.stringify(emailobj)
          );
          const upd = this.updateLastSentMail(user.id);
          waiter.push(send, upd);
          this.progressSendBatch = (i + 1) / this.newImportedImports.length;
        }, i * 200);
      });
      setTimeout(() => {
        Promise.all(waiter).then(() => {
          this.$bvToast.toast(
            "Un mail a été envoyé à chaque utilisateur de cet import.",
            {
              variant: "success",
              toaster: "b-toaster-top-center",
              solid: true,
              noCloseButton: true
            }
          );
          this.progressSendBatch = 0;
          this.$emit("complete");
          this.handleHide();
        });
      }, (this.newImportedImports.length + 1) * 200);
    },

    findDuplicates(arr) {
      let noDup = [];
      let dup = [];

      arr.forEach(user => {
        if (noDup.includes(user.email.toLowerCase())) dup.push(user.email);
        else noDup.push(user.email.toLowerCase());
      });
      return dup;
    },
    handleHide() {
      this.resetFile();
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
#modal-1 {
  .modal-dialog {
    max-width: 600px;
  }

  table {
    margin: 0.5rem auto 1.5rem auto;

    td,
    th {
      padding-right: 1rem;
    }
  }
}
</style>
