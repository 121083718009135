<template>
  <div class="mt-8">
    <div class="mb-4">Dates des séquences</div>

    <div v-for="(sequence, idx) in sequences" :key="idx" class="flex mb-4">
      <div
        class="c-promotion-detail__sequences grid grid-cols-2 border-line-left border rounded rounded-r-none p-5 w-11/12"
      >
        <div class="flex items-center">
          <div class="mr-8">Type</div>
          <v-select
            v-model="sequence.type"
            :options="['Présidentielle', 'Digitale']"
            placeholder="Choisir un type..."
            class="twn-select w-full"
          ></v-select>
        </div>

        <div class="flex items-center">
          <div class="mr-8">MES</div>
          <b-form-tags placeholder="" v-model="sequence.mes" />
        </div>

        <div class="flex items-center">
          <div class="mr-8">Le</div>
          <b-form-datepicker
            class="w-64 mr-4"
            v-model="sequence.date"
          ></b-form-datepicker>
        </div>

        <div class="flex items-center">
          <div class="flex items-center mr-8">
            <div class="mr-8">Début</div>
            <b-form-input v-model="sequence.start" type="time" />
          </div>
          <div class="flex items-center">
            <div class="mr-8">Durée</div>
            <b-form-input
              v-model="sequence.duration"
              type="time"
              min="01:00"
              max="08:00"
            />
          </div>
        </div>

        <div class="col-span-2 flex items-center">
          <div class="mr-8">
            Stagiaires inscrits :
            {{ `${sequence.interns.length}/${nbInterns}` }}
          </div>

          <v-select
            class="flex-1 mr-auto"
            multiple
            :options="interns"
            :filterable="false"
            placeholder="Nom/Prénom de l'utilisateur"
            v-model="sequence.interns"
            :get-option-label="getInternsOptionLabel"
          />
        </div>
      </div>

      <div
        class="w-1/12 bg-principal-selected flex justify-center items-center flex-col"
      >
        <div
          class="cursor-pointer flex-1 flex items-center justify-center text-3xl text-white border-b border-white w-full"
          @click="onRemoveSequenceClick(idx)"
        >
          -
        </div>
        <div
          class="cursor-pointer flex-1 flex items-center text-3xl text-white"
          @click="addSequence"
        >
          +
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  model: {
    prop: "sequences",
    event: "change",
  },
  props: {
    creating: Boolean,
    interns: {
      type: [Array, Object],
      default: () => [],
    },
    sequences: {
      type: [Array, Object],
      default: () => [],
    },
  },

  computed: {
    nbInterns() {
      return this.interns.filter((intern) => intern && intern.user).length;
    },
  },

  data: () => ({
    internsOptions: [],
  }),

  mounted() {
    if (this.creating) {
      this.addSequence();
    }
  },

  methods: {
    getInternsOptionLabel(intern) {
      return intern.user?.name;
    },

    onRemoveSequenceClick(idx) {
      this.sequences.splice(idx, 1);
    },

    addSequence() {
      this.sequences.push({
        type: null,
        mes: null,
        date: null,
        duration: null,
        interns: [],
      });
    },
  },
};
</script>

<style lang="scss">
.c-promotion-detail__sequences {
  gap: 2em 4em;
}
</style>