<template>
	<div class="game-vocab w-full h-full">
		<div class="flex w-full justify-around">
			<div class="form-group">
				<label for="score">Temps de préparation (secondes):
				</label>
				<b-form-input id="score" type="number" step="1" min="0" v-model="prepareTime"></b-form-input>
			</div>
			<div class="form-group">
				<label for="score">Temps d'éxécution (secondes):
				</label>
				<b-form-input id="score" type="number" step="1" min="0" v-model="executeTime"></b-form-input>
			</div>
		</div>
		<div v-if="gameImage" class="uppercase font-bold mt-4 mb-2">Contrôles de dropzones :</div>
		<div class="buttons w-full flex" v-if="gameImage">
			<div class="w-1/2 flex justify-around">
				<button
					type="button"
					class="twn-button"
					@click="newLabelMode"
					v-bind:class="currentActiveTool == 'rect' ? 'select' : ''"
				>
					Nouvelle zone
				</button>
				<button
					type="button"
					class="twn-button"
					@click="selectMode"
					v-bind:class="currentActiveTool == 'selectMode' ? 'select' : ''"
				>
					Déplacer
				</button>
				<button
					type="button"
					class="twn-button"
					@click="deleteLabel"
				>
					Supprimer la zone
				</button>
			</div>
			<div class="w-1/2 flex justify-end">
				<button
					type="button"
					class="twn-button danger"
					@click="deleteImageAsk"
				>
					Supprimer l'image
				</button>
			</div>
		</div>
		<div class="flex items-center flex-1 flex-col">
			<div class="top-block w-full">
				<div
					class="w-full image-content"
					@dragover="allowDrop"
					@drop.prevent.stop="dropImage($event)"
					ref="parentDiv"
				>
					<div class="my-4 font-bold" v-if="!gameImage">Choix d'image de Vocabulaire</div>
					<div
						class="drop-zone p-10 w-full h-full border-dashed border-2"
						v-show="!gameImage"
						@click="openMediaChoice"
					>
						<div class="m-auto w-1/3 break-words text-center text-xs">
							Ajouter une image depuis la bibliothèque de média
						</div>
					</div>
					<div
						v-show="gameImage"
						class="image"
						@pointermove="moveLabel"
						@pointerdown="beginDrag($event)"
						@pointerup="endDrag"
					>
						<div class="my-4 uppercase font-bold">Positionnement: </div>
						<Editor
							class="m-auto"
							ref="editor"
							:canvasWidth="targetWidth"
							:canvasHeight="targetHeight"
							@dragend="endDrag"
						/>
					</div>
				</div>
			</div>
			<div class="bottom-block w-full flex flex-row">
				<b-table-lite
					class="table w-full mt-5"
					borderless
					:items="questions"
					:fields="fields"
					v-if="questions.length != 0"
				>
					<template v-slot:cell(index)="data">
						<div
							class="bg-principal-selected mt-1 number rounded-full w-5 h-5 flex-center text-xs"
						>
							{{ data.index + 1 }}
						</div>
					</template>
					<template v-slot:cell(text)="data">
						<input
							class="words border-line-top rounded-sm"
							type="text"
							:value="data.item.text"
							@input="updateQuestionText($event.target.value, data.index)"
						/>
					</template>
					<template v-slot:cell(correction)="data">
						<input
							class="words w-full border-line-top rounded-sm"
							type="text"
							:value="data.item.correction"
							@input="updateCorrectionText($event.target.value, data.index)"
						/>
					</template>
				</b-table-lite>
			</div>
		</div>
		<RightPanel ref="rp" title="Choix d'image">
			<Medias
				:draggable-asset="true"
				:defaultTypeFilterSlug="['file_image']"
				@select-media="AssignBackground"
				:selectOnly="true"
				:draggableAsset="true"
			></Medias>
		</RightPanel>
		<b-modal
			ref="delete-modal-image"
			centered
			hide-footer
			id="delete-modal-image"
			hide-header
		>
			<div class="d-block text-center my-6 uppercase font-semibold">
				<h3>Attention, toutes les données du jeu seront perdues</h3>
			</div>
			<div class="flex flex-row justify-evenly items-center">
				<button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-image')">
					Retour
				</button>
				<button type="button" class="mt-4 twn-button danger" @click="deleteImage">Supprimer</button>
			</div>
		</b-modal>
	</div>
</template>
<script>
import RightPanel from "@/components/RightPanel"
import Medias from "@/views/Medias"
import Editor from "vue-image-markup"
import {mapState} from 'vuex'
//Draw modes
// text
// circle
// rect
// selectMode
// arrow
// freeDrawing
// crop
import { fabric } from "fabric"

export default {
	name: "Vocabulary",
	components: {
		RightPanel,
		Medias,
		Editor
	},
	props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
	},
	data() {
		return {
			fields: [
				{
					key: "index",
					label: "",
					sortable: false
				},
				{
					key: "text",
					label: "Mots",
					sortable: false
				},
				{
					key: "correction",
					label: "Description",
					sortable: false
				}
			],
			editor: null,
			canvasWidth: 0,
			canvasHeight: 0,
			activeObject: null,
			targetWidth: 0,
			targetHeight: 0,
			objectOutside: false
		}
	},
	mounted() {
		if (this.$refs['editor']){
			this.editor = this.$refs['editor'];
			this.targetWidth = this.$refs.parentDiv.clientWidth;
			this.targetHeight = this.targetWidth / 1.7777; //Keep 16/9 format
			this.editor.canvas.setDimensions({width:this.targetWidth, height:this.targetHeight});
			this.editor.canvas.on('mouse:up', this.checkForUnvalidObjects)
			// this.editor.canvas.on('object:moving', this.preventDragOffCanvas);
			if (this.questions.length > 0){
				this.questions.forEach((question, i) => {
					//Create square with question coordinates
					if (question.coordinates){
						let coordinates = JSON.parse(question.coordinates)
						let left = (coordinates.x1 * this.editor.canvas.width) / 100
						let top = (coordinates.y1 * this.editor.canvas.height) / 100
						let width = ((coordinates.x2 * this.editor.canvas.width) / 100) - left
						let height = ((coordinates.y2 * this.editor.canvas.height) / 100) - top
						let rect = new fabric.Rect({
							left,
							top,
							width,
							height,
							fill: 'transparent',
							stroke: '#DC9799',
							strokeWidth: 2
						});
						rect.id = i;

						this.checkAndFixRect(rect)
						
						let leftText = rect.left + (rect.width / 2) - 5
						let topText = rect.top + (rect.height / 2) - 5
						let text = this.setText(leftText, topText, i + 1);

						rect.textLabel = text;
						this.editor.canvas.add(rect);
					}
				})
			} else{
				let question = {
					correction: '',
					answers:[],
					text: '',
					coordinates: null,
					media_id: null,
				}
				this.$emit('add-questions', [question])
			}
			this.editor.canvas.renderAll(this.editor.canvas);
			this.selectMode();
		}
	},
	computed: {
		...mapState("Assets", {
			assets(state) {
				if (!state.assets || state.assets.length <= 0) return {}

				return state.assets.reduce((dict, asset) => {
					dict[asset.id] = asset

					return dict
				}, {})
			}
		}),
		questions() {
			return JSON.parse(JSON.stringify(this.game.questions))
		},
		canvasObj(state) {
			return state.editor.canvas._objects.filter((obj) => obj.id)
		},
		currentActiveTool(state) {
			if (!state.editor) {
				return false
			}
			return state.editor.currentActiveTool
		},
		gameImage(){
			if (!this.questions[0] || !this.questions[0].media_id){
				return null
			}
			return this.questions[0].media_id
		},
		gameImageSrc(){
			if (!this.gameImage || !this.assets[this.gameImage])
				return null
			return this.assets[this.gameImage]
		},
		prepareTime: {
			get(){
				return this.game.prepareTime
			},
			set(val){
				this.$emit('update-prep-time', val)
			}
		},
		executeTime: {
			get(){
				return this.game.executeTime
			},
			set(val){
				this.$emit('update-exec-time', val)
			}
		}
	},
	methods: {
		checkAndFixRect(rect) {
			// Minimal size check to avoid to small rectangle (not selectable)
			if (rect.width <= 50) {
				rect.width = 50
			}

			if (rect.height <= 50) {
				rect.height = 50
			}

			// Check minimal out of bound
			if (rect.left < 0) {
				rect.left = 20
			}

			if (rect.top < 0) {
				rect.top = 20
			}

			// Check maximal out of bound
			if ((rect.left + rect.width) > this.editor.canvas.width){
				rect.left -= (this.editor.canvas.width - rect.width - 20)
			}

			if ((rect.top + rect.height) > this.editor.canvas.height){
				rect.top -= (this.editor.canvas.height - rect.height - 20)
			}

			return rect
		},
		openMediaChoice() {
			this.$refs["rp"].show()
		},
		moveLabel(e) {
			e.preventDefault()
			if (e.pressure > 0) {
				this.activeObject = this.editor.canvas.getActiveObject()
				if (this.activeObject && this.activeObject.text && !this.activeObject.id){
					return;
				}
				if (
					this.activeObject &&
					this.editor.currentActiveTool == "selectMode" &&
					this.activeObject.id !== undefined
				) {

					let width = this.activeObject.width * this.activeObject.scaleX
					let height = this.activeObject.height * this.activeObject.scaleY

					let left = this.activeObject.left + width / 2 - 5
					let top = this.activeObject.top + height / 2 - 5

					let text = this.activeObject.textLabel
					this.editor.canvas.remove(this.activeObject.textLabel)
					text.left = left
					text.top = top
					this.editor.canvas.add(text)
					this.editor.canvas.renderAll()
					this.activeObject.textLabel = text
				}
			}
		},
		allowDrop: function(event) {
			event.preventDefault()
		},
		setText(left, top, number) {
			if (number !== null && number !== undefined) {
				let text = new fabric.IText(number.toString(), {
					fontSize: 20,
					left: left,
					top: top,
					fontFamily: "AvenirNext",
					selectable: false,
					hasControls: false,
					lockMovementX: true,
					lockMovementY: true,
					evented: false,
					backgroundColor: '#fff'
				})
				this.editor.canvas.add(text)
				return text
			}
		},
		async dropImage(e) {
			const assetJSON = e.dataTransfer.getData("application/json")
			const asset = assetJSON ? JSON.parse(assetJSON) : null

			if (asset) {
				this.AssignBackground(asset)
			}
		},
		AssignBackground(asset){
			if (this.questions[0]){
				this.$emit('update-question', 0, {
					media_id: asset.id
				})
				this.$refs["rp"].hide()
			}
		},
		updateQuestionText(e, i) {
			this.$emit('update-question', i, {
				text: e
			})
		},
		updateCorrectionText(e, i) {
			this.$emit('update-question', i, {
				correction: e
			})
		},
		selectMode() {
			this.editor.set("selectMode")
		},
		beginDrag() {
			let editor = this.$refs["editor"]
			this.activeObject = editor.canvas.getActiveObject()
			if (this.activeObject && this.activeObject.id !== undefined) {
				this.activeObject.hasControls = true
				this.activeObject.hasBorders = true
				this.activeObject.lockMovementX = false
				this.activeObject.lockMovementY = false
				this.activeObject.lockRotation = true
				this.activeObject.lockUniScaling = false
				editor.canvas.renderAll()
			}
		},
		endDrag() {
			this.activeObject = this.editor.canvas.getActiveObject()
			// this.checkForUnvalidObjects()
			if (this.activeObject) {
				let width = this.activeObject.width * this.activeObject.scaleX
				let height = this.activeObject.height * this.activeObject.scaleY

				let x1 = (this.activeObject.left / this.editor.canvas.width) * 100
				let y1 = (this.activeObject.top / this.editor.canvas.height) * 100
				let x2 = ((this.activeObject.left + width) / this.editor.canvas.width) * 100
				let y2 = ((this.activeObject.top + height) / this.editor.canvas.height) * 100

				let questionIndex
				if (
					this.editor.currentActiveTool == "rect" ||
					this.editor.currentActiveTool == "selectMode"
				) {
					if (this.editor.currentActiveTool == "rect") {
						if (!this.questions[0].coordinates){
							questionIndex = 0
						} else {
							let question = {
								text: '',
								answers: [],
								correction: '',
								coordinates: null,
								media_id: null
							}
							this.$emit('add-questions', [question])
							questionIndex = this.questions.length - 1
						}
					} else {
						questionIndex = this.activeObject.id
					}
					if (questionIndex === undefined) {
						this.editor.canvas.discardActiveObject()
						return
					}

					let number = questionIndex + 1
					let left = this.activeObject.left + width / 2 - 5
					let top = this.activeObject.top + height / 2 - 5

					let text
					if (!this.activeObject.textLabel) {
						text = this.setText(left, top, number)
					} else {
						text = this.activeObject.textLabel
						text.left = left
						text.top = top
					}
					this.editor.canvas.renderAll()
					this.activeObject.textLabel = text
				}
				this.activeObject.id = questionIndex
				this.$emit('update-question', questionIndex, {
					coordinates: JSON.stringify({x1, y1, x2, y2})
				})
			}
		},
		checkForUnvalidObjects(data){
			const target = data.target
			
			if (!target)
				return
			
			let needUpdate = false
			const width = target.width * target.scaleX
			const height = target.height * target.scaleY
			let rect = this.editor.canvas.wrapperEl.getBoundingClientRect()

			if (width <= 40) {
				target.width = 192
				target.scaleX = 1
				target.textLabel.set({
					left: target.left + (target.width / 2)
				})
				target.setCoords()
				target.textLabel.setCoords()
				needUpdate = true
			}

			if (height <= 40) {
				target.height = 48
				target.scaleY = 1
				target.textLabel.set({
					top: target.top + (target.height / 2)
				})
				target.setCoords()
				target.textLabel.setCoords()
				needUpdate = true
			}

			if (data.target.type == 'rect' && this.currentActiveTool == 'rect'){
				if (target.left + width > rect.width){
					this.deleteLabel()
				}
				if (target.top + height > rect.height){
					this.deleteLabel()
				}
			} else if (data.target.type == 'rect' && this.currentActiveTool == 'selectMode'){


				if (target.left + width > rect.width){
					target.set({
						left: rect.width - width
					})
					target.textLabel.set({
						left: target.left + width / 2
					})
					target.setCoords()
					target.textLabel.setCoords()
					needUpdate = true
				}

				if (target.top + height > rect.height){
					target.set({
						top: rect.height - height
					})
					target.textLabel.set({
						top: target.top + height / 2
					})
					target.setCoords()
					target.textLabel.setCoords()
					needUpdate = true
				}

				if (target.top < 0){
					target.set({
						top: 0
					})
					target.textLabel.set({
						top: height / 2
					})
					target.setCoords()
					target.textLabel.setCoords()
					needUpdate = true
				}

				if (target.left < 0){
					target.set({
						left: 0
					})
					target.textLabel.set({
						left: width / 2
					})
					target.setCoords()
					target.textLabel.setCoords()
					needUpdate = true
				}
			}

			if (needUpdate) {
				if (target.id !== undefined && target.id !== null){
					let x1 = (target.left / this.editor.canvas.width) * 100
					let y1 = (target.top / this.editor.canvas.height) * 100
					let x2 = ((target.left + (target.width * target.scaleX)) / this.editor.canvas.width) * 100
					let y2 = ((target.top + (target.height * target.scaleY)) / this.editor.canvas.height) * 100

					this.$emit('update-question', target.id, {
						coordinates: JSON.stringify({x1, y1, x2, y2})
					})
				}

				this.editor.canvas.renderAll()
			}
		},
		newLabelMode() {
			this.editor.set("rect", {
				stroke: "#DC9799",
				strokeWidth: 2,
				strokeUniform: false
			})
		},
		deleteImageAsk(){
			this.$refs['delete-modal-image'].show()
		},
		deleteImage(){
			let indexes = []
			indexes = this.questions.map((question, i) => i)
			this.$emit('delete-questions', indexes)
			let question = {
				text: '',
				answers: [],
				correction: '',
				coordinates: null,
				media_id: null
			}
			this.editor.canvas.clear()
			this.editor.canvas.renderAll(this.editor.canvas)

			this.$emit('add-questions', [question])
			this.$refs['delete-modal-image'].hide()
		},
		deleteLabel() {
			if (this.activeObject) {
				if (this.activeObject.textLabel && this.activeObject.id !== null && this.activeObject.id !== undefined) {
					this.$refs["editor"].canvas.remove(this.activeObject.textLabel)
					if (this.activeObject.id === 0 && this.questions.length === 1){
						this.$emit('update-question', 0, {
							coordinates: null,
							text: '',
							correction: ''
						})
					} else {
						if (this.activeObject.id === 0 && this.questions.length > 1) {
							this.$emit('update-question', 1, {
								media_id: this.gameImage
							})
						}
						this.$emit('delete-questions', [this.activeObject.id])
						this.canvasObj.forEach((obj) => {
							if (obj.id > this.activeObject.id && obj.textLabel) {
								let width = obj.width * obj.scaleX
								let height = obj.height * obj.scaleY
								let left = obj.left + width / 2 - 5
								let top = obj.top + height / 2 - 5
								let text = this.setText(left, top, obj.id)
								this.editor.canvas.remove(obj.textLabel)
								obj.textLabel = text
								obj.id--
							}
						})
					}
				}
				this.editor.canvas.remove(this.activeObject)
			}
		},
	},
	watch:{
		gameImage(image){
			if (image && this.assets[image]){
				let url = this.assets[image].url
				this.editor.canvas.setBackgroundImage(url, this.editor.canvas.renderAll.bind(this.editor.canvas),{
					width: this.editor.canvas.width,
					height: this.editor.canvas.height
				})
			}
		},
		assets(assetList){
			if (this.gameImage && assetList && Object.keys(assetList).length > 0 && assetList[this.gameImage]){
				let url = this.assets[this.gameImage].url
				this.editor.canvas.setBackgroundImage(url, this.editor.canvas.renderAll.bind(this.editor.canvas),{
					width: this.editor.canvas.width,
					height: this.editor.canvas.height
				})
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.game-vocab {
	.words {
		@apply border-line-top rounded-sm;
		border-width: 1px;
	}
	.main-image {
		@apply w-full bg-line-top;
		.placeholder {
			min-height: 300px;
		}
		img {
			height: -moz-fit-content;
		}
	}
}
</style>
