<template>
  <div>
			<div class="intermediary flex flex-row justify-between items-center mb-5">
				<div class="w-full md:w-2/3 flex flex-row items-center pr-2">
					<label class="mb-0" for="search">
						<b-icon-search class="h2 my-3 mx-2"></b-icon-search>
					</label>
					<input
						v-model="searchStr"
						type="text"
						id="search"
						class="form-control w-4/5"
						placeholder="Rechercher un média"
					/>
				</div>
				<v-select
					v-show='type ===null'
					class="style-chooser text-sm w-1/3 mr-auto"
					placeholder="Type"
					label="name"
					multiple
					:options="typesList"
					v-model="filter.item"
					:reduce="(f) => f.id"
				>
					<template v-slot:option="option">
						<span class="capitalize">{{ option.name }}</span>
					</template>
					<template v-slot:selected-option="option">
						<span class="capitalize">{{ option.name }}</span>
					</template>
				</v-select>
				<div class="hidden md:flex flex-row items-center w-1/3 justify-end">
					<b-icon-list
						@click="cardView = false"
						class="h2 cursor-pointer"
						v-bind:class="[cardView ? '' : 'text-principal-selected']"
					></b-icon-list>
					<svg @click="cardView = true" xmlns="http://www.w3.org/2000/svg" class="icon cursor-pointer" :class="{'selected':cardView}" viewBox="0 0 71.7 71.7"><g id="Calque_2" data-name="Calque 2"><g id="Calque_1-2" data-name="Calque 1"><path class="cls-1" d="M14.3,28.5A14.3,14.3,0,1,1,28.5,14.3,14.3,14.3,0,0,1,14.3,28.5ZM14.3,2A12.3,12.3,0,1,0,26.5,14.3,12.3,12.3,0,0,0,14.3,2Z"/><path class="cls-1" d="M14.3,71.7A14.3,14.3,0,1,1,28.5,57.4,14.3,14.3,0,0,1,14.3,71.7Zm0-26.6A12.3,12.3,0,1,0,26.5,57.4,12.3,12.3,0,0,0,14.3,45.1Z"/><path class="cls-1" d="M57.4,71.7A14.3,14.3,0,1,1,71.7,57.4,14.3,14.3,0,0,1,57.4,71.7Zm0-26.6A12.3,12.3,0,1,0,69.7,57.4,12.3,12.3,0,0,0,57.4,45.1Z"/><path class="cls-1" d="M57.4,28.5A14.3,14.3,0,1,1,71.7,14.3,14.3,14.3,0,0,1,57.4,28.5ZM57.4,2A12.3,12.3,0,1,0,69.7,14.3,12.3,12.3,0,0,0,57.4,2Z"/></g></g></svg>
				</div>
			</div>
			<div class="list">
				<div
					class="card-list w-full flex-wrap justify-center md:justify-start items-center"
					v-bind:class="[cardView ? 'flex' : 'hidden']"
				>
					<div
						class="card hover:shadow-twn bg-assets-background relative w-56 h-56 md:w-28 md:h-28 mr-6 mb-6"
						:class="{ 'cursor-pointer': selectOnly }"
						v-for="(asset, i) in filteredAssetList"
						:key="i"
						:draggable="draggableAsset"
						@dragstart="cardDrag($event, asset)"
						@dragend="dragEnd"
						@click="selectMedia(asset, i, $event)"
					>
						<div class="thumb absolute w-full h-full">
							<img
								class="w-full h-full"
								:src="getThumbnailForAsset(asset)"
								alt=""
								draggable="false"
							/>
						</div>

						<div
							v-if="!selectOnly"
							@click="showDeleteModal(asset, $event)"
							class="cursor-pointer flex justify-center items-center absolute top-0 right-0 w-10 h-10 lg:w-6 lg:h-6"
						>
							<div class="block lg:hidden">
								<b-icon-x class="h1 text-red-600 mb-0"></b-icon-x>
							</div>
							<div class="hidden lg:block">
								<b-icon-x class="h3 text-red-600 mb-0"></b-icon-x>
							</div>
						</div>
						<div
							class="absolute opacity-75 bottom-0 p-1 w-full description bg-white flex flex-col justify-between rounded-b overflow-hidden"
						>
							<div class="md:text-xs">{{ asset.name }}</div>
							<div class="md:text-xs w-full text-right absolute bottom-0 bg-white px-2 h-5">
								{{ asset.type.name }}
							</div>
						</div>
					</div>
				</div>
				<div class="b-list" v-bind:class="[cardView ? 'hidden' : 'block']">
					<b-table
						class="table_list min-w-0 bootstrap"
						responsive="lg"
						borderless
						:busy="tableBusy"
						:items="filteredAssetList"
						:fields="fields"
						:filter="filter"
						:tbody-tr-class="{ 'cursor-pointer': selectOnly }"
						@row-clicked="selectMedia"
					>
						<template v-slot:cell(action)="data">
							<div
								v-if="!selectOnly"
								class="hidden lg:flex flex-row justify-around text-xs show-actions"
							>
								<button
									@click="showDeleteModal(data.item, $event)"
									type="button"
									class="twn-button danger"
								>
									Supprimer
								</button>
							</div>
							<div
								v-if="!selectOnly"
								v-click-outside="clickOutsideSubmenu"
								@click="showEditMenu(data.item.id, $event)"
								class="flex lg:hidden justify-center w-full"
							>
								<div
									class="relative flex-row justify-around text-xs leading-tight w-1/4 items-center"
								>
									• <br />
									• <br />
									•
									<div
										class="rounded-lg edit-menu shadow-twn absolute flex-col justify-center h-24 bg-white px-3"
										v-bind:class="[mobile_edit_menu == data.item.id ? 'flex' : 'hidden']"
									>
										<div
											@click="showDeleteModal(data.item, $event)"
											class="flex items-center justify-center px-10 text-principal-selected"
										>
											Supprimer
										</div>
										<div
											@click="closeSubmenu"
											class=" cursor-pointer w-6 h-6 mt-1 mr-1 flex justify-center items-center absolute top-0 right-0 text-lg font-principal"
										>
											<b-icon-x></b-icon-x>
										</div>
									</div>
								</div>
							</div>
						</template>
					</b-table>
				</div>
			</div>
      <b-modal
			ref="detail-media-modal"
			id="detail-media-modal"
			centered
			hide-footer
			title="Détails du fichier joint"
			size="xl"
		>
			<div class="flex flex-row items-center w-full h-full" v-if="selectedFile">
				<div class="flex items-center flex-col justify-center w-1/2">
					<div v-if="selectedFile.type.slug == 'file_image'">
						<img :src="selectedFile.url" alt="">
					</div>
					<div v-else-if="selectedFile.type.slug == 'file_pdf'">
						<vue-pdf-embed :source="selectedFile.url" />
					</div>
					<div v-else-if="selectedFile.type.slug == 'integrated_video' || selectedFile.type.slug == 'file_video'">
						<video controls :src="selectedFile.url"></video>
					</div>
					<div v-else-if="selectedFile.type.slug == 'file_audio'">
						<audio :src="selectedFile.url"></audio>
					</div>
					<input type="file" ref="inputFile" hidden class="input-file" @change="chooseFile">
					<button class="twn-button mt-4" @click="changeFile">Changer le fichier</button>
				</div>
				<div class="p-4 w-1/2">
					<div class="detail-input">
						<div class="form-label">
							Nom du fichier
						</div>
						<div class="text-sm w-2/3">{{selectedFile.name}}</div>
					</div>
					<div class="detail-input">
						<label class="form-label" for="form-title">Titre</label>
						<input class="twn-input" type="text" id="form-title" v-model="selectedFile.title" @blur="updateMedia">
					</div>
					<div class="detail-input">
						<label class="form-label" for="form-description">Description</label>
						<input class="twn-input" type="text" id="form-description" v-model="selectedFile.description" @blur="updateMedia">
					</div>
					<div class="detail-input">
						<label class="form-label" for="form-author">Type</label>
						<v-select
							:options="typesList"
							label="name"
							class="twn-select w-2/3"
							@input="updateMedia"
							v-model="selectedFile.type"
							:clearable="false"
						>
						</v-select>
					</div>
					<div class="detail-input">
						<label class="form-label" for="form-author">Téléversement</label>
						<div class="w-2/3" v-if="selectedFile.author">
							Le <span class="p-0 m-0">{{formatDate(selectedFile.author.created_at)}} </span>par
							<span> {{selectedFile.author.user.first_name}} {{selectedFile.author.user.name}}</span>
						</div>
					</div>
					<div class="detail-input">
						<label class="form-label" for="form-linked">Téléversé sur</label>
						<div class="w-2/3 flex flex-col" v-if="selectedFile.targets && selectedFile.targets.length > 0">
							<div v-for="(target, i) in selectedFile.targets" :key="i">
								<span>{{target.targetType}} </span>
								<span>{{target.name}}</span>
							</div>
						</div>
						<div class="w-2/3 flex flex-col" v-else>
							Non assigné
						</div>
					</div>
					<div class="detail-input">
						<label class="form-label" for="form-copy">Lien</label>
						<a target="_blank" class="link text-blue-600 w-2/3" :href="selectedFile.url">{{selectedFile.url}}</a>
					</div>
					<div class="mt-5 text-center" v-if="selectedFile.updates && selectedFile.updates.length > 0">
						<div class="cursor-pointer select-none" @click="modifRecordOpen = !modifRecordOpen">
							<svg class="inline-block mr-2" :class="{'transform rotate-180':modifRecordOpen}" height="15px" id="Layer_1" version="1.1" viewBox="0 0 26 15" width="26px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon fill="#231F20" points="23.303,-0.002 12.467,10.834 1.63,-0.002 -0.454,2.082 12.467,15.002 14.551,12.918 25.387,2.082   "/></svg>
							<span v-if="modifRecordOpen">Cacher</span>
							<span v-else>Afficher</span> l'historique des modifications 
						</div>

						<div v-if="modifRecordOpen" class="record-list mt-4 px-5">
							<div class="my-4 flex flex-row text-sm justify-between w-full pr-4" v-for="record in selectedFile.updates" :key="record.created_at">
								<div>{{formatDate(record.created_at)}}</div>
								<div>{{record.user.first_name}} {{record.user.name}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-modal>
    <!-- Modals -->
		<b-modal
			ref="delete-modal-media"
			class="bootstrap"
			centered
			hide-footer
			id="delete-modal-media"
			hide-header
		>
			<div class="d-block text-center my-6 font-semibold" v-if="selectedFile">
				<h3 class="uppercase">Confirmer la suppression</h3>
				<div v-if="selectedFile.targets && selectedFile.targets.length > 0" class="my-4 font-principal font-normal">
					Attention, le média sera supprimé aux emplacements suivants : <br>
					<div class="inline" v-for="(target, i) in selectedFile.targets" :key="i">
						<span>{{target.targetType}} </span>
						<span>{{target.name}}</span> <br>
					</div>
				</div>
			</div>
			<div class="flex flex-row justify-evenly items-center">
				<button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-media')">
					Retour
				</button>
				<button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
			</div>
		</b-modal>
		</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import defaultImage from "@/assets/images/thumbnails/martine.png"
import { GC_UPDATE_MEDIA } from '@/graphql/assets'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import moment from 'moment'
export default {
  components: {
		VuePdfEmbed,
  },
  props: {
		selectOnly: {
			type: Boolean,
			required: false,
			default: false
		},
		draggableAsset: {
			type: Boolean,
			required: false,
			default: false
    },
    onDragEnd:{
			type: Function,
			required: false
		},
	type:{
		type:String,
		required:false,
		default:null
	},
	},
  data: () => ({
    searchStr: "",
    filter: {
				placeholder: "Types",
				item: []
      },
      cardView: true,
      tableBusy: false,
      selectedFile: null,
      modifRecordOpen: false,
      fields: [
				{
					key: "name",
					label: "Nom",
					sortable: true
				},
				{
					key: "type.name",
					label: "Type",
					sortable: true
				},
				{
					key: "action",
					sortable: false,
					label: ""
				}
      ],
      mobile_edit_menu: -1,
  }),
  computed: {
    ...mapGetters("Assets", ["assetList", "typesList"]),
    filteredAssetList() {
			if (!this.filter.item.length && !this.searchStr) return this.assetList ? this.assetList : []

			if (!this.filter.item.length) {
				return this.assetList.filter((asset) => {
					return asset.name.toLowerCase().includes(this.searchStr.toLowerCase())
				})
			} else if (!this.searchStr) {
				return this.assetList.filter((asset) => {
					return this.filter.item.includes(asset.type.id)
				})
			} else {
				return this.assetList.filter((asset) => {
					return asset.name.toLowerCase().includes(this.searchStr.toLowerCase()) && this.filter.item.includes(asset.type.id)
				})
			}
		}
  },
  async created() {
		await this.getTypesList()
		await this.getAssetList()
			if(this.type) {
					if(this.type==="file_pdf") this.cardView = false
			let type = this.typesList.find(item => item.slug === this.type);
			console.log(type)
			this.filter.item.push(type.id)
		}
		this.$emit("media-loaded")
	},

  methods: {
    ...mapActions("Assets", [
      "getTypesList",
			"getAssetList",
			"deleteFile",
			"deleteFileFromAWS",
			"getAssetById",
			"sendFileToAWS",
			"setFileThumbnail",
			"deleteFileFromAWS",
			"sendFileToAWS",
			"sendFileToHasura",
			"getAssetFromPath"
	]),
    dragEnd(e){
			if(this.onDragEnd){
				this.onDragEnd(e)
			}
    },
    getThumbnailForAsset(asset) {
			if (!asset.thumbnail_url) {
				switch (asset.type.slug) {
					case "file_pdf":
						return require("../assets/images/thumbnails/PDF_THUMBNAIL.svg")
					case "file_video":
					case "integrated_video":
						return require("../assets/images/thumbnails/VIDEO_THUMBNAIL.svg")
					case "asset_scene":
						return require("../assets/images/thumbnails/SCENE_THUMBNAIL.svg")
				}
			}

			return asset.thumbnail_url || defaultImage
    },
    async selectMedia(item) {
			this.$emit("select-media", item)
			if (!this.selectOnly){
				this.modifRecordOpen = false
				this.selectedFile = item
				//GET DB INFO
				const response = await this.getAssetById(item.id)

				this.selectedFile.targets = []
				this.selectedFile.updates = []
				if (response.data){
					let data = response.data

					if (data.media[0].created[0]){
						this.selectedFile.author = data.media[0].created[0]
					}

					if (data.media[0].updated[0]){
						this.selectedFile.updates = data.media[0].updated
					}

					data.game.forEach(game => {
						game.targetType = "Game"
						this.selectedFile.targets.push(game)
					})

					data.question.forEach(question => {
						question.targetType = "Question game"
						this.selectedFile.targets.push(question)
					})

					data.node.forEach(node => {
						node.scenario.targetType = "Scenario"
						this.selectedFile.targets.push(node.scenario)
					})

					this.$forceUpdate()
				}
				this.$refs['detail-media-modal'].show()
			}
    },
    async chooseFile(e){
			if (e.target.files[0] && this.selectedFile){
				let fileInfo = this.selectedFile;
				await this.deleteFileFromAWS(this.selectedFile)

				let newFile = e.target.files[0]
				fileInfo.name = newFile.name

				const res = await this.sendFileToAWS(newFile);

				if (res && res.status == 200){
					let thumbnail_path = null;
					let path = `assets/${this.sanitizeFileName(fileInfo.name)}`
					if (newFile.type.match(/image.*/)) {
						thumbnail_path = await this.setFileThumbnail(newFile)
					}
					await this.$apollo.mutate({
						mutation:GC_UPDATE_MEDIA,
						variables:{
							id: fileInfo.id,
							title: fileInfo.title,
							description: fileInfo.description,
							name: fileInfo.name,
							type: fileInfo.type.id,
							thumbnail_path,
							path,
						}
					})
					this.selectedFile = fileInfo
					this.selectedFile.url = await this.getAssetFromPath(path)
					this.$store.dispatch('Logs/ContentLog', {
						id: this.selectedFile.id,
						action: 'content_update'
					}, {root:true})
					if (thumbnail_path){
						this.selectedFile.thumbnail_url = await this.getAssetFromPath(thumbnail_path)
					}
					this.$forceUpdate()
				}
			}
    },
    changeFile(){
			this.$refs['inputFile'].click()
    },
    async updateMedia(e){
			if (e && this.selectedFile){
				await this.$apollo.mutate({
					mutation:GC_UPDATE_MEDIA,
					variables:{
						id: this.selectedFile.id,
						title: this.selectedFile.title,
						description: this.selectedFile.description,
						type: this.selectedFile.type.id,
						thumbnail_path: this.selectedFile.thumbnail_path,
						path: this.selectedFile.path,
						name: this.selectedFile.name
					}
				})
				this.$store.dispatch('Logs/ContentLog', {
					id: this.selectedFile.id,
					action: 'content_update'
				}, {root:true})
			}
		},
		async showDeleteModal(asset, e) {
			if (e){
				e.stopPropagation()
			}
			this.selectedFile = asset
			const response = await this.getAssetById(asset.id)
			this.selectedFile.targets = []
			if (response.data){
				let data = response.data
				data.game.forEach(game => {
					game.targetType = "Game"
					this.selectedFile.targets.push(game)
				})

				data.question.forEach(question => {
					question.targetType = "Question game"
					this.selectedFile.targets.push(question)
				})

				data.node.forEach(node => {
					node.scenario.targetType = "Scenario"
					this.selectedFile.targets.push(node.scenario)
				})
				this.$forceUpdate()
			}
			this.$refs["delete-modal-media"].show()
		},
		async deleteItem() {
			await this.deleteFile(this.selectedFile)
			this.getAssetList()
			this.$refs["delete-modal-media"].hide()
			this.selectedFile = null
		},
    formatDate(dt){
			return moment(new Date(dt)).locale('fr').format('LLLL')
		},
    showEditMenu(id, e) {
			e.stopPropagation()
			this.mobile_edit_menu = id
		},
		clickOutsideSubmenu() {
			this.mobile_edit_menu = -1
		},
		closeSubmenu(e) {
			e.stopPropagation()
			this.mobile_edit_menu = -1
		},
		sanitizeFileName(filename){
			return filename.replace(/\s/g, '_').replace(/[^a-zA-Z0-9-_.]/g, '')
		},
  },
  watch: {
    typesList(newList) {
			if (this.defaultTypeFilterSlug && this.defaultTypeFilterSlug.length && newList.length > 0) {
				if(!this.type) {
					this.filter.item = []
				}
				// Get the media type instance coresponding to this slug
				for (var i = 0; i < this.defaultTypeFilterSlug.length; i++) {
					let index = newList.findIndex((type) => type.slug == this.defaultTypeFilterSlug.length)
					if (index !== -1) {
						this.filter.item.push(newList[index].id)
					}
				}
			} else {
				if(!this.type) {
					this.filter.item = []
				}
			}
		},
  },
}
</script>

<style lang="scss">
.record-list{
	max-height: 200px;
	overflow-y: auto;
}
.icon{
		&.selected{
			fill: #DC9799;
		}
		@apply inline-block mb-2;
		padding: 6px;
		width: 32px;
		height: 32px;
	}
#detail-media-modal{
	.detail-input{
		@apply flex justify-end w-full my-4;
		.form-label{
			@apply w-1/3 text-right pr-4;
		}
		.twn-input{
			@apply w-2/3;
		}
	}
}
.edit-menu {
		right: 0px;
		top: -10px;
	}
  .card {
		@screen md {
			&.md\:w-28,
			.w-28 {
				width: 7rem !important;
			}

			&.md\:h-28,
			.h-28 {
				height: 7rem !important;
			}
		}

		.description {
			height: 50%;
		}
	}
</style>