import axios from "axios";
import router from "@/router/index";
import { GC_USER_ID, GC_AUTH_TOKEN } from "@/constants/jwt";
import {
  GC_USER_INFOS,
  GC_USER_INSCRIPTION,
  GC_USER_INSCRIPTION_UPDATE,
  GC_GET_USER_INSCR_TRACKS,
  GC_CHECK_REGISTERED_EMAILS,
} from "@/graphql/auth";
import apollo from "@/apolloClient";
import {
  CREATE_IMPORT,
  GET_IMPORTS,
  GET_SPONTANEOUS,
  IMPORT_USERS_MAILLESS,
  IMPORT_USERS_WITH_MAIL,
  SEARCH_IMPORTS_INSCRIPTIONS,
  UPDATE_ONE_MAILLESS,
  UPDATE_ONE_WITH_MAIL,
} from "../graphql/auth";

const backendURL = process.env.VUE_APP_API;

export default {
  namespaced: true,
  state: {
    errorMessage: "",
    user: "",
    userInfo: "",
    orga: null,
    loggedIn: !!localStorage.getItem(GC_AUTH_TOKEN),
    tracks: [],
    imports: [],
    spontaneous: [],
    results: [],
    searchLoading: false,
  },
  getters: {
    getErrorMessage(state) {
      return state.errorMessage;
    },
    isLoggedIn(state) {
      return state.loggedIn;
    },
    tracks(state) {
      return state.tracks;
    },
    imports(state) {
      return state.imports;
    },
    spontaneous(state) {
      return state.spontaneous;
    },
    results(state) {
      return state.results;
    },
    searchLoading(state) {
      return state.searchLoading;
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data.user;
      state.loggedIn = true;
    },
    setTracks(state, data) {
      state.tracks = data;
    },
    SET_USER_INFO(state, data) {
      state.userInfo = data;
    },
    SET_IMPORTS(state, value) {
      state.imports = value;
    },
    SET_SPONTANEOUS(state, value) {
      state.spontaneous = value;
    },
    SET_RESULTS(state, value) {
      state.results = value;
    },
    SET_SEARCH_LOADING(state, value) {
      state.searchLoading = value;
    },
  },
  actions: {
    async searchInscriptions({ commit }, query) {
      if (query.length > 2) {
        commit("SET_SEARCH_LOADING", true);
        const response = await apollo.query({
          query: SEARCH_IMPORTS_INSCRIPTIONS,
          variables: {
            query,
          },
        });
        response.data.mailless = response.data.mailless.map((result) => ({
          ...result,
          type: 0,
          status: result.registred,
        }));
        response.data.mail = response.data.mail.map((result) => ({
          ...result,
          type: 1,
          status: result.is_linked,
        }));
        response.data.spontaneous = response.data.spontaneous.map((result) => ({
          ...result,
          type: 2,
          mail: result.email,
          status: true,
        }));
        commit(
          "SET_RESULTS",
          response.data.mailless
            .concat(response.data.mail)
            .concat(response.data.spontaneous)
        );
        commit("SET_SEARCH_LOADING", false);
      } else {
        commit("SET_SEARCH_LOADING", false);
        commit("SET_RESULTS", []);
      }
    },
    setUser({ commit }, data) {
      commit("setUser", data);
      router.push({
        path: "/",
      });
    },
    login({ dispatch, state }, data) {
      state.errorMessage = "";
      let user = {
        username: data.email,
        password: data.password,
        remember: data.checked,
      };
      axios
        .post(backendURL + "auth/authenticate", user)
        .then(async ({ data: { access, id } }) => {
          let parsedToken = parseJwt(access.token);
          window.localStorage.setItem(GC_USER_ID, parsedToken.username);
          window.localStorage.setItem(GC_AUTH_TOKEN, id.token);
          await dispatch("getCurrentUserInfo", true);
        })
        .catch((err) => {
          if (err.response) state.errorMessage = err.response.data.error;
        });
    },
    logout({ state }) {
      localStorage.removeItem(GC_AUTH_TOKEN);
      localStorage.removeItem(GC_USER_ID);
      sessionStorage.clear();
      state.user = "";
      state.loggedIn = false;
      router
        .push({
          name: "auth",
        })
        .catch((err) => err);
    },
    async getCurrentUserInfo({ state, commit, dispatch }, isConnection) {
      const currentUserId = localStorage.getItem(GC_USER_ID);
      if (state.loggedIn || isConnection) {
        try {
          const response = await apollo.query({
            query: GC_USER_INFOS,
            variables: {
              id: currentUserId,
            },
          });
          commit("SET_USER_INFO", response.data.users[0]);
          if (isConnection) dispatch("setUser", response.data.users[0]);
        } catch (err) {
          dispatch("logout");
        }
      }
      // if (currentUserId && !this.loggedIn) {
      //   try {

      //     const response = await apollo.query({
      //       query: GC_USER_INFOS
      //     })
      //     commit('SET_USER_INFO', response.data.users[0])

      //     // const orgaRes = await apollo.query({
      //     //     query: CG_GET_ORGA,
      //     //     variables: {
      //     //         id: currentUserId
      //     //     }
      //     // })

      //     // if (orgaRes.data.organisations.length > 0){
      //     //     state.orga = orgaRes.data.organisations[0]
      //     // }

      //     return dispatch('Utils/initAll', {}, {root:true});
      //   }
      //   catch (err) {
      //     console.log('err :', err);
      //     dispatch('logout')
      //   }
      // }
    },
    async inscription(ctx, users) {
      await apollo.mutate({
        mutation: GC_USER_INSCRIPTION,
        variables: {
          user: users,
        },
      });
    },
    async updateInscription(ctx, { id, code }) {
      await apollo.mutate({
        mutation: GC_USER_INSCRIPTION_UPDATE,
        variables: {
          id,
          code,
        },
      });
    },
    async listAllTracks({ commit }) {
      const response = await apollo.query({
        query: GC_GET_USER_INSCR_TRACKS,
      });
      commit("setTracks", response.data.user_inscription_tracks);
    },
    async checkRegisteredEmails(ctx, emails) {
      const response = await apollo.query({
        query: GC_CHECK_REGISTERED_EMAILS,
        variables: {
          emails,
        },
      });
      return [
        ...response.data.user_inscription,
        ...response.data.users,
        ...response.data.imported_users_with_mail,
      ];
    },
    async importUsersWithMail(ctx, payload) {
      try {
        const {
          data: {
            insert_imports_one: { id },
          },
        } = await apollo.mutate({
          mutation: CREATE_IMPORT,
          variables: {
            type: "mail",
            name: payload.name,
          },
        });
        payload.data.forEach((item) => {
          item.import_id = id;
        });
        const response = await apollo.mutate({
          mutation: IMPORT_USERS_WITH_MAIL,
          variables: {
            objects: payload.data,
          },
        });
        return response.data.insert_imported_users_with_mail;
      } catch (e) {
        console.log(e);
        throw "Certains mails sont déjà en base";
      }
    },
    async importUsersMailless(ctx, payload) {
      try {
        const {
          data: {
            insert_imports_one: { id },
          },
        } = await apollo.mutate({
          mutation: CREATE_IMPORT,
          variables: {
            type: "mailless",
            name: payload.name,
          },
        });
        payload.data.forEach((item) => {
          item.import_id = id;
        });
        const response = await apollo.mutate({
          mutation: IMPORT_USERS_MAILLESS,
          variables: {
            objects: payload.data,
          },
        });
        return response.data.insert_imported_users_mailless.affected_rows;
      } catch {
        throw "Une erreur est survenue";
      }
    },
    async getImports({ commit }, type) {
      const response = await apollo.query({
        query: GET_IMPORTS,
        variables: {
          type,
        },
      });
      commit("SET_IMPORTS", response.data.imports);
    },
    async updateMailless(ctx, payload) {
      await apollo.mutate({
        mutation: UPDATE_ONE_MAILLESS,
        variables: {
          id: payload.id,
          registred: payload.registred,
        },
      });
    },
    async updateLastSentMail({ dispatch }, id) {
      await apollo.mutate({
        mutation: UPDATE_ONE_WITH_MAIL,
        variables: {
          last_sent_mail_date: new Date(),
          was_mail_sent: true,
          id,
        },
      });
      dispatch("getImports", "mail");
    },
    async getSpontaneous({ commit }) {
      const response = await apollo.query({
        query: GET_SPONTANEOUS,
      });
      const resp = [...response.data.user_inscription, ...response.data.users];
      resp.sort(function(a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.created_at) - new Date(a.created_at);
      });
      commit("SET_SPONTANEOUS", resp);
    },
  },
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}
