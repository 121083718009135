<template>
  <div class="spontaneous-users">
    <b-table
      id="table"
      class="table_list min-w-0 bootstrap"
      responsive="lg"
      :current-page="current"
      :per-page="perPage"
      borderless
      :busy="loading"
      :items="spontaneous"
      :fields="fields"
      :tbody-tr-class="{ 'cursor-pointer': true }"
    >
      <!-- https://bootstrap-vue.js.org/docs/components/table/#tables -->
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mr-5"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template #cell(lastname)="data">
        <b>{{ data.item.lastname.toUpperCase() }}</b>
      </template>

      <hr />
    </b-table>
    <b-pagination
      class="m-auto style-paginate"
      v-model="current"
      v-if="pages"
      :total-rows="spontaneous.length"
      :per-page="perPage"
      aria-controls="table"
    >
    </b-pagination>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      current: 1,
      perPage: 20,
      loading: true,
      fields: [
        {
          key: "lastname",
          label: "NOM",
          sortable: true,
        },
        {
          key: "firstname",
          label: "Prénom",
          sortable: true,
        },
        { key: "email", label: "Mail", sortable: true },

        {
          key: "created_at",
          label: "Date d'inscription",
          sortable: true,
          formatter: (date) => {
            return moment(date)
              .format("dddd DD MMMM YYYY HH:mm")
              .split(":")
              .join("h");
          },
        },
      ],
      detail: false,
      detailData: [],
      moment: moment,
    };
  },
  mounted() {
    this.moment.locale("fr");
  },
  computed: {
    ...mapGetters("Auth", ["spontaneous"]),
    pages() {
      if (this.spontaneous.length && this.perPage) {
        return Math.ceil(this.spontaneous.length / this.perPage);
      } else return 1;
    },
  },
  methods: {
    ...mapActions("Auth", ["getSpontaneous"]),
    handleDetail(detail) {
      this.detail = true;
      this.detailData = detail.item;
    },
    async load() {
      this.loading = true;
      await this.getSpontaneous();
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>
