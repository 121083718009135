import utils from "@/utils/trackingReportTemplate"

export default {
	progressionPerChapter(user, data) {
		// Create a dictionary of chapter progression by their id
		return data.chapters.reduce((dict, chapter) => {
			// Get order of the last visited zone in this chapter
			const lastZoneOrder = user.planche_zone_visiteds.reduce((lastOrder, log) => {
				const order = chapter.zoneOrders[log.planche_zone_id]

				if (order > -1 && lastOrder < order) {
					lastOrder = order
				}

				return lastOrder
			}, -1)

			// Get progression percentage
			dict[chapter.id] = ((lastZoneOrder + 1) / (chapter.zoneOrders.count || 1))

			return dict
		}, {})
	},
	progression(user) {
		// Make an average of user progression per chapter
		return utils.groupAverage(user.progressionPerChapter, (progression) => progression)
	},
	createdWeekId(user) {
		// Generate a uniq week id for the user subscription date
		return utils.weekId(new Date(user.created_at))
	},
	activeTimeData(user) {
		// Init computed data
		let computedTimeData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			weekIds: {},
			lastSessionTimestamp: 0
		}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Add log creation time
			times.push((new Date(log.created_at)).getTime())

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		const oneHour = (1 * 60 * 60 * 1000) // in 
		const fiveMinutes = (5 * 60 * 1000)

		let lastLogTime = 0

		times.forEach((logTime) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if too logs are too far apart
			if (deltaTime > oneHour) {
				// Start a new session with the minimal time (5 min)
				computedTimeData.lastSessionTimestamp = logTime
				computedTimeData.times.push(fiveMinutes)

				// Update sum
				computedTimeData.sum += fiveMinutes

				// Register session week as active
				computedTimeData.weekIds[utils.weekId(new Date(logTime))] = true
			} else {
				// Increment current session time
				computedTimeData.times[computedTimeData.times.length - 1] += deltaTime

				// Update sum
				computedTimeData.sum += deltaTime
			}

			// Update max
			const sessionTime = computedTimeData.times[computedTimeData.times.length - 1]

			if (sessionTime > computedTimeData.max) {
				computedTimeData.max = sessionTime
			}

			lastLogTime = logTime
		})

		// Compute average time
		if (computedTimeData.times.length > 0) {
			computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
		}

		return computedTimeData
	},
	successRatePerGame(user) {
		// Get best success rate for each game played
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'game' && log.data != null) {
				const successRate = (dict[log.data_key] || -1)

				// Only keep best success rate from all attempt
				if (log.data > successRate) {
					dict[log.data_key] = log.data
				}
			}

			return dict
		}, {})
	},
	successRate(user) {
		if (Object.keys(user.successRatePerGame).length <= 0)
			return -1

		// Make an average of user success rate per game
		return utils.groupAverage(user.successRatePerGame, (successRate) => successRate)
	},
	successRatePerChapter(user, data) {
		// Create a dictionary of average games success rate by chapter id
		return data.chapters.reduce((dict, chapter) => {
			const results = chapter.games.reduce((results, gameId) => {
				const successRate = user.successRatePerGame[gameId]

				if (successRate != undefined) {
					results.sum += successRate
					results.count += 1
				}

				return results
			}, {
				sum: 0,
				count: 0
			})

			// Compute average success rate
			if (results.count > 0) {
				dict[chapter.id] = (results.sum / results.count)
			}

			return dict
		}, {})
	}
}