import gql from 'graphql-tag'

const GC_ADD_CHAPTER = gql`
  mutation insertChapter($chapter: [chapter_insert_input!]!) {
    insert_chapter(objects: $chapter) {
      affected_rows
    }
  }
`
const GC_UPDATE_CHAPTER = gql`
  mutation updateChapter($chapter_id: uuid!, $chapter: chapter_set_input!) {
    update_chapter(where: {id: {_eq: $chapter_id}}, _set: $chapter) {
      affected_rows
    }
  }
`

const GC_CHAPTER_LIST = gql`
query getChapterList {
  chapter(order_by: {number: asc}) {
    id
    number
    title
    color
    planche_1 {
      id
      name
      path
    }
    planche_2 {
      id
      name
      path
    }
  }
}
`

export {
  GC_ADD_CHAPTER,
  GC_UPDATE_CHAPTER,
  GC_CHAPTER_LIST
}